var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "row" }, [
    _c("div", { staticClass: "col remove-line-height checkbox" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkedList,
            expression: "checkedList"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "checkbox" },
        domProps: {
          value: _vm.member.id,
          checked: Array.isArray(_vm.checkedList)
            ? _vm._i(_vm.checkedList, _vm.member.id) > -1
            : _vm.checkedList
        },
        on: {
          click: _vm.itemSelected,
          change: function($event) {
            var $$a = _vm.checkedList,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.member.id,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkedList = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkedList = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkedList = $$c
            }
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col name" }, [
      _vm._v("\n    " + _vm._s(_vm.member.user.name) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col email" }, [
      _vm._v("\n    " + _vm._s(_vm.member.user.email) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col department" }, [
      _vm._v("\n    " + _vm._s(_vm.member.department) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col management remove" }, [
      _c(
        "button",
        {
          staticClass: "button button-bordered tiny",
          on: { click: _vm.delMember }
        },
        [_vm._v("삭제")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }