var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.articles.length !== 0
      ? _c("div", { staticClass: "list-wrapper" }, [
          _c("div", { staticClass: "list-head" }, [
            _c(
              "h3",
              { staticClass: "list-title label-middle-description bold" },
              [
                _vm._v("\n        뉴스\n        "),
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.newsCount))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button-all-remove button-arrow middle",
                on: {
                  click: function($event) {
                    return _vm.moreNews()
                  }
                }
              },
              [_vm._v("\n        뉴스 더 보기\n        "), _vm._m(0)]
            )
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list short-top news" },
            _vm._l(_vm.articles, function(article) {
              return _c("li", { staticClass: "item" }, [
                _c("article", { staticClass: "item-wrapper investing-item" }, [
                  _c(
                    "div",
                    { staticClass: "image-wrapper remove-line-height" },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.articleUrl(article) },
                          on: {
                            click: function($event) {
                              return _vm.clickArticle(article)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-box",
                              style: _vm.heroImageUrl(article)
                            },
                            [
                              _c("img", {
                                staticClass: "blank-image",
                                attrs: {
                                  src:
                                    "https://assets.themiilk.com/common/blank-6-4.png",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-wrapper" }, [
                    article.tags.length > 0
                      ? _c(
                          "ul",
                          { staticClass: "tags" },
                          _vm._l(article.tags, function(tag) {
                            return _c("li", { staticClass: "tag" }, [
                              _c("a", { attrs: { href: _vm.tagUrl(tag) } }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(tag.name) +
                                    "\n                "
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.articleUrl(article) },
                        on: {
                          click: function($event) {
                            return _vm.clickArticle(article)
                          }
                        }
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "title label-middle-title bold ellipsis one"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(article.title) +
                                "\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    article.first_text
                      ? _c(
                          "a",
                          {
                            staticClass: "no-deco",
                            attrs: { href: _vm.articleUrl(article) },
                            on: {
                              click: function($event) {
                                return _vm.clickArticle(article)
                              }
                            }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description label-small-description ellipsis three"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.stripTag(article.first_text)) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "author label-xsmall" },
                      [
                        _c("author-name", { attrs: { article: article } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(article.str_published_at) +
                              "\n              "
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.videos.length !== 0
      ? _c("div", { staticClass: "list-wrapper" }, [
          _c("div", { staticClass: "list-head" }, [
            _c(
              "h3",
              { staticClass: "list-title label-middle-description bold" },
              [
                _vm._v("\n        비디오\n        "),
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.videoCount))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button-all-remove button-arrow middle",
                on: {
                  click: function($event) {
                    return _vm.moreVideo()
                  }
                }
              },
              [_vm._v("\n        비디오 더 보기\n        "), _vm._m(1)]
            )
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list short-top videos" },
            _vm._l(_vm.videos, function(video) {
              return _c("li", { staticClass: "item" }, [
                _c("article", { staticClass: "item-wrapper investing-item" }, [
                  _c(
                    "div",
                    { staticClass: "image-wrapper remove-line-height" },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.videoLink(video) },
                          on: {
                            click: function($event) {
                              return _vm.clickVideo(video)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-box",
                              style: _vm.videoHeroImageUrl(video)
                            },
                            [
                              _c("img", {
                                staticClass: "blank-image",
                                attrs: {
                                  src:
                                    "https://assets.themiilk.com/common/blank.png",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-wrapper" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.videoLink(video) },
                        on: {
                          click: function($event) {
                            return _vm.clickVideo(video)
                          }
                        }
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "title label-middle-title bold ellipsis one"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(video.title) +
                                "\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    video.description
                      ? _c(
                          "a",
                          {
                            staticClass: "no-deco",
                            attrs: { href: _vm.videoLink(video) },
                            on: {
                              click: function($event) {
                                return _vm.clickVideo(video)
                              }
                            }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description label-small-description ellipsis three"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(video.description) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "statistics label-xsmall" }, [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(video.str_published_at))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "count" }, [
                        _vm._v(
                          "\n                조회수 " +
                            _vm._s(video.view_count) +
                            "\n              "
                        )
                      ])
                    ])
                  ])
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.reports.length !== 0
      ? _c("div", { staticClass: "list-wrapper" }, [
          _c("div", { staticClass: "list-head" }, [
            _c(
              "h3",
              { staticClass: "list-title label-middle-description bold" },
              [
                _vm._v("\n        리포트\n        "),
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.reportCount))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button-all-remove button-arrow middle",
                on: {
                  click: function($event) {
                    return _vm.moreReport()
                  }
                }
              },
              [_vm._v("\n        리포트 더 보기\n        "), _vm._m(2)]
            )
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list short-top news" },
            _vm._l(_vm.reports, function(report) {
              return _c("li", { staticClass: "item" }, [
                _c("article", { staticClass: "item-wrapper investing-item" }, [
                  _c(
                    "div",
                    { staticClass: "image-wrapper remove-line-height" },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.reportUrl(report) },
                          on: {
                            click: function($event) {
                              return _vm.clickReport(report)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-box",
                              style: _vm.reportHeroImageUrl(report)
                            },
                            [
                              _c("img", {
                                staticClass: "blank-image",
                                attrs: {
                                  src:
                                    "https://assets.themiilk.com/common/blank-6-4.png",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-wrapper" }, [
                    report.tags.length > 0
                      ? _c(
                          "ul",
                          { staticClass: "tags" },
                          _vm._l(report.tags, function(tag) {
                            return _c("li", { staticClass: "tag" }, [
                              _c("a", { attrs: { href: _vm.tagUrl(tag) } }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(tag.name) +
                                    "\n                "
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.reportUrl(report) },
                        on: {
                          click: function($event) {
                            return _vm.clickReport(report)
                          }
                        }
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "title label-middle-title bold ellipsis one"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.removeTag(report.title)) +
                                "\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    report.summary
                      ? _c(
                          "a",
                          {
                            staticClass: "no-deco",
                            attrs: { href: _vm.reportUrl(report) },
                            on: {
                              click: function($event) {
                                return _vm.clickReport(report)
                              }
                            }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description label-small-description ellipsis three"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.stripTag(
                                        _vm.removeTag(report.summary)
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "author label-xsmall" }, [
                      _c("span", { staticClass: "bold" }, [_vm._v("발행일")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "date" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.publishedAt(report.published_at)) +
                            "\n              "
                        )
                      ])
                    ])
                  ])
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.the100.length !== 0
      ? _c("div", { staticClass: "list-wrapper" }, [
          _c("div", { staticClass: "section-container" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "section-description label-small-description" },
              [
                _vm._v(
                  "\n        정확한 정보와 데이터, 깊이 있는 분석을 바탕으로 성장 가능성 높은 회사를 선정했습니다. "
                ),
                _c("span", { staticClass: "point bold" }, [
                  _vm._v(_vm._s(_vm.query))
                ]),
                _vm._v("와(과) 관련된 회사들을 살펴보세요.\n      ")
              ]
            )
          ]),
          _vm._v(" "),
          _vm.isYearlyPlan
            ? _c(
                "ul",
                { staticClass: "list short-top news" },
                _vm._l(_vm.the100, function(article) {
                  return _c("li", { staticClass: "item" }, [
                    _c(
                      "article",
                      { staticClass: "item-wrapper investing-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "image-wrapper remove-line-height" },
                          [
                            _c(
                              "a",
                              { attrs: { href: _vm.articleUrl(article) } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-box",
                                    style: _vm.heroImageUrl(article)
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "blank-image",
                                      attrs: {
                                        src:
                                          "https://assets.themiilk.com/common/blank-6-4.png",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          article.tags.length > 0
                            ? _c(
                                "ul",
                                { staticClass: "tags" },
                                _vm._l(article.tags, function(tag) {
                                  return _c("li", { staticClass: "tag" }, [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.tagUrl(tag) } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tag.name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.articleUrl(article) },
                              on: {
                                click: function($event) {
                                  return _vm.clickArticle(article)
                                }
                              }
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title label-middle-title bold ellipsis one"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(article.title) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          article.first_text
                            ? _c(
                                "a",
                                {
                                  staticClass: "no-deco",
                                  attrs: { href: _vm.articleUrl(article) },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickArticle(article)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "description label-small-description ellipsis three"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.stripTag(article.first_text)
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "author label-xsmall" }, [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(_vm._s(article.author_names))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(article.str_published_at) +
                                  "\n              "
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-arrow" }, [
      _c("span", { staticClass: "icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-arrow" }, [
      _c("span", { staticClass: "icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-arrow" }, [
      _c("span", { staticClass: "icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-head" }, [
      _c("h3", { staticClass: "section-title bold" }, [
        _vm._v("\n          미국형님\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }