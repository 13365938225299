var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail" }, [
    _c("div", { staticClass: "table-container contact detail" }, [
      _c("div", { staticClass: "table-head" }, [
        _c("h2", { staticClass: "title label-middle-title" }, [
          _vm._v("\n        1:1 문의하기\n        "),
          _vm.inquiry && _vm.inquiry.comments.length > 0
            ? _c("span", { staticClass: "count label-xxsmall fold-hide" }, [
                _vm._v(
                  "\n          대화 핑퐁 " +
                    _vm._s(_vm.inquiry.comments.length) +
                    "건\n        "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button button-box round bright-blue small",
            on: { click: _vm.moveToNewInquiry }
          },
          [_vm._m(0)]
        )
      ]),
      _vm._v(" "),
      _vm.inquiry != null
        ? _c("div", { staticClass: "row top" }, [
            _c("div", { staticClass: "col category" }, [
              _vm._v("\n        " + _vm._s(_vm.getCategory) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col subject label-middle-description" }, [
              _vm._v("\n        " + _vm._s(_vm.inquiry.title) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col date normal" }, [
              _vm._v("\n        " + _vm._s(_vm.inquiry.date) + "\n      ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inquiry != null
        ? _c("div", { staticClass: "row content normal" }, [
            _c(
              "div",
              {
                staticClass: "text label-small-description",
                staticStyle: { "white-space": "pre-line" }
              },
              [_vm._v("\n        " + _vm._s(_vm.inquiry.content) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.inquiry.attachments.length > 0
              ? _c("div", { staticClass: "attached-wrapper" }, [
                  _c("p", { staticClass: "bold" }, [
                    _vm._v(
                      "첨부파일 (" +
                        _vm._s(_vm.inquiry.attachments.length) +
                        ")"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.inquiry.attachments.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "attached-list",
                          class: {
                            even: _vm.inquiry.attachments.length % 2 == 0
                          }
                        },
                        _vm._l(_vm.inquiry.attachments, function(file, index) {
                          return _c("div", { staticClass: "attached-item" }, [
                            _c(
                              "svg",
                              {
                                staticClass: "icon",
                                attrs: {
                                  width: "10",
                                  height: "20",
                                  viewBox: "0 0 10 20",
                                  fill: "none"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d:
                                      "M5 18.25C3.10159 18.25 1.5625 16.7107 1.5625 14.8125V3.81113C1.5625 3.26456 1.77975 2.74 2.16613 2.35362C2.5525 1.96725 3.07683 1.75 3.62363 1.75H3.62638C4.76488 1.75 5.6875 2.67263 5.6875 3.81113V13.4389C5.6875 13.8177 5.38042 14.125 5.00138 14.125H5C4.6205 14.125 4.3125 13.817 4.3125 13.4375V5.1875C4.3125 4.808 4.0045 4.5 3.625 4.5C3.2455 4.5 2.9375 4.808 2.9375 5.1875V13.4375C2.9375 14.5767 3.86104 15.5 5 15.5H5.00138C6.13988 15.5 7.0625 14.5774 7.0625 13.4389V3.81113C7.0625 1.91363 5.52388 0.375 3.62638 0.375H3.62363C2.71246 0.375 1.83842 0.737313 1.194 1.3815C0.549584 2.02569 0.1875 2.8995 0.1875 3.81113V14.8125C0.1875 17.4704 2.34213 19.625 5 19.625C6.27646 19.625 7.50021 19.1183 8.40313 18.2156C9.30559 17.3129 9.81251 16.0892 9.81251 14.8125C9.81251 10.7624 9.81251 5.1875 9.81251 5.1875C9.81251 4.808 9.5045 4.5 9.125 4.5C8.7455 4.5 8.4375 4.808 8.4375 5.1875V14.8125C8.4375 15.7241 8.07542 16.5986 7.43055 17.2435C6.78613 17.8877 5.91163 18.25 5 18.25Z"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              { attrs: { href: file.url, target: "_blank" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "name ellipsis one" },
                                  [_vm._v(_vm._s(file.name))]
                                )
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.inquiry.comments.length > 0
              ? _c(
                  "div",
                  { staticClass: "talk-list" },
                  _vm._l(_vm.inquiry.comments, function(talk) {
                    return _c("div", { staticClass: "talk-item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "talk-item-wrapper",
                          class: {
                            miilk: talk.from === 1,
                            user: talk.from === 0
                          }
                        },
                        [
                          _c("div", { staticClass: "profile" }, [
                            talk.from === 1
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      "https://assets.themiilk.com/mypage/icon-miilk.svg",
                                    alt: ""
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            talk.from === 0
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      "https://assets.themiilk.com/mypage/icon-user.svg",
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "date label-xsmall" }, [
                            _vm._v(_vm._s(talk.date))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "box label-small-description" },
                            [
                              _c("p", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(talk.content) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              talk.attachments.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "attached-wrapper" },
                                    [
                                      _c("p", { staticClass: "bold" }, [
                                        _vm._v(
                                          "첨부파일 (" +
                                            _vm._s(talk.attachments.length) +
                                            ")"
                                        )
                                      ]),
                                      _vm._v(" "),
                                      talk.attachments.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "attached-list",
                                              class: {
                                                even:
                                                  talk.attachments.length % 2 ==
                                                  0
                                              }
                                            },
                                            _vm._l(talk.attachments, function(
                                              file,
                                              index
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass: "attached-item"
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass: "icon",
                                                      attrs: {
                                                        width: "10",
                                                        height: "20",
                                                        viewBox: "0 0 10 20",
                                                        fill: "none"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          "clip-rule":
                                                            "evenodd",
                                                          d:
                                                            "M5 18.25C3.10159 18.25 1.5625 16.7107 1.5625 14.8125V3.81113C1.5625 3.26456 1.77975 2.74 2.16613 2.35362C2.5525 1.96725 3.07683 1.75 3.62363 1.75H3.62638C4.76488 1.75 5.6875 2.67263 5.6875 3.81113V13.4389C5.6875 13.8177 5.38042 14.125 5.00138 14.125H5C4.6205 14.125 4.3125 13.817 4.3125 13.4375V5.1875C4.3125 4.808 4.0045 4.5 3.625 4.5C3.2455 4.5 2.9375 4.808 2.9375 5.1875V13.4375C2.9375 14.5767 3.86104 15.5 5 15.5H5.00138C6.13988 15.5 7.0625 14.5774 7.0625 13.4389V3.81113C7.0625 1.91363 5.52388 0.375 3.62638 0.375H3.62363C2.71246 0.375 1.83842 0.737313 1.194 1.3815C0.549584 2.02569 0.1875 2.8995 0.1875 3.81113V14.8125C0.1875 17.4704 2.34213 19.625 5 19.625C6.27646 19.625 7.50021 19.1183 8.40313 18.2156C9.30559 17.3129 9.81251 16.0892 9.81251 14.8125C9.81251 10.7624 9.81251 5.1875 9.81251 5.1875C9.81251 4.808 9.5045 4.5 9.125 4.5C8.7455 4.5 8.4375 4.808 8.4375 5.1875V14.8125C8.4375 15.7241 8.07542 16.5986 7.43055 17.2435C6.78613 17.8877 5.91163 18.25 5 18.25Z"
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: file.url,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "name ellipsis one"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(file.name)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "write" }),
            _vm._v(" "),
            _c("div", { staticClass: "write-reply" }, [
              _c(
                "div",
                { ref: "newContent", staticClass: "write-reply-wrapper" },
                [
                  _c("div", { staticClass: "form-item" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInquiry.content,
                          expression: "newInquiry.content"
                        }
                      ],
                      staticClass: "input-form textarea",
                      staticStyle: { resize: "none" },
                      attrs: {
                        type: "text",
                        placeholder: "내용을 적어주세요."
                      },
                      domProps: { value: _vm.newInquiry.content },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newInquiry,
                            "content",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-item" }, [
                    _c("strong", [_vm._v("첨부파일")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-form file" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button button-select-file button-box round",
                          on: { click: _vm.selectFile }
                        },
                        [_vm._v("\n                파일 선택\n              ")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "fileBtn",
                        staticStyle: { display: "none" },
                        attrs: { accept: "image/*", type: "file" },
                        on: { change: _vm.handleFileChange }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.newInquiry.attachments.length > 0
                      ? _c(
                          "div",
                          { staticClass: "input-form file in-file" },
                          [
                            _vm._l(_vm.newInquiry.attachments, function(
                              item,
                              idx
                            ) {
                              return _c("div", { staticClass: "thumbnail" }, [
                                _c("img", {
                                  attrs: { src: item.url, alt: "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button-remove-image",
                                    on: {
                                      click: function($event) {
                                        return _vm.removeFile(idx)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "https://assets.themiilk.com/mypage/icon-remove.svg",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ])
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button-add-image",
                                on: { click: _vm.selectFile }
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src:
                                      "https://assets.themiilk.com/mypage/icon-add-image.svg",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "explain" }, [
                      _vm._v(
                        "\n              첨부파일은 JPG, PNG 등 3MB(3072KB) 이하의 이미지를 등록 할 수\n              있습니다.\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button button-confirm button-box round bright-blue small",
                        on: { click: _vm.addComment }
                      },
                      [_vm._v("\n              등록\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button button-box round bright-blue small",
                        on: { click: _vm.moveToList }
                      },
                      [_vm._v("\n              목록 보기\n            ")]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("새 문의"),
      _c("span", { staticClass: "fold-hide" }, [_vm._v(" 하기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v("추가문의 "),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }