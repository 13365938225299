var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rating-box", class: { scrollable: _vm.numInvestors > 5 } },
    [
      _c("div", { staticClass: "rating-wrapper" }, [
        _vm.numInvestors
          ? _c(
              "ul",
              {
                staticClass: "list",
                attrs: { "data-count": _vm.numInvestors }
              },
              _vm._l(_vm.investors, function(investor) {
                return _c(
                  "li",
                  {
                    key: investor.id,
                    staticClass: "item",
                    class: { active: investor.nickname == _vm.nickname }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "no-deco",
                        attrs: { href: "/investors/" + investor.nickname }
                      },
                      [
                        _c("div", { staticClass: "item-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "image-wrapper remove-line-height" },
                            [
                              _c("div", { staticClass: "activator" }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "image",
                                attrs: {
                                  src:
                                    "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/investors/profile/" +
                                    investor.nickname +
                                    ".jpg",
                                  alt: ""
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "investor-marks label-sub-title thin"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        investor.eng_name
                                          .split(" ")
                                          .map(function(x) {
                                            return x.charAt(0)
                                          })
                                          .join("")
                                          .slice(0, 2)
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "infor-wrapper" }, [
                            _c("div", { staticClass: "point ellipsis one" }, [
                              _vm._v(_vm._s(investor.name))
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "description ellipsis one" },
                              [
                                _vm._v(
                                  _vm._s(investor.eng_name) +
                                    "\n                  "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _c("div", { staticClass: "message empty" }, [
              _vm._v("아직 등록된 투자자가 없습니다.")
            ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rating-box-controller" }, [
      _c("button", { staticClass: "button prev" }, [
        _c("div", { staticClass: "icon-arrow" })
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button next" }, [
        _c("div", { staticClass: "icon-arrow" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rating-box-scroller" }, [
      _c("div", { staticClass: "bar" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }