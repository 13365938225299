<template>
  <div class="orders-container">
    <div class="content">
      <p class="title">결제 내역</p>

      <ul class="orders-list">
        <li class="row order-head clearfix">
          <div class="date">구매 일시</div>
          <div class="plan">플랜 유형</div>
          <div class="price">구매 금액</div>
        </li>

        <li v-for="order in orders" class="row clearfix">
          <div class="date">{{ created_at(order) }}</div>
          <div class="plan">{{ planText(order) }}</div>
          <div class="price">{{ planPrice(order) }}</div>
        </li>

        <li class="row more btn" v-if="hasMore">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
        'orders', 'hasMore'
    ],
    data() {
      return {
      }
    },
    computed: {

    },
    methods: {
      created_at: function(order) {
        var date = new Date(order.created_at);
        return common.dateToString(date);
      },
      planText: function(subscribe) {
        var terms = subscribe.terms == "monthly" ? "월간" : "연간";
        var startAt = new Date(subscribe.start_at);
        var endAt = new Date(subscribe.end_at);

        return terms + "플랜 ("+ common.dateToString(startAt) + " ~ " + common.dateToString(endAt) + ")";
      },
      planPrice: function(order) {
        var price = order.price || 0;

        return price == 0 ? price : ("$" + price);
      }
    }
  }
</script>