<template>
  <div class="content-detail">
    <div class="table-container contact detail">
      <div class="table-head">
        <h2 class="title label-middle-title">
          1:1 문의하기
          <span class="count label-xxsmall fold-hide" v-if="inquiry && inquiry.comments.length > 0">
            대화 핑퐁 {{ inquiry.comments.length }}건
          </span>
        </h2>

        <button class="button button-box round bright-blue small" @click="moveToNewInquiry">
          <span>새 문의<span class="fold-hide"> 하기</span></span>
        </button>
      </div>

      <div class="row top" v-if="inquiry != null">
        <div class="col category">
          {{ getCategory }}
        </div>
        <div class="col subject label-middle-description">
          {{ inquiry.title }}
        </div>
        <div class="col date normal">
          {{ inquiry.date }}
        </div>
      </div>

      <div class="row content normal" v-if="inquiry != null">
        <div class="text label-small-description" style="white-space: pre-line">
          {{inquiry.content}}
        </div>

        <div class="attached-wrapper" v-if="inquiry.attachments.length > 0">
          <p class="bold">첨부파일 ({{ inquiry.attachments.length }})</p>

          <div
            class="attached-list"
            :class="{ even: inquiry.attachments.length % 2 == 0 }"
            v-if="inquiry.attachments.length > 0"
          >
            <div
              class="attached-item"
              v-for="(file, index) in inquiry.attachments"
            >
              <svg
                class="icon"
                width="10"
                height="20"
                viewBox="0 0 10 20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5 18.25C3.10159 18.25 1.5625 16.7107 1.5625 14.8125V3.81113C1.5625 3.26456 1.77975 2.74 2.16613 2.35362C2.5525 1.96725 3.07683 1.75 3.62363 1.75H3.62638C4.76488 1.75 5.6875 2.67263 5.6875 3.81113V13.4389C5.6875 13.8177 5.38042 14.125 5.00138 14.125H5C4.6205 14.125 4.3125 13.817 4.3125 13.4375V5.1875C4.3125 4.808 4.0045 4.5 3.625 4.5C3.2455 4.5 2.9375 4.808 2.9375 5.1875V13.4375C2.9375 14.5767 3.86104 15.5 5 15.5H5.00138C6.13988 15.5 7.0625 14.5774 7.0625 13.4389V3.81113C7.0625 1.91363 5.52388 0.375 3.62638 0.375H3.62363C2.71246 0.375 1.83842 0.737313 1.194 1.3815C0.549584 2.02569 0.1875 2.8995 0.1875 3.81113V14.8125C0.1875 17.4704 2.34213 19.625 5 19.625C6.27646 19.625 7.50021 19.1183 8.40313 18.2156C9.30559 17.3129 9.81251 16.0892 9.81251 14.8125C9.81251 10.7624 9.81251 5.1875 9.81251 5.1875C9.81251 4.808 9.5045 4.5 9.125 4.5C8.7455 4.5 8.4375 4.808 8.4375 5.1875V14.8125C8.4375 15.7241 8.07542 16.5986 7.43055 17.2435C6.78613 17.8877 5.91163 18.25 5 18.25Z"
                />
              </svg>
              <a :href="file.url" target="_blank">
              <span class="name ellipsis one">{{ file.name }}</span>
              </a>
            </div>
          </div>
        </div>

        <div class="talk-list" v-if="inquiry.comments.length > 0">
          <div class="talk-item" v-for="talk in inquiry.comments">
            <div
              class="talk-item-wrapper"
              :class="{ miilk: talk.from === 1, user: talk.from === 0 }"
            >
              <div class="profile">
                <img
                  v-if="talk.from === 1"
                  src="https://assets.themiilk.com/mypage/icon-miilk.svg"
                  alt=""
                />
                <img
                  v-if="talk.from === 0"
                  src="https://assets.themiilk.com/mypage/icon-user.svg"
                  alt=""
                />
              </div>
              <div class="date label-xsmall">{{ talk.date }}</div>
              <div class="box label-small-description">
                <p class="text">
                  {{ talk.content }}
                </p>

                <div class="attached-wrapper" v-if="talk.attachments.length > 0">
                  <p class="bold">첨부파일 ({{ talk.attachments.length }})</p>

                  <div
                    class="attached-list"
                    :class="{ even: talk.attachments.length % 2 == 0 }"
                    v-if="talk.attachments.length > 0"
                  >
                    <div
                      class="attached-item"
                      v-for="(file, index) in talk.attachments"
                    >
                      <svg
                        class="icon"
                        width="10"
                        height="20"
                        viewBox="0 0 10 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5 18.25C3.10159 18.25 1.5625 16.7107 1.5625 14.8125V3.81113C1.5625 3.26456 1.77975 2.74 2.16613 2.35362C2.5525 1.96725 3.07683 1.75 3.62363 1.75H3.62638C4.76488 1.75 5.6875 2.67263 5.6875 3.81113V13.4389C5.6875 13.8177 5.38042 14.125 5.00138 14.125H5C4.6205 14.125 4.3125 13.817 4.3125 13.4375V5.1875C4.3125 4.808 4.0045 4.5 3.625 4.5C3.2455 4.5 2.9375 4.808 2.9375 5.1875V13.4375C2.9375 14.5767 3.86104 15.5 5 15.5H5.00138C6.13988 15.5 7.0625 14.5774 7.0625 13.4389V3.81113C7.0625 1.91363 5.52388 0.375 3.62638 0.375H3.62363C2.71246 0.375 1.83842 0.737313 1.194 1.3815C0.549584 2.02569 0.1875 2.8995 0.1875 3.81113V14.8125C0.1875 17.4704 2.34213 19.625 5 19.625C6.27646 19.625 7.50021 19.1183 8.40313 18.2156C9.30559 17.3129 9.81251 16.0892 9.81251 14.8125C9.81251 10.7624 9.81251 5.1875 9.81251 5.1875C9.81251 4.808 9.5045 4.5 9.125 4.5C8.7455 4.5 8.4375 4.808 8.4375 5.1875V14.8125C8.4375 15.7241 8.07542 16.5986 7.43055 17.2435C6.78613 17.8877 5.91163 18.25 5 18.25Z"
                        />
                      </svg>
                      <a :href="file.url" target="_blank">
                      <span class="name ellipsis one">{{ file.name }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref="write"></div>
        <div class="write-reply">
          <div class="write-reply-wrapper" ref="newContent">
            <div class="form-item">
              <strong>추가문의 <span class="required">*</span></strong>
              <textarea
                class="input-form textarea"
                style="resize:none"
                type="text"
                placeholder="내용을 적어주세요."
                v-model="newInquiry.content"
              ></textarea>
            </div>
            <div class="form-item">
              <strong>첨부파일</strong>
              <div class="input-form file">
                <button class="button button-select-file button-box round" @click="selectFile">
                  파일 선택
                </button>
                <input ref="fileBtn" accept="image/*" type="file" @change="handleFileChange" style="display:none"/>
              </div>
              <div class="input-form file in-file" v-if="newInquiry.attachments.length > 0">
                <div class="thumbnail" v-for="(item, idx) in newInquiry.attachments">
                  <img :src="item.url" alt="" />
                  <button class="button-remove-image" @click="removeFile(idx)">
                    <img
                      src="https://assets.themiilk.com/mypage/icon-remove.svg"
                      alt=""
                    />
                  </button>
                </div>
                <button class="button-add-image" @click="selectFile">
                  <img
                    class="icon"
                    src="https://assets.themiilk.com/mypage/icon-add-image.svg"
                    alt=""
                  />
                </button>
              </div>
              <p class="explain">
                첨부파일은 JPG, PNG 등 3MB(3072KB) 이하의 이미지를 등록 할 수
                있습니다.
              </p>
            </div>

            <div class="buttons-wrapper">
              <button class="button button-confirm button-box round bright-blue small"
                      @click="addComment">
                등록
              </button>
              <button class="button button-box round bright-blue small" @click="moveToList">
                목록 보기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initContact: {
      type: Object,
      default: {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inquiry: null,
      newInquiry: {
        content: '',
        attachments: [],
      },
    };
  },
  mounted() {
    const self = this;
    axios.get(`/api/inquiries/${self.id}`).then(res => {
      self.inquiry = res.data.inquiry;
    });
  },
  computed: {
    getCategory() {
      let category = '기타문의';
      switch(this.inquiry.category_id) {
        case 'etc':
          category = '기타문의';
          break;
        case 'payment':
          category = '결제/환불';
          break;
        case 'account':
          category = '계정관련';
          break;
        case 'coupon':
          category = '쿠폰';
          break;
        case 'report':
          category = '기사 및 리포트 관련';
          break;
      }

      return category;
    },
  },
  components: {},
  methods: {
    scrollTo(refName) {
      const nav = document.getElementById("serviceMenu");
      let navHeight = nav.offsetHeight;

      const element = this.$refs[refName];
      const top = element.offsetTop - navHeight;

      window.scrollTo(0, top);
    },
    removeFile(idx) {
      this.newInquiry.attachments.splice(idx, 1);
    },
    selectFile() {
      this.$refs.fileBtn.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file != null) {
        const self = this;
        var frm = new FormData();
        frm.append("upload", file);
        axios.post(`/api/images/inquiries/${self.inquiry.prefix}`, frm, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          const path = res.data.newPath.path;
          const filename = res.data.newPath.filename;

          const data = {
            name: file.name,
            path: path,
            filename: filename,
            url: `https://assets.themiilk.com/${path}/origin/${filename}`
          };
          self.newInquiry.attachments.push(data);
        }).catch((error) => {
        });
      }
    },
    moveToNewInquiry() {
      location.href = "/users/me/inquiries";
    },
    addComment() {
      const self = this;
      if (this.newInquiry.content == null || this.newInquiry.content.length == 0) {
        this.scrollTo('newContent');
        return;
      }

      axios.post(`/api/inquiries/${self.inquiry.id}/comments`, self.newInquiry).then(res => {
        self.inquiry.comments = res.data.comments;
        self.clearComment();
      });
    },
    clearComment() {
      this.newInquiry.content = '';
      this.newInquiry.attachments = [];
    },
    moveToList() {
      location.href='/users/me/contacts';
    }
  },
};
</script>
