<template>
  <div class="dialog-container">
    <div class="dialog-background"></div>
    <div class="dialog-wrapper">
      <div class="button button-circle close-btn" v-on:click="closePop">
        <img class="icon-close" src="https://assets.themiilk.com/common/icon-close-white.svg" alt="Close" />
      </div>
      <div class="dialog-box">
        <div class="dialog-content">
          <div class="section-title label-small-title bold" v-if="title">{{ title }}</div>
          <div class="content-wrapper label-middle-description" v-html="description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'description'],
    data() {
      return {
      }
    },
    methods: {
      closePop: function() {
        this.$emit('canceled');
      }
    }
  }
</script>