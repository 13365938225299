<template>
<section class="my-container user">
  <GChart
    type="BarChart"
    :data="chartData"
    :options="chartOptions"
  />
</section>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: [],
    },
    top5: {
      type: Array,
      default: [],
    }
  },
  data () {
    var sectors = this.orders[0];

    var result = this.top5.reduce((a, curr, index)=>{

      a.push(sectors.indexOf(curr));

      return a;
    }, []);

    result.push(0);
    var new_result = this.orders.map(row=>{

        var new_row = row.reduce((pr, cr, index)=>{
          if(result.includes(index)){
            pr.push(cr);
          }

          return pr;
        }, []);
        return new_row;
    });
    console.log(new_result);
    this.filtered = new_result;


    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: this.orders,
      chartOptions: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',

        },
        isStacked: 'relative',
        height: 500,
        legend: { position: 'top', maxLines: 3 },
        hAxis: {
          format: 'percent'
        }
      }
    }
  },
  created() {
    this.filtered = []
  },
  mounted() {
    console.log(this.orders);
    console.log(this.orders[0])
    console.log(this.top5)
  }
}
</script>
