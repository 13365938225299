<template>
  <div class="content-detail">
    <div class="table-container contact">
      <div class="table-head">
        <h2 class="title label-middle-title">
          1:1 문의하기
          <span
            class="count label-xxsmall fold-hide"
            :class="{ active: changedTalk }"
            >대화 핑퐁 {{ contacts.length }}건</span
          >
        </h2>

        <button class="button button-box round bright-blue small" @click="newInquiry">
          <span>새 문의<span class="fold-hide"> 하기</span></span>
        </button>
      </div>

      <div class="row top">
        <div class="col category">
          카테고리
        </div>
        <div class="col subject">
          제목
        </div>
        <div class="col state">
          상태
        </div>
      </div>

      <!-- 반복 시작 -->
      <template v-if="contacts.length > 0">
        <div
            class="row my-contact"
            v-for="contact in contacts"
            :key="contact.id"
            @click="show(contact.id)"
        >
          <div class="col category">
            {{ getCategory(contact.category_id) }}
          </div>
          <div class="col subject label-middle-description ellipsis two">
            {{ contact.title }}
          </div>
          <div class="col state" :class="{ done: contact.status == 1 }">
            {{ getStatus(contact.status) }}
          </div>
        </div>
      </template>
      <!-- 반복 끝 -->

      <!-- 비었을때 시작 -->
      <div v-else class="row empty">
        <p class="title label-middle-title">아직 문의 내용이 없습니다.</p>
        <p class="description label-small">
          문의하실 내용이 있다면 문의글을 써주세요.
        </p>

        <div class="buttons-wrapper">
          <button class="button button-box round bright-blue small" @click="newInquiry">
            새 문의 하기
          </button>
        </div>
      </div>
      <!-- 비었을때 끝 -->

      <template v-if="totalPage > 1">
        <div class="pagination-wrapper">
          <ul class="pagination">
            <li class="page-item prev">
              <div class="page-link">
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>
                <span>이전</span>
              </div>
            </li>
            <div class="number">
              <li class="page-item page-link active">1</li>
              <li class="page-item page-link">2</li>
              <li class="page-item page-link">3</li>
              <li class="page-item page-link">4</li>
              <li class="page-item page-link">5</li>
            </div>
            <li class="page-item next">
              <div class="page-link link-a">
                <span>다음</span>
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="buttons-wrapper">
          <button class="button button-box round bright-blue small" @click="newInquiry">
            새 문의 하기
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      // contacts: [...this.contact],
      contacts2: [
        {
          id: "123123n23",
          category: "결제/환불",
          subject: "환불 신청을 했으나 아직 환불 되지 않았습니다.",
          reply: [],
        },
        {
          id: "1fa3123n23",
          category: "계정관련",
          subject:
            "계정이 삭제 되지 않습니다. 어떻게 하면 완전한 삭제가 되나요?",
          attached: [
            {
              name: "스크린샷 2023-02-06 오후 11.51.02",
              format: "png",
              size: 2485,
              link: "https://themiilk.com/file/a.png",
            },
            {
              name: "스크린샷 2023-02-06 오후 1.11.03",
              format: "png",
              size: 2485,
              link: "https://themiilk.com/file/a.png",
            },
            {
              name: "스크린샷 2023-01-31 오전 1.26.11",
              format: "png",
              size: 2485,
              link: "https://themiilk.com/file/a.png",
            },
          ],
          reply: [
            {
              from: "user",
              date: "2023-01-28(토) 오후 10:05",
              title: "RE: 환불 신청을 했으나 아직 환불 되지 않았습니다.",
              descriptions: [
                "Non irure exercitation dolor veniam culpa voluptate id magna ex commodo. Tempor ut in proident dolor reprehenderit nulla est nisi. Labore nulla occaecat commodo eu ullamco amet in commodo elit aute et esse mollit.",
                "Sint duis aliquip eu ullamco quis laboris eiusmod sint qui consectetur aute. Labore ea velit et deserunt aliquip sunt aute id nostrud exercitation. Eu dolor cupidatat pariatur anim. Nostrud nostrud minim et tempor culpa sint adipisicing incididunt occaecat fugiat. Enim proident ut minim ut non do.",
              ],
              attached: [
                {
                  name: "스크린샷 2023-02-06 오후 11.51.02",
                  format: "png",
                  size: 2485,
                  link: "https://themiilk.com/file/a.png",
                },
                {
                  name: "스크린샷 2023-02-06 오후 1.11.03",
                  format: "png",
                  size: 2485,
                  link: "https://themiilk.com/file/a.png",
                },
                {
                  name: "스크린샷 2023-01-31 오전 1.26.11",
                  format: "png",
                  size: 2485,
                  link: "https://themiilk.com/file/a.png",
                },
              ],
            },
            {
              from: "miilk",
              date: "2023-01-28(토) 오후 10:05",
              title: "RE: 환불 신청을 했으나 아직 환불 되지 않았습니다.",
              descriptions: [
                "Non irure exercitation dolor veniam culpa voluptate id magna ex commodo. Tempor ut in proident dolor reprehenderit nulla est nisi. Labore nulla occaecat commodo eu ullamco amet in commodo elit aute et esse mollit.",
                "Sint duis aliquip eu ullamco quis laboris eiusmod sint qui consectetur aute. Labore ea velit et deserunt aliquip sunt aute id nostrud exercitation. Eu dolor cupidatat pariatur anim. Nostrud nostrud minim et tempor culpa sint adipisicing incididunt occaecat fugiat. Enim proident ut minim ut non do.",
              ],
              attached: [
                {
                  name: "스크린샷 2023-02-06 오후 11.51.02",
                  format: "png",
                  size: 2485,
                  link: "https://themiilk.com/file/a.png",
                },
                {
                  name: "스크린샷 2023-02-06 오후 1.11.03",
                  format: "png",
                  size: 2485,
                  link: "https://themiilk.com/file/a.png",
                },
                {
                  name: "스크린샷 2023-01-31 오전 1.26.11",
                  format: "png",
                  size: 2485,
                  link: "https://themiilk.com/file/a.png",
                },
              ],
            },
          ],
        },
        {
          id: "9ada3123n23",
          category: "쿠폰사용",
          subject:
            "Dolor reprehenderit in eu non excepteur commodo voluptate id enim consequat adipisicing. Ad commodo in irure laboris Lorem in reprehenderit. Minim in in amet non nulla qui sit cupidatat non laboris laborum culpa. Et ea laboris proident ea pariatur proident minim. Eu laborum officia consequat ad consequat cillum ut elit aute eiusmod non labore.",
          reply: [],
        },
        {
          id: "9adaasd3n23",
          category: "리포트",
          subject:
            "Est anim labore aliquip cillum exercitation enim adipisicing proident exercitation. Pariatur dolore proident exercitation et eiusmod minim laboris ad excepteur ullamco reprehenderit dolor qui amet. Consequat quis consequat nostrud tempor. Ullamco consectetur do pariatur aute dolor reprehenderit adipisicing ut.",
          reply: [],
        },
        {
          id: "785das5d8a4d",
          category: "기타문의",
          subject:
            "Ipsum cupidatat elit do exercitation dolor duis in dolor ex. Dolore culpa qui officia magna commodo dolore tempor veniam nulla Lorem. Magna aliquip eu irure excepteur amet consequat duis magna et labore labore nulla ipsum. Ullamco velit irure consectetur officia nulla. Et minim quis est laboris sint ut in. Dolor consectetur laborum tempor enim culpa anim nostrud excepteur veniam eu ea dolore Lorem ullamco. Eu labore eiusmod fugiat aliqua in pariatur aliqua irure amet est est fugiat.",
          reply: [],
        },
        {
          id: "123123n23",
          category: "결제/환불",
          subject: "환불 신청을 했으나 아직 환불 되지 않았습니다.",
          reply: [],
        },
        {
          id: "1fa3123n23",
          category: "계정관련",
          subject:
            "계정이 삭제 되지 않습니다. 어떻게 하면 완전한 삭제가 되나요?",
          reply: [],
        },
        {
          id: "9ada3123n23",
          category: "쿠폰사용",
          subject:
            "Dolor reprehenderit in eu non excepteur commodo voluptate id enim consequat adipisicing. Ad commodo in irure laboris Lorem in reprehenderit. Minim in in amet non nulla qui sit cupidatat non laboris laborum culpa. Et ea laboris proident ea pariatur proident minim. Eu laborum officia consequat ad consequat cillum ut elit aute eiusmod non labore.",
          reply: [],
        },
        {
          id: "9adaasd3n23",
          category: "리포트",
          subject:
            "Est anim labore aliquip cillum exercitation enim adipisicing proident exercitation. Pariatur dolore proident exercitation et eiusmod minim laboris ad excepteur ullamco reprehenderit dolor qui amet. Consequat quis consequat nostrud tempor. Ullamco consectetur do pariatur aute dolor reprehenderit adipisicing ut.",
          reply: [],
        },
        {
          id: "785das5d8a4d",
          category: "기타문의",
          subject:
            "Ipsum cupidatat elit do exercitation dolor duis in dolor ex. Dolore culpa qui officia magna commodo dolore tempor veniam nulla Lorem. Magna aliquip eu irure excepteur amet consequat duis magna et labore labore nulla ipsum. Ullamco velit irure consectetur officia nulla. Et minim quis est laboris sint ut in. Dolor consectetur laborum tempor enim culpa anim nostrud excepteur veniam eu ea dolore Lorem ullamco. Eu labore eiusmod fugiat aliqua in pariatur aliqua irure amet est est fugiat.",
          reply: [],
        },
      ],
      contacts: [],
      changedTalk: true,
      totalPage: 1,
    };
  },
  mounted() {
    const self = this;
    axios.get("/api/inquiries").then(res => {
      self.contacts = res.data.inquiries;
      self.totalPage = res.data.total;
    });
  },
  computed: {},
  components: {},
  methods: {
    show(id) {
      location.href = "/users/me/contact-detail/" + id;
    },
    getCategory(categoryId) {
      let category = '기타문의';
      switch(categoryId) {
        case 'etc':
          category = '기타문의';
          break;
        case 'payment':
          category = '결제/환불';
          break;
        case 'account':
          category = '계정관련';
          break;
        case 'coupon':
          category = '쿠폰';
          break;
        case 'report':
          category = '기사 및 리포트 관련';
          break;
      }

      return category;
    },
    getStatus(status) {
      if (status == 'registered') {
        return "미답변";
      }

      return "답변완료";
    },
    newInquiry() {
      location.href = "/users/me/inquiries";
    }
  },
};
</script>
