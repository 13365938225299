<template>
  <div class="content-detail membership">
    <h2 class="label-middle-title">멤버십 / 구독 및 결제 관리</h2>

    <div class="membership-container expiration-date">
      <div class="info-wrapper">
        <h3 class="label-middle-description bold point">구독 플랜</h3>
        <p class="label-small-description description">{{ planText }}</p>
      </div>

      <div class="button-wrapper">
        <a class="button button-bordered no-deco" href="/users/change_plan" v-if="plan.terms == 'yearly' && corporateAccount == null">
          플랜 변경하기
        </a>
      </div>
    </div>

    <template v-if="subscribe.should_next_charge">
      <div class="membership-container next-payment-due-date">
        <div class="info-wrapper">
          <h3 class="label-middle-description bold point">다음 결제 예정 날짜</h3>
          <p class="label-small-description description">{{ subscribe.end_at }}</p>
        </div>
      </div>

      <div class="membership-container amount-to-be-paid">
        <div class="info-wrapper">
          <h3 class="label-middle-description bold point">결제 예정 금액</h3>
          <p class="label-small-description description">{{ planPrice }}</p>
        </div>

        <div class="button-wrapper">
          <a class="button button-bordered no-deco" href="/users/me/subscribes">
            쿠폰 확인하기
          </a>
        </div>
      </div>

      <div class="membership-container payment-information">
        <div class="info-wrapper">
          <h3 class="label-middle-description bold point">결제 정보</h3>
          <p class="label-small-description description">{{ currentCardInfo }}</p>
        </div>

        <div class="button-wrapper">
          <a class="button button-bordered no-deco" href="/users/change_card">
            카드 변경하기
          </a>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="membership-container expected-subscription-end-date">
        <div class="info-wrapper">
          <h3 class="label-middle-description bold point">구독 종료 예정 날짜</h3>
          <p class="label-small-description description">{{ subscribe.end_at }}</p>
        </div>
      </div>
    </template>

    <p class="label-small bold" v-if="plan.terms == 'yearly'">
      * 구독 취소 또는 및 해지를 원하시면 support@themiilk.com으로 연락주십시오.
    </p>

    <button class="button button-bordered" v-if="subscribe.terms == 'monthly' && subscribe.should_next_charge" v-on:click="changeAutoMode('stop')">
      자동 결제 중지하기
    </button>

    <button class="button button-bordered" v-if="!subscribe.should_next_charge" v-on:click="changeAutoMode('start')">
      구독 다시 시작하기
    </button>


    <layer-confirm-popup v-if="showConfirmChange" :content="confirmMessage" @close-layer-popup="changeConfirmed" />
  </div>
</template>

<script>
  import LayerConfirmPopup from "../../commons/components/layer_confirm_popup";
  export default{
    components: {LayerConfirmPopup},
    props: {
      subscribe: {
        type: Object,
        default: {},
      },
      latestCard: {
        type: Object,
        default: {},
      },
      plan: {
        type: Object,
        default: {},
      },
      nextOrderPrice: {
        type: Number,
        default: 0
      },
      corporateAccount: {
        type: Object,
        default: null,
      }
    },
    data() {
      return {
        showConfirmChange: false,
        confirmMessage: null,
        mode: null,
      };
    },
    computed: {
      planText: function() {
        if (this.plan.terms == 'monthly') {
          return "월간 플랜"
        } else if (this.plan.terms == 'yearly') {
          return "연간 플랜"
        }
      },
      planPrice: function() {
        return "$" + this.nextOrderPrice + ".00";
      },
      currentCardInfo: function() {
        return `[${this.latestCard.brand}] **** **** **** ${this.latestCard.last_number} `;
      },
    },
    methods: {
      changeAutoMode: function(mode) {
        if (mode == 'start') {
          this.confirmMessage = "더밀크 구독을 다시 시작하시겠어요?";
          this.mode = true
        } else {
          this.confirmMessage = "더밀크 구독을 중지 하시겠어요?";
          this.mode = false
        }
        this.showConfirmChange = true;
      },
      changeConfirmed: function(confirmed) {
        if (confirmed) {
          const params = {
            mode: this.mode
          };
          axios.put("/users/change_mode", params).then((res) => {
            location.reload();
          });
        } else {
          this.showConfirmChange = false;
          this.confirmMessage = null;
        }
      }
    }
  }
</script>
