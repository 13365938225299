<template>
  <ng-container>
    <span
      v-if="!article.author_list"
      class="bold"
    >{{ article.author_names[0] }}</span>
    <span 
      class="bold" 
      v-for="(author, index) in article.author_list"
      :key="`author-${author.name}`"
      v-else
    >{{index !== 0 ? ' · ' : ''}}
      <ng-container v-if="author.reporter">
        <a :href="`/reporter/${author.id}`" class="bold">{{ author.name }}</a>
      </ng-container>
      <ng-container v-else>
        {{ author.name }}
      </ng-container>
    </span>
  </ng-container>
</template>

<script>
export default {
  props: ['article'],
  mounted() {
    console.log(this.article);
  }
}
</script>