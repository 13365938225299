<template>
  <div class="plan-container padding tiny">
    <div class="content">
      <div class="content-head">
        <h1 class="label-title">플랜 변경</h1>
        <h2 class="label-small-title plan-title">
          <span>구독 플랜 선택</span>
          <hr class="mobile-only" />
        </h2>
      </div>

      <div class="content-detail">
        <ul class="plans">
          <plan-card v-for="plan in plans"
                     :key="plan.id"
                     :plan="plan"
                     :isChangeMode="event_candidate"
                     :selectedType="user.selectedType"
                     :currentPlan = "plan.id == user.currentPlan.id"
                     @selected="planSelected"
          />
        </ul>

        <div class="infos">
          <div class="box">
            이번 달 월간 플랜 구독료 <strong>환불 후</strong> 연간 플랜 구독료가 결제됩니다.
          </div>
        </div>

        <div class="payment payment-container">
          <h2 class="label-small-title payment-title">
            <span>구매 정보</span>
            <hr />
          </h2>

          <div class="amount">
            <div class="row">
              <div class="key">구매 상품</div>
              <div class="val plan" v-if="user.selectedPlan">{{user.selectedPlan.name}} 플랜</div>
            </div>

            <div class="row">
              <div class="key">결제 금액</div>
              <div class="val">${{ amount }}</div>
            </div>

            <div class="row discount" v-if="(paymentInfo.discount > 0)">
              <div class="key">할인 <span class="small"></span></div>

              <div class="val">${{paymentInfo.discount}}</div>
            </div>

            <div class="row total">
              <div class="key"><strong>최종 결제 금액</strong></div>
              <div class="val"><strong>${{ paymentInfo.totalAmount }}</strong></div>
            </div>
          </div>
        </div>

        <div class="payment payment-container" v-if="coupons.length > 0">
          <h2 class="label-small-title payment-title">
            <span>쿠폰</span>
            <hr />
          </h2>

          <div class="text-input">
            <!--              <label class="label" :class="{required: required}">할인코드</label>-->

            <div class="selectbox-element">
              <div class="selectbox-element-wrapper">
                <div class="selected">
                  <span class="">{{couponName}}</span>
                </div>
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>

                <select v-model="selectedCoupon" @change="changedCoupon">
                  <option value="-1">
                    쿠폰을 선택해주세요.
                  </option>

                  <option :value="coupon.id" v-for="coupon in coupons">
                    {{coupon.name}}
                  </option>
                </select>
              </div>

              <label class="state-message" v-if="selectedCoupon != -1">
                <i class="material-icons done">done</i>
                <small>할인 코드가 적용 되었습니다.</small>
              </label>
            </div>
          </div>
        </div>

      </div>

      <div class="content-option">
        <div class="change-button-wrapper">
          <miilk-button :v="$v" name="플랜 변경" class="primary" :isDisableStart=true @clicked="changePlan" />
        </div>
      </div>
    </div>


    <order-layer-pop v-if="confirm"
               title=""
               content="연간플랜으로 바로 적용하시겠습니까?"
               @clicked="checkConfirm"
               @canceled="closePop"
    />

    <layer-popup v-if="paymentError"
                 title="결제에 실패하였습니다."
                 content="마이페이지 > 결제수단에서 카드 변경 후 다시 진행해주세요."
                 @close-layer-popup="closeError"
    />
  </div>
</template>

<script>
import { checkDiffPlan } from '../commons/validators';
import PlanCard from "./plan_card";
import OrderLayerPop from "./layer_popup";

export default {
  props: [
    'plans', 'plan', 'event_candidate'
  ],
  data() {
    return {
      confirm: false,
      isProcessing: false,
      paymentError: false,
      coupons: [],
      user: {
        currentPlan: this.plan,
        selectedType: '',
        selectedPlanId: 2,
        selectedPlan: null,
      },
      paymentInfo: {
        cardInfo: null,
        isValidCard: false,
        isValidCvc: true,

        cardInfoError: null,
        name: null,

        discount: 0,
        discountRate: 0,
        paymentTerm: null,
        totalAmount: 0,
        isFree: false,
        selectedMethod: -1,
      },
      selectedCoupon: -1,
      uid: null,
    }
  },
  created() {
    this.uid = this.generateUUID();
    const curDom = this;
    this.user.selectedPlan = this.plans.find((plan) => {
      return plan.id == curDom.plan.id;
    });
    this.user.selectedPlanId = this.user.selectedPlan.id;
    this.user.selectedType = this.user.selectedPlan.terms;
    this.paymentInfo.totalAmount = this.user.selectedPlan.price;

    axios.get("/api/users/coupons").then(res => {
      if (res.data.coupons) {
        res.data.coupons.forEach((coupon) => {
          this.coupons.push(coupon);
        });
      }
    });
  },
  mounted() {
  },
  computed: {
    amount: function() {
      return this.user.selectedPlan?.price;
    },
    couponName() {
      if (this.selectedCoupon == -1) {
        return '쿠폰을 선택해주세요.';
      }

      const coupon = this.coupons.find(x => x.id == this.selectedCoupon);
      return coupon.name;
    },
  },
  methods: {
    generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : r & 0x7 | 0x8).toString(16);
      });
      return uuid;
    },
    planSelected: function(type, id) {
      this.user.selectedType = type;
      this.user.selectedPlan = this.plans.find((plan) => {
        return plan.id == id;
      });
      this.user.selectedPlanId = this.user.selectedPlan.id;
      this.paymentInfo.discount = 0;
      this.paymentInfo.discountRate = 0;
      this.paymentInfo.totalAmount = this.user.selectedPlan.price;
      this.selectedCoupon = -1;

      this.$v.$reset();
      this.$v.$touch();
    },
    changedCoupon() {
      // check price
      axios.get(`/api/orders/calculate_price?plan_id=${this.user.selectedPlan.id}&coupon_id=${this.selectedCoupon}`).then(res => {
        this.paymentInfo.discount = res.data.discount;
        this.paymentInfo.discountRate = res.data.discount_rate;
        this.paymentInfo.totalAmount = res.data.price;
      });
    },
    changePlan: function() {
      if (this.user.selectedType == 'yearly') {
        if (!this.isProcessing) {
          this.confirm = true;
        }
      } else {
        const id = this.user.selectedPlanId;
        const data = {
          plan_id: id,
          uid: this.uid,
        };
        const formData = common.objectToFormData(data);

        axios.put(`/users/change_plan`, formData).then(response => {
          location.href = "/users/me";
        }).catch(this.onSaveError);
      }
    },
    checkConfirm: function(confirmed) {
      this.confirm = false;
      this.isProcessing = true;
      const data = {
        coupon: this.selectedCoupon,
        uid: this.uid,
      };

      const self = this;
      axios.put(`/users/change_to_yearly_plan`, data).then(response => {
        location.href = self.getReturnUrl();
      }).catch(e => {
        this.paymentError = true;
        this.isProcessing = false;
      });
    },
    closePop: function(e) {
      this.confirm = false;
    },
    closeError: function() {
      location.href = '/users/me/cards';
    },
    getReturnUrl: function() {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("url")) {
        return urlParams.get("url");
      }

      return "/users/me";
    },
  },
  validations: {
    user: {
      selectedPlan: {checkDiffPlan},
    }
  },
  components: {
    PlanCard, OrderLayerPop
  }
}
</script>
