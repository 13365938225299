<template>
<div class="rating-box" :class="{'scrollable': numInvestors > 5}">
  <div class="rating-wrapper">
      <ul class="list" :data-count="numInvestors" v-if="numInvestors">
        <li class="item" v-for="investor in investors" :class="{'active': investor.nickname == nickname}" :key="investor.id">
          <a :href="'/investors/' + investor.nickname" class='no-deco'>
          <div class="item-wrapper">  
            <div class="image-wrapper remove-line-height">
              <div class="activator"></div>
              <img class="image" :src="'https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/investors/profile/' + investor.nickname + '.jpg'" alt="" />
                <div class="investor-marks label-sub-title thin">
                  {{investor.eng_name.split(' ').map((x)=>x.charAt(0)).join('').slice(0,2)}}
                </div>
            </div>
            <div class="infor-wrapper">
              <div class="point ellipsis one">{{investor.name}}</div>
              <div class="description ellipsis one">{{investor.eng_name}}
                  <!--span class="option age">90</span-->
              </div>
              <!--ul class="rating-box">
                  <li class="rating-item f-100">
                  <div class="rating-item-fill">
                  </div>
                  </li>
                  <li class="rating-item f-100">
                  <div class="rating-item-fill">
                  </div>
                  </li>
                  <li class="rating-item f-100">
                  <div class="rating-item-fill">
                  </div>
                  </li>
                  <li class="rating-item f-100">
                  <div class="rating-item-fill">
                  </div>
                  </li>
                  <li class="rating-item f-100">
                  <div class="rating-item-fill">
                  </div>
                  </li>
              </ul-->
            </div>
          </div>
          </a>
        </li>
      </ul>      
      <div class="message empty" v-else >아직 등록된 투자자가 없습니다.</div>
    </div>      
    <div class="rating-box-controller">
      <button class="button prev">
          <div class="icon-arrow"></div>
      </button>
      <button class="button next" >
          <div class="icon-arrow"></div>
      </button>
      </div>

      <div class="rating-box-scroller">
      <div class="bar"></div>
    </div>
  
</div>
</template>
<script>
  export default {
    props: {
      investors: {
        type: Array,
        default: []
      },
      nickname: {
        type: String,
        default: ''
      }
    },
    data() {
      console.log(this.investors)
      return {
        selectedQuestion: 1,
        numInvestors: this.investors.length || false
      }
    },
  }
</script>