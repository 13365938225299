<template>
  <section class="business-apply business-signup bg-grey_light_1">
    <section class="business-apply-hero relative">
      <div class="w-full pointer-events-none">
        <img
            class="object-cover object-bottom w-full h-business_hero_fold xxxs:h-business_hero_mobile md:h-business_hero_tablet lg:h-auto max-h-business_hero"
            src="https://assets.themiilk.com/business/hero-bg-2023-12.jpg"
            alt=""
        />
      </div>

      <div
          class="tw-content-wrapper text-center absolute top-1/2 left-1/2 mt-business_subscribe_head transform -translate-x-1/2 -translate-y-1/2"
      >
        <h1 class="type-h1 font-thin leading-tight">
          더밀크가 제공하는 <br /><span class="font-bold"
        >기업고객만을 위한 플랜</span
        >
        </h1>

        <p class="type-p1 font-thin">
          실리콘벨리 혁신기업들의 최신뉴스를 빠르게 만날 수 있는 더밀크의
          기업고객 플랜
          <br class="hidden xs:inline" />
          기업고객만을 위한 남다른 혜택을 고민하고 담았습니다.
        </p>

        <div class="tw-button-wrapper justify-center">
          <a
              class="tw-button-fill icon black"
              :href="
              `https://assets.themiilk.com/commons/attachments/themiilk.pdf?${getTime}`
            "
              target="_blank"
          >
            <span>서비스 소개서 다운로드</span>
            <span class="icon">
              <img
                  src="https://assets.themiilk.com/business/icon-download-circular.svg"
                  alt=""
              />
            </span>
          </a>
        </div>
      </div>
    </section>

    <section class="tw-content-wrapper relative -mt-20 mb-20 md:mb-32">
      <div class="mobile-hide">
        <h3 class="mb-0 px-5 xl:px-7 type-h3">더밀크 기업구독 안내</h3>

        <div class="relative mt-4">
          <div
              class="z-1 business-pricing-table-head pointer-events-none absolute right-m3px -bottom-m3px h-full rounded-bl-5 rounded-br-20 xl:rounded-br-30 border-3 border-cerulean_blue box-content"
          >
            <div
                class="absolute right-m3px bottom-full w-full py-3 xl:py-4 text-12 xl:text-14 text-white text-center xl:font-bold bg-cerulean_blue rounded-tl-10 md:rounded-tl-20 rounded-tr-10 md:rounded-tr-20 border-3 border-cerulean_blue box-content"
            >
              2023년 11월 부터 기업용 서비스 확대
            </div>
          </div>

          <div
              class="grid grid-col-1 gap-px text-14 text-center rounded-tl-20 xl:rounded-tl-30 rounded-br-20 xl:rounded-br-30 rounded-bl-20 xl:rounded-bl-30 shadow-rounded_box"
          >
            <div
                v-for="(item, index) in modelsRow"
                class="business-pricing-table w-full grid gap-px bg-grey_super_light_alpha"
                :class="{
                'rounded-tl-20 xl:rounded-tl-30': index === 0,
                'rounded-br-20 xl:rounded-br-30 rounded-bl-20 xl:rounded-bl-30':
                  index === modelsRow.length - 1,
              }"
            >
              <div
                  class="flex items-center text-left"
                  :class="{
                  'p-5 xl:p-7 text-12 md:text-14 lg:text-16 font-bold':
                    index === 0,
                  'px-5 xl:px-7 py-4': index > 0,
                  'bg-white': !item.isPoint,
                  'bg-toasted_marshmallow_fluff': item.isPoint,
                  'rounded-tl-20 xl:rounded-tl-30': index === 0,
                  'rounded-bl-20 xl:rounded-bl-30':
                    index === modelsRow.length - 1,
                }"
              >
                <span class="inline-flex items-center">
                  <span :class="{'font-bold': item.isBold}">
                    {{ item.title }}
                    <span
                        v-if="item.titleSuffix"
                        class="font-light opacity-50"
                    >{{ item.titleSuffix }}</span
                    >
                  </span>
                  <span
                      v-if="item.isNew"
                      class="ml-2 px-2 py-1 bg-tangerine_tango text-10 text-white leading-none rounded-full"
                  >NEW</span
                  >
                  <span
                      v-if="item.isHot"
                      class="ml-2 px-2 py-1 bg-red_popularity text-10 text-white leading-none rounded-full"
                  >HOT</span
                  >
                  <span
                      v-if="item.isOutline"
                      class="ml-2 px-6px py-1 bg-transparent text-10 whitespace-nowrap leading-none rounded-full border border-black"
                  >{{ item.isOutline }}</span
                  >
                </span>
              </div>

              <div
                  class="flex justify-center"
                  :class="{
                  'text-gray-400': models[1].options[index].isOff,
                  'relative justify-center lg:justify-between items-center p-5 xl:p-7 text-12 md:text-14 lg:text-16 font-bold':
                    index === 0,
                  'items-center px-5 xl:px-7 py-4': index !== 0,
                  'bg-white': !models[1].options[index].isPoint,
                  'bg-toasted_marshmallow_fluff':
                    models[1].options[index].isPoint,
                  'flex-col': models[1].options[index].subValue,
                }"
              >
                <div
                    v-if="index === 0"
                    class="pointer-events-none z-0 absolute top-0 left-0 w-full h-full bg-cerulean_blue_5"
                ></div>
                <img
                    v-if="models[1].options[index].isCheckCircle"
                    src="https://assets.themiilk.com/business/icon-check-green-outline.svg"
                    alt=""
                />
                <img
                    v-if="models[1].options[index].isCheck"
                    src="https://assets.themiilk.com/business/icon-check-white.svg"
                    class="rounded-full w-auto bg-green_confirm"
                    alt=""
                />
                <span
                    v-if="models[1].options[index].value"
                    :class="{'font-bold': models[1].options[index].isBold}"
                >{{ models[1].options[index].value }}</span
                >
                <span
                    v-if="models[1].options[index].subValue"
                    class="mt-0.5 text-12 opacity-50"
                >{{ models[1].options[index].subValue }}</span
                >
                <div
                    v-if="index === 0 && models[1].icon"
                    class="subscribe-type-icon relative bg-grey_super_light_alpha rounded-full"
                >
                  <img :src="models[1].icon" alt="" />
                </div>
              </div>

              <div
                  class="flex justify-center"
                  :class="{
                  'text-gray-400': models[2].options[index].isOff,
                  'relative justify-center lg:justify-between items-center p-5 xl:p-7 text-12 md:text-14 lg:text-16 font-bold':
                    index === 0,
                  'items-center px-5 xl:px-7 py-4': index !== 0,
                  'bg-white': !models[2].options[index].isPoint,
                  'bg-toasted_marshmallow_fluff':
                    models[2].options[index].isPoint,
                  'flex-col': models[2].options[index].subValue,
                }"
              >
                <div
                    v-if="index === 0"
                    class="pointer-events-none z-0 absolute top-0 left-0 w-full h-full bg-cerulean_blue_7"
                ></div>
                <img
                    v-if="models[2].options[index].isCheckCircle"
                    src="https://assets.themiilk.com/business/icon-check-green-outline.svg"
                    alt=""
                />
                <img
                    v-if="models[2].options[index].isCheck"
                    src="https://assets.themiilk.com/business/icon-check-white.svg"
                    class="rounded-full w-auto bg-green_confirm"
                    alt=""
                />
                <span
                    v-if="models[2].options[index].value"
                    :class="{'font-bold': models[2].options[index].isBold}"
                >{{ models[2].options[index].value }}</span
                >
                <span
                    v-if="models[2].options[index].subValue"
                    class="mt-0.5 text-12 opacity-50"
                >{{ models[2].options[index].subValue }}</span
                >
                <div
                    v-if="index === 0 && models[2].icon"
                    class="subscribe-type-icon relative bg-grey_super_light_alpha rounded-full"
                >
                  <img :src="models[2].icon" alt="" />
                </div>
              </div>

              <div
                  class="flex justify-center"
                  :class="{
                  'text-gray-400': models[3].options[index].isOff,
                  'relative justify-center lg:justify-between items-center p-5 xl:p-7 text-12 md:text-14 lg:text-16 font-bold':
                    index === 0,
                  'items-center px-5 xl:px-7 py-4': index !== 0,
                  'bg-white': !models[3].options[index].isPoint,
                  'bg-toasted_marshmallow_fluff':
                    models[3].options[index].isPoint,
                  'rounded-br-20 xl:rounded-br-30':
                    index === modelsRow.length - 1,
                  'flex-col': models[3].options[index].subValue,
                }"
              >
                <div
                    v-if="index === 0"
                    class="pointer-events-none z-0 absolute top-0 left-0 w-full h-full bg-cerulean_blue_10"
                ></div>
                <img
                    v-if="models[3].options[index].isCheckCircle"
                    src="https://assets.themiilk.com/business/icon-check-green-outline.svg"
                    alt=""
                />
                <img
                    v-if="models[3].options[index].isCheck"
                    src="https://assets.themiilk.com/business/icon-check-white.svg"
                    class="rounded-full w-auto bg-green_confirm"
                    alt=""
                />
                <span
                    v-if="models[3].options[index].value"
                    :class="{'font-bold': models[3].options[index].isBold}"
                >{{ models[3].options[index].value }}</span
                >
                <span
                    v-if="models[3].options[index].subValue"
                    class="mt-0.5 text-12 opacity-50"
                >{{ models[3].options[index].subValue }}</span
                >
                <div
                    v-if="index === 0 && models[3].icon"
                    class="subscribe-type-icon relative bg-grey_super_light_alpha rounded-full"
                >
                  <img :src="models[3].icon" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            class="tw-button-wrapper sticky xs:relative bottom-0 justify-center py-10 xs:py-0 -mt-5 xs:mt-5 md:mt-10 -mb-10 xs:mb-0 text-center"
        >
          <button
              class="tw-button-fill large confirm w-full xs:w-auto min-w-button_large mx-auto"
              ref="requestButton"
              @click="openPopup"
          >
            <span class="font-bold">견적 문의하기</span>
          </button>
        </div>
      </div>

      <div class="mobile-only">
        <div class="grid gap-7">
          <div
              v-for="(item, index) in reversedModels"
              v-if="index !== reversedModels.lenght - 1"
          >
            <div
                v-if="index < reversedModels.length - 1"
                class="p-5 bg-white rounded-20 shadow-rounded_box"
            >
              <div class="flex items-center justify-between w-full">
                <div class="flex flex-col">
                  <span class="font-bold">{{ item.name }}</span>
                  <span class="text-11 opacity-50">{{ item.subscribe }}</span>
                </div>
                <div class="flex-none ml-2.5">
                  <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM17.7846 30.6535C18.9249 31.8721 20.0647 33.0903 21.2077 34.3035C21.4924 34.6056 21.9463 34.6056 22.2232 34.3035L36.3244 19.3535C36.609 19.0595 36.609 18.5778 36.3244 18.2757L33.4164 15.1893C33.1318 14.8954 32.6779 14.8954 32.3933 15.1893L21.7309 26.5141L17.5844 22.1132C17.3074 21.8193 16.8458 21.8193 16.5689 22.1132L13.6686 25.1914C13.3917 25.4853 13.3917 25.9752 13.6686 26.2692C15.0454 27.726 16.4153 29.1901 17.7846 30.6535Z"
                        :class="{
                        'fill-check_green opacity-80': index === currentModel,
                        'fill-check_grey opacity-50': index !== currentModel,
                      }"
                    />
                  </svg>
                </div>
              </div>

              <div class="mt-3 type-h1 flex justify-start font-bold">
                {{
                  isCurrencyUSD
                      ? item.options[3].value
                      : item.options[3].subValue.split(" / ")[0]
                }}
                <span
                    class="-mt-3 ml-2 text-15 text-red_cancel_hover font-light line-through"
                >{{
                    isCurrencyUSD
                        ? item.options[2].value
                        : item.options[2].subValue.split(" / ")[0]
                  }}</span
                >
              </div>

              <div
                  @click="changeCurrency"
                  class="overflow-hidden relative flex flex-nowrap items-center w-16 h-7 mt-2.5 p-1 bg-grey_light_1 text-11 border rounded-full"
              >
                <div
                    class="flex-none w-14 px-1 transform transition-all ease-default duration-750 translate-x-0"
                    :class="{'-translate-x-full': !isCurrencyUSD}"
                >
                  원화
                </div>
                <div
                    class="flex-none w-14 pr-1.5 text-right transform transition-all ease-default duration-750 translate-x-0"
                    :class="{'-translate-x-full': !isCurrencyUSD}"
                >
                  USD
                </div>
                <div
                    class="absolute top-1 w-switch_small h-switch_small bg-blue_chaos rounded-full transform transition-all ease-default duration-750"
                    :class="{
                    'right-1 translate-x-0': isCurrencyUSD,
                    'right-full translate-x-6': !isCurrencyUSD,
                  }"
                ></div>
              </div>

              <div class="mt-5 pt-5 border-t border-grey_super_light_alpha">
                <p class="text-12">계정수 *</p>
                <p class="mt-0.5 type-h2 font-bold">{{ item.account }}</p>
              </div>

              <button
                  class="tw-button-fill large confirm w-full xs:w-auto min-w-button_large mx-auto mt-3"
                  ref="requestButton"
                  @click="openPopup(models.length - 1 - index)"
              >
                <span class="font-bold">견적 문의하기</span>
              </button>

              <div class="flex justify-between items-center mt-6 text-14">
                <span
                ><span class="font-bold"
                >{{
                    item.options
                        .slice(4)
                        .filter((item) => item.available === true).length
                  }}가지</span
                >
                  제공 서비스</span
                >
                <button
                    class="flex items-center"
                    @click="item.showServiceMobile = !item.showServiceMobile"
                >
                  <span class="mr-3">자세히 보기</span>
                  <div class="flex justify-center items-center">
                    <div
                        class="flex-none w-1.5 h-1.5 border-t border-r transform"
                        :class="{
                        'border-button_confirm rotate-135 -translate-y-px': !item.showServiceMobile,
                        'border-grey_50_alpha -rotate-45 translate-y-px':
                          item.showServiceMobile,
                      }"
                    ></div>
                  </div>
                </button>
              </div>

              <div v-if="item.showServiceMobile" class="mt-2.5">
                <div v-for="(option, index) in item.options.slice(4)">
                  <div
                      v-if="option.available"
                      class="flex items-start py-4 text-14 border-t border-grey_super_light_alpha"
                      :class="{ 'pb-0': index === item.options.slice(4).length - 1, }"
                  >
                    <img
                        src="https://assets.themiilk.com/business/icon-check-white.svg"
                        alt=""
                        class="flex-none mr-2.5 rounded-full xs:mr-2 xl:mr-3 md:w-3 lg:w-auto bg-green_confirm transform translate-y-px"
                    />
                    <div class="">
                      <p>
                        {{logT(modelsRow[index + 4])}}
                        <span v-if="modelsRow[index + 4] && modelsRow[index + 4].mobileTitlePrefix" >
                          {{ modelsRow[index + 4].mobileTitlePrefix }}
                        </span >
                        {{ modelsRow[index + 4].mobileTitle || modelsRow[index + 4].title }}
                      </p>
                      <p
                          v-if="modelsRow[index + 4].mobileDescription"
                          class="mt-2.5 text-12 opacity-60"
                      >
                        {{ modelsRow[index + 4].mobileDescription }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="business-apply-content relative -mt-7 sm:mt-0">
      <div class="tw-content-wrapper full spacing-y bottom-only">
        <div class="max-w-1100 mx-auto">
          <div class="mt-20">
            <h3 class="mb-5 xs:mb-8 type-h2 text-primary_blue_deep font-light">
              <span class="relative">
                <span
                    class="absolute -left-1 bottom-0 w-pen_mark h-1/2 bg-brilliant_azure opacity-20"
                ></span>
                <span class="relative">
                  기업구독 제공 콘텐츠
                </span>
              </span>
            </h3>

            <div class="flex flex-col pl-2.5 xs:pl-5">
              <div
                  v-for="(content, index) in subscribeContents"
                  class=""
                  :class="{
                  'pb-8 xs:pb-10': index < subscribeContents.length - 1,
                }"
              >
                <h4 class="type-h4 flex mb-2">
                  <span class="flex-none mr-2 font-light"
                  >{{ index + 1 }}.</span
                  >
                  {{ content.title }}
                </h4>

                <div
                    v-if="content.descriptions"
                    class="ml-2 xs:ml-7 pt-3 pl-4 xs:pl-7 border-l border-grey_super_light_alpha"
                >
                  <p
                      v-for="(item, index) in content.descriptions"
                      class="type-p1"
                      :class="{'pb-5': index < content.descriptions.length - 1}"
                  >
                    <span v-if="item.prefix" class="font-bold">{{
                        item.prefix
                      }}</span
                    >{{ item.description }}
                  </p>

                  <div
                      v-if="content.boxs"
                      class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5 mt-4 sm:mt-5"
                  >
                    <div
                        v-for="(box, index) in content.boxs"
                        class="p-5 bg-white_50 border border-grey_super_light7 rounded-20"
                    >
                      <p v-if="box.title" class="type-p1 font-bold">
                        {{ box.title }}
                        <span v-if="box.suffix" class="font-light">{{
                            box.suffix
                          }}</span>
                      </p>
                      <p
                          v-if="box.description"
                          class="mt-2.5 text-14 opacity-60"
                      >
                        {{ box.description }}
                      </p>
                    </div>
                  </div>

                  <p
                      v-if="content.lower_description"
                      class="mt-4 text-11 opacity-50"
                  >
                    {{ content.lower_description }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-16">
            <h3 class="mb-5 xs:mb-8 type-h2 text-diamine_green font-light">
              <span class="relative">
                <span
                    class="z-0 absolute -left-1 bottom-0 w-pen_mark h-1/2 bg-acid_pops opacity-20"
                ></span>
                <span class="relative">
                  기업 홍보 및 기사 발행
                </span>
              </span>
            </h3>

            <div class="flex flex-col pl-2.5 xs:pl-5">
              <div
                  v-for="(content, index) in publishContents"
                  class=""
                  :class="{
                  'pb-8 xs:pb-10': index < publishContents.length - 1,
                }"
              >
                <h4 class="type-h4 flex mb-2">
                  <span class="flex-none mr-2 font-light"
                  >{{ index + 5 }}.</span
                  >
                  {{ content.title }}
                </h4>

                <div
                    v-if="content.descriptions"
                    class="ml-2 xs:ml-7 pt-3 pl-4 xs:pl-7 border-l border-grey_super_light_alpha"
                >
                  <p
                      v-for="(item, index) in content.descriptions"
                      class="type-p1"
                      :class="{'pb-5': index < content.descriptions.length - 1}"
                  >
                    <span v-if="item.prefix" class="font-bold">{{
                        item.prefix
                      }}</span
                    >{{ item.description }}
                  </p>

                  <div
                      v-if="content.boxs"
                      class="grid grid-cols-1 gap-4 sm:gap-5 mt-4 sm:mt-5"
                      :class="{
                      'sm:grid-cols-2': content.boxs.length <= 2,
                      'sm:grid-cols-3': content.boxs.length === 3,
                    }"
                  >
                    <div
                        v-for="(box, index) in content.boxs"
                        :class="{
                        'p-5 bg-white_50 border border-grey_super_light7 rounded-20': !box.image,
                        'overflow-hidden bg-white_50 border border-grey_super_light7 rounded-20':
                          box.image,
                      }"
                    >
                      <p
                          v-if="!box.image && box.title"
                          class="type-p1 font-bold"
                      >
                        {{ box.title }}
                        <span v-if="box.suffix" class="font-light">{{
                            box.suffix
                          }}</span>
                      </p>
                      <p
                          v-if="!box.image && box.description"
                          class="mt-2.5 text-14 opacity-60"
                      >
                        {{ box.description }}
                      </p>
                      <img v-if="box.image" :src="box.image" alt="" />
                    </div>
                  </div>

                  <p
                      v-if="content.lower_description"
                      class="mt-4 text-11 opacity-50"
                  >
                    {{ content.lower_description }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-20">
            <h3 class="mb-5 xs:mb-8 type-h2 font-bold">
              더밀크와 함께하고 있는 기업 및 기관
            </h3>

            <div class="flex flex-wrap items-center">
              <div
                  v-for="company in companies"
                  class="flex justify-center items-center w-1/3 xs:w-1/6"
              >
                <img class="max-w-full xs:max-w-85%" :src="company" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div
            class="pointer-events-none sticky bottom-10 right-0 -mb-14 pt-3 xs:pt-5 transform transition-opacity"
        >
          <button
              class="pointer-events-auto tw-button-fill confirm ml-auto px-5 xl:px-8 rounded-2xl xl:rounded-full"
              @click="openPopup"
          >
            <img
                class="xl:hidden w-7 mr-3 xs:mr-0"
                src="https://assets.themiilk.com/business/icon-request-form.svg"
                alt=""
            />
            <span class="xs:hidden xl:inline font-bold"
            ><span class="hidden 2xl:inline">견적 </span>문의하기</span
            >
          </button>
        </div>
      </div>
    </section>

    <div
        class="pointer-events-none z-1000 fixed top-0 left-0 flex flex-col items-center w-screen h-screen px-2.5 xxxs:px-5 xs:px-10 py-10 xs:pt-28 xs:pb-16"
        :class="{
        'pointer-events-auto': showPopup,
        'overflow-y-auto': showPopup,
      }"
    >
      <div
          class="pointer-events-none fixed top-0 left-0 w-screen h-screen bg-black_85 transition-opacity ease-default duration-750"
          :class="{
          'pointer-events-auto': showPopup,
          'opacity-0': !showPopup,
          'opacity-100': showPopup,
        }"
          @click="closePopup"
      ></div>
      <div
          class="relative pointer-events-none w-full max-w-business_popup opacity-0 transform transition-transform_opacity ease-default duration-750"
          :class="{
          'pointer-events-auto': showPopup,
          'translate-y-36': !showPopup,
          'translate-y-0': showPopup,
          'opacity-100': showPopup,
        }"
      >
        <div
            class="overflow-hidden mx-auto p-5 xxxs:p-6 xs:p-8 bg-white rounded-3xl xs:rounded-50"
        >
          <div class="flex justify-between items-start">
            <div class="type-h3">
              <div class="flex items-center font-bold">
                {{ models[currentModel].name }}
                <div
                    class="relative flex items-center selectbox ml-2 px-2.5 py-1 text-xs font-normal border grey_30_alpha rounded-full"
                >
                  변경
                  <span
                      class="flex-none w-1.5 h-1.5 ml-2.5 border-r border-b border-grey_30_alpha transform -translate-y-0.5 rotate-45"
                  ></span>
                  <select
                      class="absolute top-0 left-0 w-full h-full opacity-0"
                      v-model="currentModel"
                      @change="changeModel"
                  >
                    <option v-for="(model, index) in models" :value="index"
                    >{{ model.name }}: {{ model.account }}</option
                    >
                  </select>
                </div>
              </div>
              <span class="mt-1 font-light">라이선스</span>
            </div>

            <div
                class="subscribe-type-icon absolute xxxs:relative top-0 right-0 flex-none w-10 xs:w-auto ml-4 bg-gray-100 rounded-full transform transition ease-default duration-750 -translate-y-5 xxxs:translate-y-0"
            >
              <img class="w-60px" :src="models[currentModel].icon" alt="" />
            </div>
          </div>
          <p class="mt-2 text-xs opacity-30">
            {{ models[currentModel].account }}
          </p>

          <div class="form mt-4">
            <div class="form-item input flex flex-col mt-4">
              <p class="text-11 xs:text-xs font-bold">
                회사명 <span class="text-red_popularity">*</span>
              </p>
              <input
                  class="input-text"
                  type="text"
                  v-model="apply.company_name"
                  placeholder="회사명을 입력하세요."
              />
            </div>

            <div class="form-item input flex flex-col mt-4">
              <p class="text-11 xs:text-xs font-bold">
                담당자 <span class="text-red_popularity">*</span>
              </p>
              <input
                  class="input-text"
                  type="text"
                  v-model="apply.user_name"
                  placeholder="담당자를 입력하세요."
              />
            </div>

            <div class="form-item input flex flex-col mt-4">
              <p class="text-11 xs:text-xs font-bold">
                연락처 <span class="text-red_popularity">*</span>
              </p>
              <input
                  class="input-text"
                  type="text"
                  v-model="apply.phone_number"
                  placeholder="연락처를 입력하세요."
              />
            </div>

            <div class="form-item input flex flex-col mt-4">
              <p class="text-11 xs:text-xs font-bold">
                이메일 <span class="text-red_popularity">*</span>
              </p>
              <input
                  class="input-text"
                  type="text"
                  v-model="apply.email"
                  placeholder="이메일 입력하세요."
              />
            </div>

            <div class="form-item flex flex-col mt-4">
              <p class="text-11 xs:text-xs font-bold">
                기타 문의사항
              </p>
              <textarea
                  class="textarea"
                  type="text"
                  placeholder="내용을 입력하세요."
                  v-model="apply.etc"
              ></textarea>
            </div>

            <div class="tw-button-wrapper flex-col xxxs:flex-row">
              <button
                  class="tw-button-fill icon confirm flex-1"
                  @click="submit"
                  :disabled="isLoading"
              >
                견적 문의하기
                <img
                    class="icon w-5"
                    :class="{'opacity-75': isLoading}"
                    src="https://assets.themiilk.com/business/icon-send.svg"
                />
              </button>
              <button
                  class="tw-button-fill cancel short xxxs:ml-2.5 xs:ml-4 mt-2.5 xxxs:mt-0"
                  @click="closePopup"
              >
                닫기
              </button>
            </div>
          </div>
        </div>
        <div
            class="mark absolute top-0 left-0 xs:left-3 transform -translate-y-1/2"
        >
          <div
              class="popularity py-1 pr-2 pl-6 text-11 text-white font-bold bg-red_popularity rounded-full"
              v-if="currentModel === pointModel"
          >
            <img
                class="absolute top-1/2 left-2 transform -translate-x-1/2 -translate-y-1/2"
                src="https://assets.themiilk.com/business/icon-flame.svg"
                alt=""
            />
            인기!!
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  props: {},
  data() {
    return {
      isLoading: false,
      showPopup: false,
      pointModel: 0,
      currentModel: 0,
      isCurrencyUSD: true,
      modelsRow: [
        {title: "플랜"},
        {title: "최대 이용계정 수"},
        {title: "구독 가격", isNew: true},
        {
          title: "주 3회 뷰스레터 & 주 1회 밀키스레터",
          mobileTitle: "주 3회 뷰스레터 & 주 1회 밀키스레터",
        },

        {
          title: "더밀크 기사 무제한 이용",
          mobileTitle: "더밀크 기사 무제한 이용",
        },
        {
          title: "트렌드피드 리포트 무료 제공",
          mobileTitle: "트렌드피드 리포트 무료 제공",
        },
        {
          title: "더밀크 Top Pick 투자 리포트 할인",
          mobileTitle: "더밀크 Top Pick 투자 리포트 할인",
        },
        {
          title: "CES/MWC K-Night 초대권 ($500) 제공",
          mobileTitle: "CES/MWC K-Night 초대권 제공",
          mobileDescription:
              "더밀크의 K-Night은 VIP 구독자 중심 네트워킹 & 인사이트 이벤트로 국내 정,재계 인사들과 글로벌 기업인, 실리콘밸리 혁신가들이 참석",
          isNew: true,
        },
        {
          title: "CES/MWC 리포트 풀버젼 ($4,000) 제공",
          mobileTitle: "CES/MWC 리포트 풀버젼 제공",
          mobileDescription:
              "CES/MWC 행사의 A-Z를 총망라, 더밀크만의 인사이트를 전달",
        },

        {
          title: "기업용 ‘실리콘밸리 정보보고' (Hot Source) 제공",
          mobileTitle: "기업용 ‘실리콘밸리 정보보고' (Hot Source) 제공",
          mobileDescription:
              "실리콘밸리 현장에서 일어나는 기사화되지 않은 숨어있는 알짜 정보들을 모아 전달.",
          isNew: true,
        },
        // {
        //   title: "CES 리포트 요약본 ($300 상당) 무료 제공",
        //   mobileTitle: "CES 리포트 요약본 ($300 상당) 무료 제공",
        // },
        {
          title: "기업용 월간 AI 트렌드 리포트 제공",
          mobileTitle: "기업용 월간 AI 트렌드 리포트 제공",
          mobileDescription:
              "실리콘밸리와 뉴욕, 그 외의 미국, 유럽에서 매일 새롭게 벌어지는 새로운 AI 이벤트를 공유",
          isNew: true,
        },
        {
          title: "더밀크 발간 리포트 제공 (CES/MWC 별도)",
          mobileTitle: "더밀크 발간 리포트 제공 (CES/MWC 별도)",
        },
        {
          title: "기업 브랜디드 콘텐츠 ($10,000 상당)",
          mobileTitlePrefix: "기사/홍보",
          mobileTitle: "기업 브랜디드 콘텐츠 ($10,000 상당)",
          isOutline: "기사/홍보",
          isPoint: true,
        },
        {
          title: "뷰스레터 내 기업 광고 ($5,000) 상당",
          mobileTitlePrefix: "기사/홍보",
          mobileTitle: "뷰스레터 내 기업광고 ($5,000 상당)",
          isOutline: "기사/홍보",
          isPoint: true,
        },
      ],
      models: [
        {
          name: "월간플랜 개인",
          subscribe: "1개월 구독",
          account: "1개",
          icon: "https://assets.themiilk.com/business/icon-silver.svg",
          showServiceMobile: false,
          options: [
            {value: "월간플랜", valueSuffix: "개인"},
            {value: "1개", available: true},
            {value: "$25", isBold: true, subValue: "1개월", available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},

            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "없음", isOff: true, available: false},
            {value: "없음", isOff: true, available: false},

            {value: "없음", isOff: true, available: false},
            // {value: "없음", isOff: true, available: false},
            {value: "없음", isOff: true, available: false},
            {value: "없음", isOff: true, available: false, isPoint: true},
            {value: "없음", isOff: true, available: false, isPoint: true},

            {value: "없음", isOff: true, available: false, isPoint: true},
          ],
        },
        {
          name: "에센셜",
          subscribe: "1년 구독",
          account: "최대 10개",
          icon: "https://assets.themiilk.com/business/icon-gold.svg",
          showServiceMobile: false,
          options: [
            {value: "에센셜"},
            {value: "최대 10개", available: true},
            { value: "$3,750", isBold: true, subValue: "500만원 / 1년", available: true, },
            // { value: "$2,250", isBold: true, subValue: "300만원 / 1년", available: true, },
            {isCheckCircle: true, available: true},

            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "없음", isOff: true, available: false},
            {value: "25% 할인", isBold: true, available: true},

            {value: "없음", isOff: true, available: false},
            // {isCheck: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "없음", isOff: true, available: false, isPoint: true},

            {value: "없음", isOff: true, available: false, isPoint: true},
          ],
        },
        {
          name: "프로",
          subscribe: "1년 구독",
          account: "최대 20개",
          icon: "https://assets.themiilk.com/business/icon-diamond.svg",
          showServiceMobile: false,
          options: [
            {value: "프로"},
            {value: "최대 20개", available: true},
            { value: "$7,500", isBold: true, subValue: "1,000만원 / 1년", available: true, },
            // { value: "$5,500", isBold: true, subValue: "750만원 / 1년", available: true, },
            {isCheckCircle: true, available: true},

            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "1매", isBold: true, available: true},
            {value: "50% 할인", isBold: true, available: true},

            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "50% 할인", isBold: true, available: true, isPoint: true},

            {value: "연 2회 홍보기사", isBold: true, available: true, isPoint: true,},
          ],
        },
        {
          name: "엔터프라이즈",
          subscribe: "1년 구독",
          account: "최대 50개",
          icon: "https://assets.themiilk.com/business/icon-enterprise.svg",
          showServiceMobile: false,
          options: [
            {value: "엔터프라이즈"},
            {value: "최대 50개", available: true},
            { value: "$15,000", isBold: true, subValue: "2,000만원 / 1년", available: true, },
            // { value: "$11,000", isBold: true, subValue: "1,500만원 / 1년", available: true, },
            {isCheckCircle: true, available: true},

            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "2매", isBold: true, available: true},
            {value: "무료제공", isBold: true, available: true},

            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {isCheckCircle: true, available: true},
            {value: "연 1회 홍보기사", isBold: true, available: true, isPoint: true},

            {value: "연 4회 홍보기사", isBold: true, available: true, isPoint: true},
          ],
        },
      ],
      countOptions: [1, 10, 20, 50],
      apply: {
        company_name: null,
        user_name: null,
        phone_number: null,
        email: null,
        user_count: null,
        etc: null,
      },
      subscribeContents: [
        {
          title: "CES/MWC K-Night 초대권",
          descriptions: [
            {
              prefix: "CES",
              description:
                  "는 매년 1월 미국 라스베이거스에서 열리는 소비자 가전 전시회 (Consumer Electronics Show)입니다.",
            },
            {
              prefix: "MWC",
              description:
                  "는 매년 2월 스페인 바르셀로나에서 열리는 모바일 산업 및 콘퍼런스 (Mobile World Conference) 입니다.",
            },
            {
              description:
                  "더밀크의 K-Night은 VIP 구독자 중심 네트워킹 & 인사이트 이벤트입니다. 국내 정,재계 인사들과 글로벌 기업인, 실리콘밸리 혁신가들이 참석해 통찰력있는 강의와 차별화된 네트워킹 기회를 제공합니다.",
            },
          ],
        },
        {
          title: "CES/MWC 리포트 제공",
          descriptions: [
            {
              description:
                  "컨퍼런스의 A부터 Z까지 총망라, 현장의 생생한 정보를 전달합니다. 주요 트렌드 파악은 기본, 더밀크만의 인사이트를 전달합니다. 어떠한 비즈니스 아이템들이 미국 시장에 성공적으로 진출할 수 있는 지에 대한 벨류를 제공합니다.",
            },
          ],
          boxs: [
            {
              title: "CES 풀버전 리포트 특징",
              suffix: "($4,500불 상당)",
              description:
                  "행사가 끝난 후 바로 발행 & 고객 이메일로 전달 현장 수집 트렌드 데이터(Raw Data) 현장사진 원본 (약 1000+)",
            },
            // {
            //   title: "CES 요약본 리포트 특징",
            //   suffix: "($300불 상당)",
            //   description:
            //     "컨퍼런스의 A부터 Z까지 총망라한 리포트의 요약본 풀버전 리포트 중에서 핵심 내용을 소개",
            // },
          ],
          lower_description:
              "※ 기업용 플랜의 유형에 따라 할인/제공 금액이 상이",
        },
        {
          title: "기업용 ‘실리콘밸리 정보보고' (Hot Source) 제공",
          descriptions: [
            {
              description:
                  "실리콘밸리 현장에서 일어나는 기사화되지 않은 숨어있는 알짜 정보들을 모아 전달해드립니다.",
            },
          ],
        },
        {
          title: "월간 AI 트렌드 리포트",
          descriptions: [
            {
              description:
                  "실리콘밸리와 뉴욕, 그 외의 미국, 유럽에서 매일 새롭게 벌어지는 새로운 AI 이벤트를 정리, 인사이트를 담은 리포트입니다. 매일 쏟아지는 AI 뉴스 홍수 속에서 놓치지 않고 꼭 챙겨봐야 할 정보를 선별해 월별로 리포트를 발행합니다. 핵심요약으로 구독자분들의 시간을 아껴 드립니다.",
            },
          ],
        },
      ],
      publishContents: [
        {
          title: "기업 브랜디드 콘텐츠 (애드버토리얼)",
          descriptions: [
            {
              description:
                  "더밀크 기자의 시각과 업계 정보 등을 종합적으로 분석, 새로운 콘텐트로 재탄생합니다. 단순한 기업 소개에 머물지 않고, 차별화한 정보를 제공해 기업 브랜드를 한층 업그레이드 해드립니다. 브랜디드 콘텐츠는 더밀크닷컴에 무료 기사로 게재됩니다. 유료 구독하지 않은 독자도 해당 콘텐츠를 확인할 수 있습니다.",
            },
          ],
        },
        {
          title: "뷰스레터 내 기업 광고",
          descriptions: [
            {
              description:
                  "브랜디드 콘텐츠로 제작하지 않고 배너 형식 광고로 제작합니다. 더밀크 뷰스레터 중간이나 하단 영역에 배너 광고를 할 수 있습니다. 배너 파일을 보내주시고. 해당 배너를 클릭해서 도달할 URL을 넣을 수 있습니다. 하단 예시처럼 디스플레이 광고가 가능합니다.",
            },
          ],
          boxs: [
            {
              image:
                  "https://assets.themiilk.com/business/corp-ad-in-viewsletter1.jpg",
            },
            {
              image:
                  "https://assets.themiilk.com/business/corp-ad-in-viewsletter2.jpg",
            },
            {
              image:
                  "https://assets.themiilk.com/business/corp-ad-in-viewsletter3.jpg",
            },
          ],
          lower_description:
              "※ 기업용 플랜의 유형에 따라 할인/제공 금액이 상이",
        },
      ],
      companies: [
        "https://assets.themiilk.com/business/logos/logo-hyundai-motors.png",
        "https://assets.themiilk.com/business/logos/logo-hyundai-autoever.png",
        "https://assets.themiilk.com/business/logos/logo-kt.png",
        "https://assets.themiilk.com/business/logos/logo-lg-cns.png",
        "https://assets.themiilk.com/business/logos/logo-cj.png",
        "https://assets.themiilk.com/business/logos/logo-cj-olivenetworks.png",
        "https://assets.themiilk.com/business/logos/logo-lotte-future-strategy-research-institute.png",
        "https://assets.themiilk.com/business/logos/logo-doosan.png",
        "https://assets.themiilk.com/business/logos/logo-kakao-entertainment.png",
        "https://assets.themiilk.com/business/logos/logo-woowa-bros.png",
        "https://assets.themiilk.com/business/logos/logo-ahnlab.png",
        "https://assets.themiilk.com/business/logos/logo-aton.png",
        "https://assets.themiilk.com/business/logos/logo-kdb-silicon-valley.png",
        "https://assets.themiilk.com/business/logos/logo-meta.png",
        "https://assets.themiilk.com/business/logos/logo-genians.png",
        "https://assets.themiilk.com/business/logos/logo-yk.png",
        "https://assets.themiilk.com/business/logos/logo-ministry-of-trade-industry-and-energy.png",
        "https://assets.themiilk.com/business/logos/logo-etri.png",
        "https://assets.themiilk.com/business/logos/logo-nipa.png",
        "https://assets.themiilk.com/business/logos/logo-korea-internet-and-security-agency.png",
        "https://assets.themiilk.com/business/logos/logo-institute-of-information-and-communications-technology-planing-and-evaluation.png",
        "https://assets.themiilk.com/business/logos/logo-sba.png",
      ],
    };
  },
  mounted() {},
  computed: {
    displayCountText() {
      if (this.apply.user_count == null) {
        return "구독 예상 계정 수를 선택해주세요.";
      } else {
        return this.countOptions[this.apply.user_count];
      }
    },
    getTime() {
      return new Date().getTime();
    },
    reversedModels() {
      return this.models.slice().reverse();
    },
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.$v.$reset();
      this.$nextTick(() => {
        this.$v.$touch();
        if (this.$v.$error) {
          return;
        }

        axios
            .post("/api/business", this.apply)
            .then((res) => {})
            .finally(() => {
              this.isLoading = false;
              document.querySelector("body").style.overflow = "auto";
              this.showPopup = false;
            });
      });
    },
    openPopup(model) {
      if (typeof model === "number") {
        this.currentModel = model;
      }

      document.querySelector("body").style.overflow = "hidden";
      this.showPopup = true;
    },
    closePopup() {
      document.querySelector("body").style.overflow = "auto";
      this.showPopup = false;
    },
    changeModel(e) {
      this.currentModel = e.target.value;
    },
    changeCurrency() {
      this.isCurrencyUSD = !this.isCurrencyUSD;
    },
    showServiceMobileItems(idx) {},
    logT(i) {
      console.log(i);
    },
  },
  validations: {
    apply: {
      company_name: {required},
      user_name: {required},
      phone_number: {required},
      email: {required},
    },
  },
};
</script>
