<template>
<div class="carousel-container">
    <carousel-3d :controls-visible="true" :controls-prev-html="''" :controls-next-html="''" @before-slide-change="onBeforeSlideChange"
               :controls-width="50" :controls-height="50" :clickable="true" :border="0" :width="640" :height="360" :space="350">
      <slide v-for="(video, i) in videos" :index="i" v-bind:key="video.id" v-bind:value="i" >
        <a :href="buildVideoUrl(video.id)" class="linkage">
          <img :src="video.thumbnail_url" :class="{active: current == i}">
        </a>

        <button class="button button-play">
          <div class="icon-play-arrow"></div>
        </button>
      </slide>
    </carousel-3d>

    <ul class="carousel-indicator" v-bind:data-current="{isMobile}">
      <li v-for="(video, i) in videos" class="item" :class="{active: current == i}" v-bind:key="i">
        <div class="point"></div>
      </li>
    </ul>
</div>    
</template>

<script>
  import { Carousel3d, Slide } from 'vue-carousel-3d';

  export default {
    props: {
        event: Object,
        videos: Array
    },
    data() {
      return {
        isMobile: false,
        current: 0,
      }
    },
    methods: {
      onBeforeSlideChange(index) {
        this.current = index;
      },
      buildVideoUrl(id) {
        return "/videos/" + id;
      },
    },
    components: {
      Carousel3d,
      Slide
    },
  }
</script>