var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-container" }, [
    _c("div", { staticClass: "dialog-background" }),
    _vm._v(" "),
    _vm.content.chage < 5.0 || _vm.content.change > -5.0
      ? _c("div", { staticClass: "dialog-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "button button-circle close-btn",
              on: {
                click: function($event) {
                  return _vm.closePop(false)
                }
              }
            },
            [
              _c("img", {
                staticClass: "icon-close",
                attrs: {
                  src:
                    "https://assets.themiilk.com/common/icon-close-white.svg",
                  alt: "Close"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-infor",
              style: _vm.styleBackground(_vm.color)
            },
            [
              _c("div", { staticClass: "dialog-content" }, [
                _vm.content.sector
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "category label-description no-spacing no-uppercase",
                        class: {
                          light:
                            _vm.content.change > 5 || _vm.content.change < -5
                        }
                      },
                      [_vm._v(_vm._s(_vm.content.sector))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ticker label-title bold",
                      class: {
                        light: _vm.content.change > 5 || _vm.content.change < -5
                      }
                    },
                    [_vm._v(_vm._s(_vm.content.ticker))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "range" }, [
                    _c(
                      "div",
                      {
                        staticClass: "name label-small-description bold",
                        class: {
                          light:
                            _vm.content.change > 5 || _vm.content.change < -5
                        }
                      },
                      [_vm._v("보유비중")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "percent label-middle-title",
                        class: {
                          light:
                            _vm.content.change > 5 || _vm.content.change < -5
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            parseFloat(_vm.content.ratio * 100).toFixed(2)
                          ) + "%"
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "name label-middle-description",
                      class: {
                        light: _vm.content.change > 5 || _vm.content.change < -5
                      }
                    },
                    [_vm._v(_vm._s(_vm.content.english_name))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "line" })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dialog-footer" }, [
                _vm.content.change != 0 && _vm.content.change != null
                  ? _c("div", { staticClass: "result" }, [
                      _vm._v("\n              보유 주식수가 전분기 대비 "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            parseFloat(_vm.content.change * 100).toFixed(2)
                          ) + "%"
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.content.change > 0 ? "증가" : "감소") +
                          " 했습니다.\n          "
                      )
                    ])
                  : _vm.content.change == null
                  ? _c("div", { staticClass: "result" }, [
                      _vm._v(
                        "\n            신규 편입된 종목 입니다.\n          "
                      )
                    ])
                  : _c("div", { staticClass: "result" }, [
                      _vm._v(
                        "\n            보유 주식수가 전분기 대비 변화가 없습니다.\n          "
                      )
                    ]),
                _vm._v(" "),
                _vm.content.tag
                  ? _c("div", { staticClass: "buttons-wrapper" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button button-arrow",
                          attrs: { href: "/tags/" + _vm.content.tag }
                        },
                        [
                          _c("span", { staticClass: "company-name" }, [
                            _vm._v(
                              _vm._s(_vm.content.name.replace("ADR", "")) +
                                " 관련 기사 보러가기"
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(0)
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }