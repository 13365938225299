<template>
  <div class="content-detail membership blank">
    <h2 class="label-middle-title">멤버십 / 구독 및 결제 관리</h2>
    <p class="label-small-description description" style="marginBottom: 20px">실리콘밸리의 생생한 인사이트를 전하는 앞서가는 사람들의 테크 & 경제 미디어, 더밀크 멤버십을 이용해보세요.</p>
    <div class="membership-container expiration-date">
      <div class="info-wrapper">
        <h3 class="label-middle-description bold point">구독 플랜</h3>
        <p class="label-small-description description">무료 구독 플랜</p>
      </div>

      <div class="button-wrapper">
        <a class="button button-bordered no-deco" href="/orders/plans">
          플랜 변경하기
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: {
    },
    data() {
      return {};
    },
    computed: {
    },
    methods: {
    }
  }
</script>