var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "team-wrapper" },
      [
        _c("h2", { staticClass: "label-sub-title heavy c-white" }, [
          _vm._v("\n      THE MIILK TEAM\n    ")
        ]),
        _vm._v(" "),
        [
          _vm.isMobile
            ? _c(
                "ul",
                { staticClass: "list" },
                _vm._l(_vm.members, function(member, idx) {
                  return _c("member", {
                    key: idx,
                    attrs: { member: member },
                    on: { infoClicked: _vm.setActive }
                  })
                }),
                1
              )
            : [
                _c(
                  "ul",
                  { staticClass: "list-half" },
                  _vm._l(_vm.evenMembers, function(member, idx) {
                    return _c("member", {
                      key: idx,
                      attrs: { member: member },
                      on: { infoClicked: _vm.setActive }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-half" },
                  _vm._l(_vm.oddMembers, function(member, idx) {
                    return _c("member", {
                      key: idx,
                      attrs: { member: member },
                      on: { infoClicked: _vm.setActive }
                    })
                  }),
                  1
                )
              ]
        ]
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "team-wrapper" },
      [
        _c("h2", { staticClass: "label-sub-title heavy c-white" }, [
          _vm._v("\n      Advisory\n    ")
        ]),
        _vm._v(" "),
        [
          _vm.isMobile
            ? _c(
                "ul",
                { staticClass: "list" },
                _vm._l(_vm.advisors, function(member, idx) {
                  return _c("member", {
                    key: idx,
                    attrs: { member: member },
                    on: { infoClicked: _vm.setActive }
                  })
                }),
                1
              )
            : [
                _c(
                  "ul",
                  { staticClass: "list-half" },
                  _vm._l(_vm.evenAdvisors, function(member, idx) {
                    return _c("member", {
                      key: idx,
                      attrs: { member: member },
                      on: { infoClicked: _vm.setActive }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-half" },
                  _vm._l(_vm.oddAdvisors, function(member, idx) {
                    return _c("member", {
                      key: idx,
                      attrs: { member: member },
                      on: { infoClicked: _vm.setActive }
                    })
                  }),
                  1
                )
              ]
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }