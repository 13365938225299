<template>
  <div class="content-detail account">
    <h2 class="label-middle-title">비밀번호 변경</h2>

    <div class="input-wrapper">
      <text-input v-model="user.current_password" :v="$v.user.current_password" label="현재 비밀번호" placeholder="비밀번호를 입력하세요." type="password" :required=true @input="checkValid"></text-input>

      <text-input v-model="user.password" :v="$v.user.password" label="새 비밀번호" placeholder="새 비밀번호를 입력하세요." type="password" :required=true description="영문, 숫자 조합 8자리 이상"></text-input>
      <text-input v-model="user.password_confirm" :v="$v.user.password_confirm" label="새 비밀번호 확인" placeholder="새 비밀번호를 입력하세요." type="password" :required=true></text-input>

      <miilk-button :v="$v" name="비밀번호 변경하기" class="primary" @clicked="modify" :isDisableStart=true />
    </div>
  </div>
</template>

<script>
  import { required, minLength } from 'vuelidate/lib/validators'
  import { confirmPassword, misMatchedPwd } from '../../commons/validators';

  export default{
    props: {
      initUser: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        user: this.initializeUser(this.initUser),
      };
    },
    computed: {
    },
    methods: {
      initializeUser: function(initUser) {
        const user = _.cloneDeep(initUser);
        user.current_password = null;
        user.password = null;
        user.password_confirm = null;
        user.misMatchedPwd = false;
        return user;
      },
      modify: function() {
        const data = {
          password: this.user.current_password,
          new_password: this.user.password,
          new_password_confirm: this.user.password_confirm,
        };

        const formData = common.objectToFormData(data);
        const curDom = this;
        axios.put(`/users/${this.user.id}/password`, formData).then(response => {
          curDom.user.current_password = null;
          curDom.user.password = null;
          curDom.user.password_confirm = null;

          curDom.$v.$reset();
          curDom.$notification.open({
            message: '계정 정보 변경 완료.',
            description: '계정 정보가 성공적으로 업데이트 되었습니다.',
            top: '88px',
          });
        }).catch(error => {
          console.log(error.response.data);
          if (error.response.data.errorCode == 10006) {
            curDom.user.misMatchedPwd = true;
            curDom.$v.$touch();
          }
        });
      },
      checkValid: function() {
        this.user.misMatchedPwd = false;
        this.$v.$touch();
      },
    },
    validations: {
      user: {
        current_password: {required, misMatchedPwd},
        password: {required, minLength: minLength(8)},
        password_confirm: {required, confirmPassword},
      }
    }
  }
</script>