<template>
    <div class="option-button related-ticker">
      <div v-if="tickers.length > 1" class="option-button-body">
        <span class="label active">관련종목<span class="mobile-hide"> 보기</span></span>
        <div class="mark">
          <img src='https://assets.themiilk.com/common/option-icon-related-ticker.svg' class='icon default' />
          <img src='https://assets.themiilk.com/common/option-icon-related-ticker-active.svg' class='icon active' />
        </div>
      </div>
      <div v-else class="option-button-body" v-on:click="openPop(tickers[0])">
        <span class="label active fold-hide force">종목차트<span class="mobile-hide"> 보기</span></span>
        <div class="mark">
          <img src='https://assets.themiilk.com/common/option-icon-related-ticker.svg' class='icon default' />
          <img src='https://assets.themiilk.com/common/option-icon-related-ticker-active.svg' class='icon active' />
        </div>
      </div>      
      <ul v-if="tickers.length > 1" class="option-button-list">
        <li v-for="ticker in tickers" class="item" :key="ticker.symbol">
          <button class="ticker-item" v-on:click="openPop(ticker)">
            <div class="icon-wrapper">
              <img :src="`https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/investors/logos/${ticker.symbol}.jpg`" :alt="ticker.symbol" />
            </div>
            <div class="info-wrapper">
              <div class="ticker">{{ticker.symbol}}</div>
              <div class="percent" v-bind:class="updown(ticker.historical[0].change)">{{ticker.historical[0].changePercent.toFixed(2)}}%</div>
            </div>
          </button>
        </li>
      </ul>
      <PopupTickers v-if="showConfirm" :content="popContent" :recommended_at="published_at"  @close-layer-popup="closePop" />
    </div>
</template>
<script>
  import PopupTickers from "../commons/components/popup_tickers";
  export default {
    props: {
      tickers: {
        type: Array,
        default: []
      },
      published_at : {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        showConfirm: false,
        popContent: [],
        nav: $('.nav')
      }
    },
    components: {
      PopupTickers
    },     
    methods: {
      openPop: function(ticker){
        this.nav.addClass('block');
        this.popContent = ticker;
        this.showConfirm = true;
        document.body.style.overflow = 'hidden';
      },
      closePop: function() {
        this.nav.removeClass('block');
        this.popContent = [];
        this.showConfirm = false;
        document.body.style.overflow = 'auto'

      },      
      onChange: function() {
        let question = this.questions.find(item=>item.idx==this.selectedQuestion);
        this.questionText = question.text;
      },
      updown: function (price){
        if(price>0) return 'up';
        else if (price < 0) return 'down';
        else return ''

      },      
      onClick: function() {
          location.href = "/investing/stock101?idx=" + this.selectedQuestion;
      }
    }
  }
</script>