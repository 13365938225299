<template>
  <div class="pop-wrapper email">
    <div class="dimBg"></div>
    <div id="layer" class="pop-layer">
      <div class="pop-container">
        <div class="pop-conts">

          <div class="pop-head clearfix">
            <div class="title"></div>
            <div class="close-btn btn" @click="closeModal">
              <i class="material-icons">close</i>
            </div>
          </div>

          <div class="pop-content">
            <p>
              원활한 서비스 이용을 위해 다른 브라우저(크롬, 사파리 등)을 이용해주세요.
              <br />
              <br />
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      email: null,
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
}
</script>
