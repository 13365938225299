<template>
  <li class="reporters-item" v-on:click="infoClicked">
    <div class="image-wrapper remove-line-height">
      <img :src="reporter.thumbnail" />

      <div class="cover">
        <img
          class="icon"
          src="https://assets.themiilk.com/about/icon-show-detail.svg"
        />
      </div>
    </div>

    <div class="text-wrapper">
      <p class="name label-middle-description medium ellipsis one">
        {{ reporter.name }}
      </p>
      <p class="category label-xxsmall">{{ reporter.category }}</p>
      <p class="description label-xsmall ellipsis two">
        {{ reporter.description }}
      </p>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    reporter: {
      type: Object,
      default: {},
    },
  },
  methods: {
    infoClicked: function() {
      this.$emit("infoClicked", this.reporter);
    },
  },
};
</script>
