<template>
  <section class="my-container single padding tiny">
    <div class="content">
      <div class="content-head">
        <h1 class="label-title head-title">카드 변경하기</h1>
        <h2 class="label-small-title head-sub-title">
          <span>카드 정보 입력</span>
        </h2>
      </div>

      <div class="content-detail">
        <div class="card-info-container">
          <div class="card-info-wrapper center">
            <card-input v-model="paymentInfo.cardInfo" :v="$v.paymentInfo.cardInfo" label="카드번호" placeholder="카드번호를 입력하세요." :required="true" :clientSecret="clientSecret" @card-change="cardNumberChange" @card-error="cardError" @card-valid="setCardValid"/>
            <text-input v-model="paymentInfo.name" :v="$v.paymentInfo.name" label="이름" placeholder="카드에 적힌 이름을 입력하세요." :required=true @input="checkValid" />

            <miilk-button
                :v="$v"
                name="카드변경"
                class="primary"
                :isDisableStart=true
                @clicked="subscribe"
                :isLoading="isLoading"
                loading-text="카드 등록중..."
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {cardValidation, cvcValid, requiredName} from '../commons/validators';
  import PlanCard from "./plan_card";

  export default {
    props: [
      'initClientSecret'
    ],
    data() {
      return {
        paymentInfo: {
          cardInfo: null,
          cardInfoError: null,
          isValidCard: true,
          isValidCvc: true,
          name: null,
        },
        clientSecret: this.initClientSecret,
        isLoading: false,
      }
    },
    computed: {
    },
    methods: {
      checkValid: function() {
        this.$v.$reset();
        this.$v.$touch();
      },
      subscribe: function() {
        if (this.isLoading) {
          return;
        }

        var stripe = MiilkStripeElement.stripe;
        var card = MiilkStripeElement.card;
        const curDom = this;

        this.isLoading = true;
        stripe.confirmCardSetup(
            this.clientSecret,
            {
              payment_method: {
                card: card,
                billing_details: {
                  name: this.paymentInfo.name,
                },
              },
            }
        ).then(function(result) {
          if (result.error) {
            // TODO :: card element를 refresh 해야함.
            // var displayError = document.getElementById('card-errors');
            // displayError.textContent = result.error.message;
            console.log(result.error);
            if (result.error.code == "incorrect_number") {
              curDom.paymentInfo.isValidCard = false;
            } else if (result.error.code == "card_declined") {
              curDom.paymentInfo.isValidCard = false;
            } else {
              curDom.paymentInfo.isValidCard = false;
            }

          } else {
            curDom.payment(result.setupIntent.payment_method);
          }
        }).finally(() => {
          this.isLoading = false;
        });
      },
      payment: function (paymentId) {
        const data = {
          payment_id: paymentId,
        };

        const formData = common.objectToFormData(data);
        axios.post("/users/change_card", formData).then(res => {
          location.href = "/users/me"
        }).catch(this.paymentError);
      },
      paymentError(error) {
        this.updateClientSecret();
      },
      cardNumberChange: function(event) {
        if (event != null && event.error != null) {
          this.paymentInfo.isValidCard = false;
        }
      },
      cardError: function(event) {
        this.paymentInfo.isValidCard = false;
        this.updateClientSecret();
        // this.$v.$reset();
        this.$v.$touch();
      },
      setCardValid: function(event) {
        this.paymentInfo.isValidCard = true;
      },
      updateClientSecret: function() {
        axios.get("/users/client_secret").then(res => {
          this.clientSecret = res.client_secret;
        });
      },
    },
    validations: {
      paymentInfo: {
        cardInfo: {cardValidation, cvcValid},
        name: {requiredName},
      }
    },
    components: {
      PlanCard
    }
  }
</script>