var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "my-container corporate" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-head split no-margin" }, [
        _c("h1", { staticClass: "label-sub-title section-title" }, [
          _c("span", { staticClass: "c-primary" }, [
            _vm._v(_vm._s(_vm.corporate.company_name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "marking" }, [_vm._v("관리 페이지")])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content set-sidebar" }, [
      _c("div", { staticClass: "content-sidebar sticky" }, [
        _c("div", { staticClass: "sticky-wrapper" }, [
          _c("ul", { staticClass: "list-menu linear" }, [
            _c(
              "li",
              {
                staticClass: "menu linkage",
                class: { active: _vm.menus == "members" },
                on: {
                  click: function($event) {
                    return _vm.changeMenu("members")
                  }
                }
              },
              [_vm._v("\n            사용자 명단\n          ")]
            ),
            _vm._v(" "),
            _c("li", { staticClass: "bar" }),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "menu linkage",
                class: { active: _vm.menus == "subscribe" },
                on: {
                  click: function($event) {
                    return _vm.changeMenu("subscribe")
                  }
                }
              },
              [_vm._v("\n            구독 관리\n          ")]
            ),
            _vm._v(" "),
            _c("li", { staticClass: "bar" })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-wrapper" },
        [
          _vm.menus == "members"
            ? _c("members", {
                attrs: { corporate: _vm.corporate, initMembers: _vm.members }
              })
            : _vm.menus == "subscribe"
            ? _c("subscribe", {
                attrs: { initUser: _vm.initUser, corporate: _vm.corporate }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }