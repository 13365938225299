var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "social-btn",
        attrs: { id: "g-signin2" },
        on: {
          click: function($event) {
            return _vm.snsClick("google")
          }
        }
      },
      [
        _c("div", { staticClass: "wrapper clearfix" }, [
          _c("div", { staticClass: "icon google" }),
          _vm._v(" "),
          _c("div", { staticClass: "txt" }, [
            _vm._v("구글로 " + _vm._s(_vm.actionName))
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "social-btn",
        on: {
          click: function($event) {
            return _vm.snsClick("kakao")
          }
        }
      },
      [
        _c("div", { staticClass: "wrapper clearfix" }, [
          _c("div", { staticClass: "icon kakao" }),
          _vm._v(" "),
          _c("div", { staticClass: "txt" }, [
            _vm._v("카카오로 " + _vm._s(_vm.actionName))
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }