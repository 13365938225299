<template>
  <li class="item flip-box" :class="{active: member.opened}">
    <div class="button button-flip" v-on:click="infoClicked">
      <div class="image-wrapper remove-line-height profile-photo">
        <img :src="member.imageUrl" />
      </div>

      <p class="name">
        {{member.name}}
        <span class="label-small position">{{member.position}}</span>
      </p>

      <div class="icon-arrow" :class="{active: member.opened}">
        <div class="icon"></div>
      </div>
    </div>

    <div class="flip-box-content">
      <div class="text-wrapper label-middle-description">
        {{member.info}}
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      member: {
        type: Object,
        default: {}
      },
    },
    methods: {
      infoClicked: function() {
        this.$emit("infoClicked", this.member);
      }
    }
  }
</script>