var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "faqs-container padding tiny" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list-flip" },
        _vm._l(_vm.faqs, function(faq) {
          return _c("faq-item", { key: faq.id, attrs: { faq: faq } })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-head" }, [
      _c("h1", { staticClass: "label-sub-title" }, [_vm._v("자주 묻는 질문")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }