<template>
  <div class="list-wrapper">
    <ul class="list videos" v-if="videos.length > 0">
      <li class="item" v-for="video in videos">
        <article class="item-wrapper investing-item">
          <div class="image-wrapper remove-line-height">
            <a :href="videoLink(video)" @click="clickVideo(video)">
              <div class="image-box" :style="heroImageUrl(video)">
                <img class='blank-image' src="https://assets.themiilk.com/common/blank.png" alt='' />
              </div>
            </a>
          </div>

          <div class="text-wrapper">
            <a :href="videoLink(video)" @click="clickVideo(video)">
              <h2 class="title label-middle-title bold ellipsis one">
                {{ video.title }}
              </h2>
            </a>

            <a class="no-deco" :href="videoLink(video)" v-if="video.description" @click="clickVideo(video)">
              <p class="description label-small-description ellipsis three">
                {{ video.description }}
              </p>
            </a>

            <p class="statistics label-xsmall">
              <span class="bold">{{ video.str_published_at }}</span>
              <span class="count">
                조회수 {{ video.view_count }}
              </span>
            </p>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ['videos'],
    data() {
      return {}
    },
    mounted() {},
    methods: {
      heroImageUrl: function(video) {
        return `background-size: cover; background-image: url('${video.thumbnail_url}')`;
      },
      videoLink: function(video) {
        return `/videos/${video.id}`;
      },
      clickVideo: function(video) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_video',
          'video_id': video.id,
          'video_title': video.title,
          'video_author': video.author,
          'video_published_at': video.published_at
        })
      },
    }
  }
</script>
