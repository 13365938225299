<template>
  <section class="login-container padding tiny">
    <div class="content">
      <div class="content-head">
        <h1 class="label-title">로그인</h1>
      </div>

      <div class="content-detail">
        <div class="login-form social">
          <social-button :is-signup=false @snsClicked="snsClick" />
        </div>

        <div class="splitter">
          <hr class="line" />
          <span class="label label-small">또는 이메일로 로그인</span>
          <hr class="line" />
        </div>

        <div class="login-form">
          <text-input v-model.trim="user.loginUserId" :v="$v.user.loginUserId" label="이메일" placeholder="이메일을 입력하세요." :required=true></text-input>
          <text-input v-model.trim="user.password" :v="$v.user.password" label="비밀번호" placeholder="비밀번호를 입력하세요." type="password" :required=true @enter="validationWithLogin"></text-input>
          <checkbox-input v-model="user.isKeep" label="로그인상태유지" value="true" pk="isKeep" :checked=true></checkbox-input>

          <div class="message error from-bottom" v-if="user.misMatchedPwd">
            이메일 또는 비밀번호를 확인해주세요.
          </div>
          <miilk-button :v="$v" name="이메일로 로그인" class="primary" :isDisableStart=true @clicked="login" />

          <div class="message">
            <a href="/users/reset_password" class="button-link">비밀번호를 잊으셨나요?</a>
          </div>
        </div>

        <div class="message top-split">
          <p>더밀크가 처음이신가요? <a class="button-link" :href="`/signup?url=${getReturnUrl()}`">회원가입</a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { emailValid, requiredEmail, requiredPassword } from '../commons/validators';

  import Vue from 'vue'
  import Antd from 'ant-design-vue';
  import 'ant-design-vue/dist/antd.css';
  Vue.use(Antd);

  export default {
    props: [
      'changed_password'
    ],
    data() {
      return {
        user: this.prepareData()
      }
    },
    mounted() {
      if (this.changed_password == "true") {
        this.$notification.open({
          message: '비밀번호 변경 완료.',
          description: '비밀번호 변경 완료'
        });
      }

      const kakaoKey = "e5d202fca4e87a85631146db398a689a";
      $.getScript("//developers.kakao.com/sdk/js/kakao.min.js", function() {
        Kakao.init(kakaoKey);
      });

    },
    computed: {
      termsLabel: function() {
        return "(필수) <a href='/terms'>이용약관</a> 및 <a href='/privacy'>개인정보처리방침</a>에 동의합니다.";
      },
    },

    methods: {
      prepareData() {
        const user = {
          loginUserId: '',
          password: null,
          isKeep: "true",
          misMatchedPwd: false,
        };

        return user;
      },
      validationWithLogin() {
        this.$v.$reset();
        this.$nextTick(() => {
          this.$v.$touch();
          if (this.$v.$error) {
            return;
          }

          this.login();
        });

      },
      snsClick: function(type) {
        const curDom = this;
        if (type == "kakao") {
          Kakao.Auth.authorize({redirectUri: `${window.redirectHost}/users/kakao/oauth`, state: `${this.getReturnUrl()}`});
        } else {
          const googleClientId = '246544490991-crfiaqauri720n18gj1l6om2s33sppo5.apps.googleusercontent.com';
          const redirectUrl = `${window.redirectHost}/users/google/oauth`;
          location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile%20email&response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUrl}&state=${this.getReturnUrl()}`;
        }
      },
      login() {
        const curDom = this;
        MiilkAuth.setIdPassword(this.user.loginUserId, this.user.password);
        MiilkAuth.login( function(isSuccess, isSignuped, message, errorCode) {
          if (isSuccess) {
            var returnUrl = curDom.getReturnUrl();
            if (isSignuped) {
              location.href = `/orders/signup_plans?url=${returnUrl}`;
              return;
            }

            location.href = returnUrl;
          } else {
            if (errorCode == 10006) {
              curDom.user.misMatchedPwd = true;
              curDom.$v.$touch();
            }
            console.log(message);
          }
        });
      },
      getReturnUrl: function() {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("url")) {
          return urlParams.get("url");
        }

        return "/";
      }
    },
    validations: {
      user: {
        loginUserId: {requiredEmail, emailValid},
        password: {requiredPassword},
      }
    }
  }
</script>
