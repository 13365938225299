var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-info-container add-new-card" }, [
    _c(
      "div",
      { staticClass: "card-info-wrapper" },
      [
        _c("h2", { staticClass: "label-small-title" }, [
          _vm._v("카드 정보 등록")
        ]),
        _vm._v(" "),
        _c("card-input", {
          attrs: {
            v: _vm.$v.paymentInfo.cardInfo,
            label: "카드번호",
            placeholder: "카드번호를 입력하세요.",
            required: true,
            clientSecret: _vm.clientSecret
          },
          on: {
            "card-change": _vm.cardNumberChange,
            "card-error": _vm.cardError,
            "card-valid": _vm.setCardValid
          },
          model: {
            value: _vm.paymentInfo.cardInfo,
            callback: function($$v) {
              _vm.$set(_vm.paymentInfo, "cardInfo", $$v)
            },
            expression: "paymentInfo.cardInfo"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            v: _vm.$v.paymentInfo.name,
            label: "이름",
            placeholder: "카드에 적힌 이름을 입력하세요.",
            required: true
          },
          on: { input: _vm.checkValid },
          model: {
            value: _vm.paymentInfo.name,
            callback: function($$v) {
              _vm.$set(_vm.paymentInfo, "name", $$v)
            },
            expression: "paymentInfo.name"
          }
        }),
        _vm._v(" "),
        _c("miilk-button", {
          staticClass: "primary",
          attrs: { v: _vm.$v, name: "카드 정보 등록", isDisableStart: true },
          on: { clicked: _vm.subscribe }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }