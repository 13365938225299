<template>
  <div class="content-detail">
    <div class="table-container contact detail">
      <div class="table-head">
        <h2 class="title label-middle-title">
          1:1 문의하기
          <span class="description label-xxsmall fold-hide"
          >최대한 빠른 시간 내에 답변 해드리겠습니다.</span
          >
        </h2>
      </div>

      <div class="row write">
        <div class="form-item" ref="category">
          <strong>카테고리 <span class="required">*</span></strong>
          <div class="selectbox">
            <span class="selected ellipsis one">
              {{ categoryText }}
            </span>
            <select @change="changedCategory($event)">
              <option value="1">결제/환불</option>
              <option value="2">계정관련</option>
              <option value="3">쿠폰</option>
              <option value="4">기사 및 리포트 관련</option>
              <option value="0">기타문의</option>
            </select>
          </div>
        </div>
        <div class="form-item" ref="titleElement">
          <strong>제목 <span class="required">*</span></strong>
          <input
              class="input-form"
              type="text"
              placeholder="제목을 적어주세요."
              v-model="inquiry.title"
          />
        </div>
        <div class="form-item" ref="contentElement">
          <strong>내용 <span class="required">*</span></strong>
          <textarea
              class="input-form textarea"
              type="text"
              placeholder="내용을 적어주세요."
              v-model="inquiry.content"
          ></textarea>
        </div>
        <div class="form-item">
          <strong>첨부파일</strong>
          <div class="input-form file">
            <button class="button button-select-file button-box round" @click="selectFile">
              파일 선택
            </button>
            <input ref="fileBtn" accept="image/*" type="file" @change="handleFileChange" style="display:none"/>
          </div>
          <div class="input-form file in-file" v-if="inquiry.attachments.length > 0">
            <div class="thumbnail" v-for="(item, idx) in inquiry.attachments">
              <img :src="item.url" alt="" />
              <button class="button-remove-image" @click="removeFile(idx)">
                <img
                    src="https://assets.themiilk.com/mypage/icon-remove.svg"
                    alt=""
                />
              </button>
            </div>
            <button class="button-add-image" @click="selectFile">
              <img
                  class="icon"
                  src="https://assets.themiilk.com/mypage/icon-add-image.svg"
                  alt=""
              />
            </button>
          </div>
          <p class="explain">
            첨부파일은 JPG, PNG 등 3MB(3072KB) 이하의 이미지를 등록 할 수
            있습니다.
          </p>
        </div>
      </div>

      <div class="buttons-wrapper">
        <button
            class="button button-confirm button-box round bright-blue small"
            @click="createInquiry"
        >
          글쓰기
        </button>
        <button class="button button-cancel button-box round bright-blue small" @click="cancelInquiry">
          취소
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {defaultV} from '../../commons/validators';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    v: defaultV,
  },
  data() {
    return {
      categoryText: '문의 카테고리를 선택하세요.',
      inquiry: {
        prefix: this.getRandomText(),
        title: null,
        content: null,
        attachments: [],
        category: null,
      },
      isLoading: false,
    };
  },
  computed: {},
  components: {},
  methods: {
    changedCategory(event) {
      this.categoryText = event.target.selectedOptions[0].outerText;
      this.inquiry.category = event.target.selectedOptions[0].value;
    },
    createInquiry() {
      if (this.isLoading) {
        return;
      }

      if (this.inquiry.category == null) {
        this.scrollTo('category');
        return;
      }

      if (this.inquiry.title == null || this.inquiry.title.length == 0) {
        this.scrollTo('titleElement');
        return;
      }

      if (this.inquiry.content == null || this.inquiry.content.length == 0) {
        this.scrollTo('contentElement');
        return;
      }

      const self = this;
      this.isLoading = true;
      axios.post(`/api/inquiries`, this.inquiry).then(res => {
        location.href = `/users/me/contact-detail/${res.data.id}`;
      }).finally( () => {
        self.isLoading = false;
      });
    },
    cancelInquiry() {
      history.back();
    },
    removeFile(idx) {
      this.inquiry.attachments.splice(idx, 1);
    },
    selectFile() {
      this.$refs.fileBtn.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file != null) {
        const self = this;
        var frm = new FormData();
        frm.append("upload", file);
        axios.post(`/api/images/inquiries/${self.inquiry.prefix}`, frm, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          const path = res.data.newPath.path;
          const filename = res.data.newPath.filename;

          const data = {
            name: file.name,
            path: path,
            filename: filename,
            url: `https://assets.themiilk.com/${path}/origin/${filename}`
          };
          self.inquiry.attachments.push(data);
        }).catch((error) => {
        });
      }
    },
    getRandomText() {
      const r = (Math.random() + 1).toString(36).substring(7);
      return r;
    },
    scrollTo(refName) {
      const nav = document.getElementById("serviceMenu");
      let navHeight = nav.offsetHeight;

      const element = this.$refs[refName];
      const top = element.offsetTop - navHeight;

      window.scrollTo(0, top);
    },
  },
  validations: {
    inquiry: {
      title: { required },
      content: { required }
    }
  }
};
</script>
