var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-container from-lower" }, [
    _c("div", { staticClass: "dialog-background" }),
    _vm._v(" "),
    _c("div", { staticClass: "dialog-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "button button-circle close-btn",
          on: { click: _vm.closePop }
        },
        [
          _c("img", {
            staticClass: "icon-close",
            attrs: {
              src: "https://assets.themiilk.com/common/icon-close-white.svg",
              alt: "Close"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dialog-ticker" }, [
        _c("div", { staticClass: "dialog-content" }, [
          _c("div", { staticClass: "dialog-head" }, [
            _c("div", { staticClass: "info-wrapper" }, [
              _c(
                "div",
                { staticClass: "section-title label-middle-title bold" },
                [_vm._v(_vm._s(this.content.symbol))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "section-description label-small-description" },
                [_vm._v(_vm._s(this.content.name))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "price-wrapper" }, [
              _c(
                "div",
                { staticClass: "current-price label-middle-title bold" },
                [
                  _c("sup", { staticClass: "thin" }, [_vm._v("$")]),
                  _vm._v(_vm._s(this.closeData.adjClose) + "\n            ")
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "market-wrapper" }, [
                _c("div", { staticClass: "market" }, [
                  _c(
                    "div",
                    {
                      staticClass: "move label-small-description medium",
                      class: _vm.updown(this.closeData.change)
                    },
                    [
                      _vm._v(
                        _vm._s(
                          new Intl.NumberFormat("en-US").format(
                            this.closeData.change
                          )
                        ) +
                          " (" +
                          _vm._s(this.closeData.changePercent.toFixed(2)) +
                          "%)"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "status label-xsmall" }, [
                    _vm._v(
                      _vm._s(
                        this.closeData.date
                          .split("-")
                          .slice(-2)
                          .join(".")
                      ) + " 종가"
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-chart" },
            [
              _vm.isLoading
                ? _c("div", { staticClass: "chart-loading" }, [_vm._m(0)])
                : _vm._e(),
              _vm._v(" "),
              _c("GChart", {
                ref: "gChart",
                attrs: {
                  type: "LineChart",
                  data: _vm.chartData,
                  settings: { packages: ["corechart"] },
                  options: _vm.chartOptions,
                  events: _vm.chartEvents
                },
                on: { ready: _vm.onChartReady }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-loading-wrapper" }, [
      _c("div", { staticClass: "icon remove-line-height" }, [
        _c("img", {
          attrs: {
            src: "https://assets.themiilk.com/common/icon-loading.svg",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "msg" }, [_vm._v("Chart Loading ...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }