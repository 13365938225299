var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-wrapper" }, [
    _vm.videos.length > 0
      ? _c(
          "ul",
          { staticClass: "list videos" },
          _vm._l(_vm.videos, function(video) {
            return _c("li", { staticClass: "item" }, [
              _c("article", { staticClass: "item-wrapper investing-item" }, [
                _c("div", { staticClass: "image-wrapper remove-line-height" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.videoLink(video) },
                      on: {
                        click: function($event) {
                          return _vm.clickVideo(video)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-box",
                          style: _vm.heroImageUrl(video)
                        },
                        [
                          _c("img", {
                            staticClass: "blank-image",
                            attrs: {
                              src:
                                "https://assets.themiilk.com/common/blank.png",
                              alt: ""
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-wrapper" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.videoLink(video) },
                      on: {
                        click: function($event) {
                          return _vm.clickVideo(video)
                        }
                      }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "title label-middle-title bold ellipsis one"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(video.title) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  video.description
                    ? _c(
                        "a",
                        {
                          staticClass: "no-deco",
                          attrs: { href: _vm.videoLink(video) },
                          on: {
                            click: function($event) {
                              return _vm.clickVideo(video)
                            }
                          }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "description label-small-description ellipsis three"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(video.description) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "statistics label-xsmall" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(video.str_published_at))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "count" }, [
                      _vm._v(
                        "\n              조회수 " +
                          _vm._s(video.view_count) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }