<template>
  <div class="reset-password-container">
    <div class="content">
      <h2 class="label-middle-title">비밀번호 변경</h2>
      <p class="sub-title">새로운 비밀번호를 입력하세요.</p>

      <text-input v-model="user.password" :v="$v.user.password" label="비밀번호" placeholder="비밀번호를 입력하세요." type="password" :required=true description="영문,숫자 조합 8자리 이상"></text-input>
      <text-input v-model="user.password_confirm" :v="$v.user.password_confirm" label="비밀번호 확인" placeholder="비밀번호를 입력하세요." type="password" :required=true @enter="reset"></text-input>

      <miilk-button :v="$v" name="비밀번호 변경" class="primary" :isDisableStart=true @clicked="reset" />
    </div>
  </div>
</template>

<script>
  import { required, minLength } from 'vuelidate/lib/validators'
  import { confirmPassword } from '../commons/validators';

  export default {
    props: [
      'init_user'
    ],
    data() {
      return {
        user : {
          password: null,
          password_confirm: null,
        }
      }
    },
    methods: {
      reset: function() {
        this.$v.$reset();

        this.$nextTick(() => {
          this.$v.$touch();
          if (this.$v.$error) {
            return;
          }

          const formData = common.objectToFormData({password: this.user.password});
          axios.put(`/users/${this.init_user.id}`, formData).then(response => {
            location.href = "/users/me";
          }).catch(this.onSaveError)
        });
      },
    },
    validations: {
      user: {
        password: {required, minLength: minLength(8)},
        password_confirm: {required, confirmPassword},
      },
    }
  }
</script>