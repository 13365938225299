<template>
  <li class="item" :class="{active: isShow}">
    <div class="item-wrapper">
      <div class="label-description question" @click="changeShow">
        <span class="type label-small">{{ typeName }}</span>
        <span class="label" :class="{bold: isShow}">{{ faq.question }}</span>
        <div class="icon-arrow" :class="{active: isShow}">
          <div class="icon"></div>
        </div>
      </div>

      <div class="answer label-small-description" v-if="isShow" v-html="answer"></div>
    </div>
  </li>
</template>

<script>
  export default {
    props: ['faq'],
    data() {
      return {
        isShow: false,
      }
    },
    computed: {
      typeName: function() {
        let name;
        if (this.faq.faq_type == 'user') {
          name = "[회원]";
        } else if (this.faq.faq_type == 'subscribe') {
          name = "[콘텐츠(구독)]";
        } else if (this.faq.faq_type == 'payment') {
          name = "[결제/할인/취소]";
        } else if (this.faq.faq_type == 'etc') {
          name = "[기타]";
        }

        return name;
      },
      answer: function() {
        return this.faq.answer;
      }
    },
    methods: {
      changeShow: function() {
        this.isShow = !this.isShow;
      }
    }
  }
</script>