<template>
    <div class="search-ranks">
        <div class="search-form-container selectable has-front">
        <div class="front">추천 가이드</div>
        <div class="search">
            <input class="input" type="text" placeholder="검색어를 입력해주세요." />

            <div class="select-box">
            <div class="selected ellipsis one">{{ questionText }}</div>
            <div class="arrow-icon">
                <div class="icon"></div>
            </div>

            <select class="select" v-model="selectedQuestion" @change="onChange()">
                <option v-for="(question, index) in this.questions" :value="question.idx" :key="index">{{question.text}}</option>
            </select>
            </div>

            <button class="button" v-on:click="onClick()">
              <img class="icon" src="https://assets.themiilk.com/investing/icon-search.svg" alt="" />
              <span class="fold-hide">검색</span>
            </button>
        </div>
        </div>
    </div>
</template>
<script>
  export default {
    props: {
      questions: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        selectedQuestion: 1,
        questionText: this.questions[0].text
      }
    },
    methods: {
      onChange: function() {
        let question = this.questions.find(item=>item.idx==this.selectedQuestion);
        this.questionText = question.text;
      },
      onClick: function() {
          location.href = "/investing/stock101?idx=" + this.selectedQuestion;
      }
    }
  }
</script>