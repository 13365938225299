<template>
  <section class="my-container corporate">
    <div class="content">
      <div class="content-head split no-margin">
        <h1 class="label-sub-title section-title">
          <span class="c-primary">{{corporate.company_name}}</span>
          <div class="marking">관리 페이지</div>
        </h1>
      </div>
    </div>

    <div class="content set-sidebar">
      <div class="content-sidebar sticky">
        <div class="sticky-wrapper">
          <ul class="list-menu linear">
            <li class="menu linkage" :class="{active: menus == 'members'}" v-on:click="changeMenu('members')">
              사용자 명단
            </li>
            <li class="bar"></li>
            <li class="menu linkage" :class="{active: menus == 'subscribe'}" v-on:click="changeMenu('subscribe')">
              구독 관리
            </li>
            <li class="bar"></li>
            <!--
            <li class="menu linkage" :class="{active: menus == 'trash'}" v-on:click="changeMenu('trash')">
              휴지통
            </li>
            -->
          </ul>
        </div>
      </div>

      <div class="content-wrapper">
        <members v-if="menus == 'members'" :corporate="corporate" :initMembers="members"></members>
        <subscribe v-else-if="menus == 'subscribe'" :initUser="initUser" :corporate="corporate"></subscribe>
        <!--
        <div v-else-if="menus == 'trash'">Trash</div>
        -->
      </div>
    </div>
  </section>
</template>

<script>
  import Members from "./member";
  import Subscribe from "./subscribe";

  export default {
    props: {
      members: {
        type: Array,
        default: [],
      },
      corporate: {
        type: Object,
        default: {},
      },
      initUser: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        menus: "members",
      }
    },
    computed: {
    },
    components: {
      Members, Subscribe
    },
    methods: {
      changeMenu: function(menu) {
        this.menus = menu;
      }
    }
  }
</script>