import Vue from "vue";
import Vuelidate from "vuelidate";
import VueCookies from "vue-cookies";
import common from "../lib/common";
import VueGoogleCharts from "vue-google-charts";

import TextInput from "../service/commons/components/text_input";
import CardInput from "../service/commons/components/card_input";
import CheckboxInput from "../service/commons/components/checkbox_input";
import ValidationMessage from "../service/commons/components/validation_message";
import PopupMessage from "../service/commons/components/popup_message";
import MiilkButton from "../service/commons/components/miilk_button";
import SocialButton from "../service/commons/components/social_button";
import Carousel from "../service/commons/components/carousel";
import Notification from "../service/commons/components/notification";

import Signup from "../service/users/signup";
import Login from "../service/users/login";
import RequestResetPassword from "../service/users/request_reset_password";
import ResetPassword from "../service/users/reset_password";
import ChangePassword from "../service/users/change_password";
import MyPage from "../service/users/my";
import CorporateMyPage from "../service/corporates/my";

import PromotionOrderPlans from "../service/orders/promotion_plans";
import ChangePlan from "../service/orders/change_plans";
import ChangeCard from "../service/orders/change_card";
import OrderList from "../service/orders/order_list";
import ProductOrder from "../service/orders/product_order";
import NewOrderPlans from "../service/orders/form";

import EventDetail from "../service/events/show";
import ReportDetail from "../service/reports/show";

import Search from "../service/search/index";

import FaqList from "../service/faq/index";

import LayerPopup from "../service/commons/components/layer_popup";

import Team from "../service/about/team";
import Reporters from "../service/about/reporters";

import Holdings from "../service/investors/holdings";
import BubblesD3 from "../service/investors/bubble-d3";
import TreeMapD3 from "../service/investors/treemap-d3";
import StackedD3 from "../service/investors/stacked-d3";

import SearchRanks from "../service/investing/search_ranks";
import RelatedTickers from "../service/investing/related_tickers";
import InvestorsList from "../service/investors/investor_list";

import BusinessApply from "../service/business/apply";
import ToggleButton from "vue-js-toggle-button";
import '../front/assets/tailwind.css';
window._ = require("lodash");
// window.$ = window.jQuery = require('jquery/dist/jquery');
window.moment = require("moment");
window.common = common;
window.axios = require("axios");
axios.defaults.headers.common["Accept"] = "application/json";

Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(ToggleButton);
Vue.use(VueGoogleCharts);

Vue.component("validation-message", ValidationMessage);
Vue.component("text-input", TextInput);
Vue.component("card-input", CardInput);
Vue.component("checkbox-input", CheckboxInput);
Vue.component("miilk-button", MiilkButton);
Vue.component("social-button", SocialButton);
Vue.component("popup-message", PopupMessage);
Vue.component("layer-popup", LayerPopup);

document.addEventListener("DOMContentLoaded", () => {
  common.mountComponent("users-signup", Signup);
  common.mountComponent("users-login", Login);
  common.mountComponent("request-reset-password", RequestResetPassword);
  common.mountComponent("reset-password", ResetPassword);
  common.mountComponent("change-password", ChangePassword);
  common.mountComponent("promotion-order-plans", PromotionOrderPlans);
  common.mountComponent("users-my", MyPage);
  common.mountComponent("corporate-admin-my", CorporateMyPage);
  common.mountComponent("users-change-plan", ChangePlan);
  common.mountComponent("users-change-card", ChangeCard);
  common.mountComponent("users-order-list", OrderList);
  common.mountComponent("events-detail", EventDetail);
  common.mountComponent("search-index", Search);
  common.mountComponent("faq-index", FaqList);
  common.mountComponent("product-order", ProductOrder);
  common.mountComponent("team", Team);
  common.mountComponent("reporters", Reporters);
  common.mountComponent("carousel", Carousel);
  common.mountComponent("investors-holdings", Holdings);
  common.mountComponent("investors-bubbles-d3", BubblesD3);
  common.mountComponent("investors-treemap-d3", TreeMapD3);
  common.mountComponent("investors-stacked-d3", StackedD3);
  common.mountComponent("investing-search-ranks", SearchRanks);
  common.mountComponent("investing-related-tickers", RelatedTickers);
  common.mountComponent("investors-list", InvestorsList);
  common.mountComponent("business-apply", BusinessApply);
  common.mountComponent("notification", Notification);
  common.mountComponent("newPlans", NewOrderPlans);
  common.mountComponent("report-detail", ReportDetail);
});

Vue.filter("formatNumber", function(number) {
  return common.numberWithCommas(number);
});
Vue.filter("intToDate", function(number) {
  return common.intToDateString(number);
});
