var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail account" }, [
    _c("h2", { staticClass: "label-middle-title" }, [_vm._v("비밀번호 변경")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "input-wrapper" },
      [
        _c("text-input", {
          attrs: {
            v: _vm.$v.user.current_password,
            label: "현재 비밀번호",
            placeholder: "비밀번호를 입력하세요.",
            type: "password",
            required: true
          },
          on: { input: _vm.checkValid },
          model: {
            value: _vm.user.current_password,
            callback: function($$v) {
              _vm.$set(_vm.user, "current_password", $$v)
            },
            expression: "user.current_password"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            v: _vm.$v.user.password,
            label: "새 비밀번호",
            placeholder: "새 비밀번호를 입력하세요.",
            type: "password",
            required: true,
            description: "영문, 숫자 조합 8자리 이상"
          },
          model: {
            value: _vm.user.password,
            callback: function($$v) {
              _vm.$set(_vm.user, "password", $$v)
            },
            expression: "user.password"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            v: _vm.$v.user.password_confirm,
            label: "새 비밀번호 확인",
            placeholder: "새 비밀번호를 입력하세요.",
            type: "password",
            required: true
          },
          model: {
            value: _vm.user.password_confirm,
            callback: function($$v) {
              _vm.$set(_vm.user, "password_confirm", $$v)
            },
            expression: "user.password_confirm"
          }
        }),
        _vm._v(" "),
        _c("miilk-button", {
          staticClass: "primary",
          attrs: { v: _vm.$v, name: "비밀번호 변경하기", isDisableStart: true },
          on: { clicked: _vm.modify }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }