<template>
  <div class="chart-box stacked">
    <div class="chart-box-header remove-padding">
      <h2 class="title label-sub-title bold">
        Sector Allocation
      </h2>
      <p class="description label-small-title">
        시간 흐름에 따른 투자 분야의 변화를 통해 트렌드를 파악해보세요!
      </p>
    </div>

    <div id="stacked-bar"></div>

    <div class="options">
      <div class="option-items-wrapper">
        <ul class="option-items option-categories">
          <li
            v-for="(category, index) in categories"
            v-if="index !== 0"
            class="option-item option-category"
          >
            <div
              class="color-box"
              :style="{ background: colorRage[index - 1] }"
            ></div>
            <div class="label label-small-description">{{ category }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    orders: {
      type: Array,
      default: [],
    },
  },
  data() {
    let keys = this.orders[0];
    console.log("order", this.orders);
    console.log("keys", keys);

    let finalData = this.orders.slice(1).map((row) => {
      let rowObj = {};
      keys.map((r, i) => {
        if (i > 0) {
          rowObj[r] = parseFloat(row[i]);
        } else {
          rowObj[r] = row[i];
        }
      });
      return rowObj;
    });

    console.log("final data:", finalData);

    return {
      categories: this.orders[0],
      data: finalData,
      colorRage: [
        "#E7B8B8",
        "#E7CAB8",
        "#EADBA0",
        "#C4E199",
        "#B8E7BE",
        "#9FDECA",
        "#9FD3DE",
        "#A7C5E4",
        "#B1B3E7",
        "#CDB1E7",
        "#E7B8B8",
        "#E7CAB8",
        "#EADBA0",
        "#C4E199",
        "#B8E7BE",
        "#9FDECA",
        "#9FD3DE",
        "#A7C5E4",
        "#B1B3E7",
        "#CDB1E7",
        "#E7B8B8",
        "#E7CAB8",
        "#EADBA0",
        "#C4E199",
        "#B8E7BE",
        "#9FDECA",
        "#9FD3DE",
        "#A7C5E4",
        "#B1B3E7",
        "#CDB1E7",
      ],
    };
  },
  mounted() {
    if (this.data.length > 0) {
      this.showStackedChart();
    }
  },
  computed: {},
  methods: {
    showStackedChart() {
      const width = 640;
      // const margin = {top: 50, right: 10, bottom: 70, left: 30};
      const margin = { top: 50, right: 10, bottom: 0, left: 30 };
      const height = this.data.length * 25 + margin.top + margin.bottom;
      const chartHeight = this.data.length * 25 + margin.top + 10;

      let columns = Object.keys(this.data[0]);

      // 데이터를 stacked chart를 위한 배열로 바꿔주는 함수
      var stack = d3
        .stack()
        .keys(columns.slice(1))
        .offset(d3.stackOffsetExpand);
      const series = stack(this.data);

      console.log("series", series);

      // x 축 데이터를 그림이 그려지는 영역의 범위에 선형으로 맞춤
      const x = d3.scaleLinear().range([margin.left, width - margin.right]);

      // y 축 데이터를 그림이 그려지는 영역의 범위에 묶음 형태로 맞춤
      const y = d3
        .scaleBand()
        .domain(this.data.map((d) => d.year))
        .range([margin.top, height - margin.bottom])
        .padding(0.03);

      console.log(
        "series map",
        series.map((d) => d.key)
      );
      console.log("series", series);
      console.log("series length", series.length);

      // 카테고리 분류에 따른 컬러 매핑
      const color = d3
        .scaleOrdinal()
        .domain(
          series.map((d) => {
            return d.key;
          })
        )
        // .range(d3.schemeSpectral[11]) //12 면 사용할 수 없음.
        .range(this.colorRage)
        .unknown("#FEF2B1");

      const formatPercent = d3.format(".1%");
      const formatValue = (x) => (isNaN(x) ? "N/A" : x.toLocaleString("en"));

      // 차트 영역 설정
      const svg = d3
        .select("#stacked-bar")
        .append("svg")
        .attr("viewBox", [0, 0, width, height])
        .style("overflow", "visible");

      // x축 문자를 표시하는 함수
      const xAxis = (g) =>
        g
          .attr("transform", `translate(0,${margin.top})`)
          .call(d3.axisTop(x).ticks(width / 100, "%"))
          .call((g) => g.selectAll(".domain").remove())
          .selectAll("line")
          .remove();

      // y축 문자를 표시하는 함수
      const yAxis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .call(d3.axisLeft(y).tickSizeOuter(0))
          .call((g) => g.selectAll(".domain").remove())
          .selectAll("line")
          .remove();

      // 그래프를 데이터에 맞춰 사각형으로 x, y축을 자동으로 그림
      svg
        .append("g")
        .selectAll("g")
        .data(series)
        .enter()
        .append("g")
        .attr("fill", (d) => color(d.key))
        .selectAll("rect")
        .data((d) => d)
        .join("rect")
        .attr("x", (d) => x(d[0]))
        .attr("y", (d, i) => y(d.data.year))
        // .attr("rx", '7')
        // .attr("ry", '7')
        .attr("width", (d) => x(d[1]) - x(d[0]))
        .attr("height", y.bandwidth());

      // x 축 그림
      svg.append("g").call(xAxis);

      // y 축 그림
      svg.append("g").call(yAxis);

      var line = 0;
      var col = 0;

      // 주석 표시
      // 주석이 줄 바꿈시 그림의 위치를 바꾸기 위해 좌표 계산
      var legend = svg
        .selectAll(".legend")
        .data(series)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function(d, i) {
          // 폰트에 따라 글짜 폭이 달라서 글자수 7을 기준으로
          // 글자 뒤 스페이스를 달리함
          var space = d.key.length > 7 ? 12 : 20;
          var y = line * 25 + chartHeight;
          var x = col + 4;

          col += d.key.length * 7 + space;
          if (col > width) {
            x = 4;
            col = d.key.length * 7 + space;
            line++;
            y = line * 25 + chartHeight;
          }
          return "translate(" + x + "," + y + ")";
        });

      // legend.append("rect")
      //     .attr("x", 0)
      //     .attr("width", 18)
      //     .attr("height", 18)
      //     .style("fill", function (d) {
      //     return color(d.key);
      // });

      // legend.append("text")
      //     .attr("x", 20)
      //     .attr("y", 9)
      //     .attr("dy", ".35em")
      //     .style("text-anchor", "start")
      //     .style("font-family", "sans-serif")
      //     .style("font-size", "12px")
      //     .text(function (d) {
      //     return d.key;
      // })
      //   .style("fill", "black");
    },
  },
};
</script>
