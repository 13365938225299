var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail" }, [
    _c(
      "div",
      { staticClass: "table-container contact" },
      [
        _c("div", { staticClass: "table-head" }, [
          _c("h2", { staticClass: "title label-middle-title" }, [
            _vm._v("\n        1:1 문의하기\n        "),
            _c(
              "span",
              {
                staticClass: "count label-xxsmall fold-hide",
                class: { active: _vm.changedTalk }
              },
              [_vm._v("대화 핑퐁 " + _vm._s(_vm.contacts.length) + "건")]
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button button-box round bright-blue small",
              on: { click: _vm.newInquiry }
            },
            [_vm._m(0)]
          )
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm.contacts.length > 0
          ? _vm._l(_vm.contacts, function(contact) {
              return _c(
                "div",
                {
                  key: contact.id,
                  staticClass: "row my-contact",
                  on: {
                    click: function($event) {
                      return _vm.show(contact.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "col category" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getCategory(contact.category_id)) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col subject label-middle-description ellipsis two"
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(contact.title) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col state",
                      class: { done: contact.status == 1 }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getStatus(contact.status)) +
                          "\n        "
                      )
                    ]
                  )
                ]
              )
            })
          : _c("div", { staticClass: "row empty" }, [
              _c("p", { staticClass: "title label-middle-title" }, [
                _vm._v("아직 문의 내용이 없습니다.")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "description label-small" }, [
                _vm._v(
                  "\n        문의하실 내용이 있다면 문의글을 써주세요.\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button-box round bright-blue small",
                    on: { click: _vm.newInquiry }
                  },
                  [_vm._v("\n          새 문의 하기\n        ")]
                )
              ])
            ]),
        _vm._v(" "),
        _vm.totalPage > 1
          ? [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "buttons-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button-box round bright-blue small",
                    on: { click: _vm.newInquiry }
                  },
                  [_vm._v("\n          새 문의 하기\n        ")]
                )
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("새 문의"),
      _c("span", { staticClass: "fold-hide" }, [_vm._v(" 하기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row top" }, [
      _c("div", { staticClass: "col category" }, [
        _vm._v("\n        카테고리\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col subject" }, [
        _vm._v("\n        제목\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col state" }, [
        _vm._v("\n        상태\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pagination-wrapper" }, [
      _c("ul", { staticClass: "pagination" }, [
        _c("li", { staticClass: "page-item prev" }, [
          _c("div", { staticClass: "page-link" }, [
            _c("div", { staticClass: "icon-arrow" }, [
              _c("div", { staticClass: "icon" })
            ]),
            _vm._v(" "),
            _c("span", [_vm._v("이전")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "number" }, [
          _c("li", { staticClass: "page-item page-link active" }, [
            _vm._v("1")
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "page-item page-link" }, [_vm._v("2")]),
          _vm._v(" "),
          _c("li", { staticClass: "page-item page-link" }, [_vm._v("3")]),
          _vm._v(" "),
          _c("li", { staticClass: "page-item page-link" }, [_vm._v("4")]),
          _vm._v(" "),
          _c("li", { staticClass: "page-item page-link" }, [_vm._v("5")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-item next" }, [
          _c("div", { staticClass: "page-link link-a" }, [
            _c("span", [_vm._v("다음")]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-arrow" }, [
              _c("div", { staticClass: "icon" })
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }