<template>
  <div class="dialog-container">
    <div class="dialog-background" ></div>
    <div class="dialog-wrapper" v-if="content.chage < 5.0 || content.change > -5.0">
      <div class="button button-circle close-btn" v-on:click="closePop(false)">
        <img class="icon-close" src="https://assets.themiilk.com/common/icon-close-white.svg" alt="Close" />
      </div>
      <div class="dialog-infor" :style="styleBackground(color)">
        <div class="dialog-content">
          <div class="category label-description no-spacing no-uppercase" v-if="content.sector" :class="{light: content.change > 5 || content.change < -5}">{{ content.sector }}</div>
          <div class="row">
            <div class="ticker label-title bold" :class="{light: content.change > 5 || content.change < -5}">{{ content.ticker }}</div>
            <div class="range">
                <div class="name label-small-description bold" :class="{light: content.change > 5 || content.change < -5}">보유비중</div>
                <div class="percent label-middle-title" :class="{light: content.change > 5 || content.change < -5}">{{ parseFloat(content.ratio * 100).toFixed(2) }}%</div>
            </div>
          </div>
          <div class="row">
            <div class="name label-middle-description" :class="{light: content.change > 5 || content.change < -5}">{{ content.english_name }}</div>
            <div class="line"></div>
          </div>
        </div>

        <div class="dialog-footer">
            <div class="result" v-if="content.change != 0 && content.change != null">
                보유 주식수가 전분기 대비 <strong>{{ parseFloat(content.change * 100).toFixed(2) }}%</strong> {{content.change>0?'증가':'감소'}} 했습니다.
            </div>
            <div class="result" v-else-if="content.change == null">
              신규 편입된 종목 입니다.
            </div>
            <div class="result" v-else>
              보유 주식수가 전분기 대비 변화가 없습니다.
            </div>
            <div class="buttons-wrapper" v-if="content.tag">
                <a class="button button-arrow" :href="'/tags/' + content.tag">
                    <span class="company-name">{{ content.name.replace('ADR', '') }} 관련 기사 보러가기</span>
                    <div class="icon-arrow">
                        <div class="icon"></div>
                    </div>
                </a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
        category: {
            default: 'Consumer Defensive',
            type: String
        },
        color: {
            default: 'rgb(205 245 180)',
            type: String
        },
        ticker: {
            default: 'WMT',
            type: String
        },
        percent: {
            default: '45.44',
            type: String
        },
        company_name: {
            default: 'Wallmart',
            type: String
        },
        company_name_kr: {
            default: '월마트',
            type: String
        },
        relate_count: {
            default: 40,
            type: Number
        },
        relate_link: {
            default: '/',
            type: String
        },
        content: {
            default: {},
            type: Object
        },
    },
    computed: {
    },
    methods: {
      closePop: function(e) {
        this.$emit('close-layer-popup', e);
      },
      styleBackground: function(color) {
        return `--background-color: ${color}`;
      }
    }
  }
</script>
