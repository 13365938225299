<template>
  <div class="list-wrapper">
    <ul class="list news" v-if="reports.length > 0">
      <li class="item" v-for="report in reports">
        <article class="item-wrapper investing-item">
          <div class="image-wrapper remove-line-height">
            <a :href="reportUrl(report)" @click="clickReport(report)">
              <div class="image-box" :style="heroImageUrl(report)">
                <img class='blank-image' src="https://assets.themiilk.com/common/blank-6-4.png" alt='' />
              </div>
            </a>
          </div>

          <div class="text-wrapper">
            <ul class="tags" v-if="report.tags.length > 0">
              <li class="tag" v-for="tag in report.tags">
                <a :href="tagUrl(tag)">
                  {{ tag.name }}
                </a>
              </li>
            </ul>

            <a :href="reportUrl(report)" @click="clickReport(report)">
              <h2 class="title label-middle-title bold ellipsis one">
                {{ removeTag(report.title) }}
              </h2>
            </a>

            <a class="no-deco" :href="reportUrl(report)" v-if="report.summary" @click="clickReport(report)">
              <p class="description label-small-description ellipsis three">
                {{ stripTag(removeTag(report.summary) )}}
              </p>
            </a>

            <p class="author label-xsmall">
              <span class="bold">발행일</span>
              <span class="date">
                {{ publishedAt(report.published_at) }}
              </span>
            </p>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ['reports'],
    data() {
      return {}
    },
    mounted() {},
    methods: {
      publishedAt: function(date) {
        date = new Date(date);
        date.setHours(date.getHours() + 9);
        return date
          .toISOString()
          .replace("T", " ")
          .substring(0, 10);
      },
      heroImageUrl: function(report) {
        const imageUrl = "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/";
        return `background-size: cover; background-image: url('${report.main_image_url}')`;
      },
      reportUrl: function(report) {
        return `/reports/${report.id}`;
      },
      clickReport: function(report) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_report',
          'report_id': report.id,
          'report_title': report.title,
          'report_published_at': report.published_at
        })
      },
      isFree: function(report) {
        if (report.free_start_at == null || report.free_end_at == null) {
          return false;
        }

        var date = new Date();
        var startAt = new Date(report.free_start_at);
        var endAt = new Date(report.free_end_at);

        return ((startAt <= date) && (endAt > date));
      },
      stripTag: function(str) {
        var div = document.createElement("div");
        div.innerHTML = str;
        var text = div.textContent || div.innerText || "";
        return text;
      },
      tagUrl: function(tag) {
        return `/tags/${tag.id}`;
      },
      removeTag (oriText) {
        return oriText.replace(/(<([^>]+)>)/ig,"");
      },
    }
  }
</script>