var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "login-container padding tiny" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "content-detail" }, [
        _c(
          "div",
          { staticClass: "login-form social" },
          [
            _c("social-button", {
              attrs: { "is-signup": false },
              on: { snsClicked: _vm.snsClick }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "login-form" },
          [
            _c("text-input", {
              attrs: {
                v: _vm.$v.user.loginUserId,
                label: "이메일",
                placeholder: "이메일을 입력하세요.",
                required: true
              },
              model: {
                value: _vm.user.loginUserId,
                callback: function($$v) {
                  _vm.$set(
                    _vm.user,
                    "loginUserId",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "user.loginUserId"
              }
            }),
            _vm._v(" "),
            _c("text-input", {
              attrs: {
                v: _vm.$v.user.password,
                label: "비밀번호",
                placeholder: "비밀번호를 입력하세요.",
                type: "password",
                required: true
              },
              on: { enter: _vm.validationWithLogin },
              model: {
                value: _vm.user.password,
                callback: function($$v) {
                  _vm.$set(
                    _vm.user,
                    "password",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "user.password"
              }
            }),
            _vm._v(" "),
            _c("checkbox-input", {
              attrs: {
                label: "로그인상태유지",
                value: "true",
                pk: "isKeep",
                checked: true
              },
              model: {
                value: _vm.user.isKeep,
                callback: function($$v) {
                  _vm.$set(_vm.user, "isKeep", $$v)
                },
                expression: "user.isKeep"
              }
            }),
            _vm._v(" "),
            _vm.user.misMatchedPwd
              ? _c("div", { staticClass: "message error from-bottom" }, [
                  _vm._v(
                    "\n          이메일 또는 비밀번호를 확인해주세요.\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("miilk-button", {
              staticClass: "primary",
              attrs: {
                v: _vm.$v,
                name: "이메일로 로그인",
                isDisableStart: true
              },
              on: { clicked: _vm.login }
            }),
            _vm._v(" "),
            _vm._m(2)
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "message top-split" }, [
          _c("p", [
            _vm._v("더밀크가 처음이신가요? "),
            _c(
              "a",
              {
                staticClass: "button-link",
                attrs: { href: "/signup?url=" + _vm.getReturnUrl() }
              },
              [_vm._v("회원가입")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-head" }, [
      _c("h1", { staticClass: "label-title" }, [_vm._v("로그인")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "splitter" }, [
      _c("hr", { staticClass: "line" }),
      _vm._v(" "),
      _c("span", { staticClass: "label label-small" }, [
        _vm._v("또는 이메일로 로그인")
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c(
        "a",
        {
          staticClass: "button-link",
          attrs: { href: "/users/reset_password" }
        },
        [_vm._v("비밀번호를 잊으셨나요?")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }