<template>
  <div class="dialog-container">
    <div class="dialog-background"></div>
    <div class="dialog-wrapper">
      <div class="dialog-box">
        <div class="dialog-content">
          <div class="content-wrapper label-middle-description" v-html="content"></div>
        </div>
        <div class="dialog-button">
          <div class="button button-box confirm-btn" v-on:click="closePop">확인</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'content'],
    methods: {
      closePop: function() {
        this.$emit('close-layer-popup');
      }
    }
  }
</script>
