var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel-container" },
    [
      _c(
        "carousel-3d",
        {
          attrs: {
            "controls-visible": true,
            "controls-prev-html": "",
            "controls-next-html": "",
            "controls-width": 50,
            "controls-height": 50,
            clickable: true,
            border: 0,
            width: 640,
            height: 360,
            space: 350
          },
          on: { "before-slide-change": _vm.onBeforeSlideChange }
        },
        _vm._l(_vm.videos, function(video, i) {
          return _c("slide", { key: video.id, attrs: { index: i, value: i } }, [
            _c(
              "a",
              {
                staticClass: "linkage",
                attrs: { href: _vm.buildVideoUrl(video.id) }
              },
              [
                _c("img", {
                  class: { active: _vm.current == i },
                  attrs: { src: video.thumbnail_url }
                })
              ]
            ),
            _vm._v(" "),
            _c("button", { staticClass: "button button-play" }, [
              _c("div", { staticClass: "icon-play-arrow" })
            ])
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "carousel-indicator",
          attrs: { "data-current": { isMobile: _vm.isMobile } }
        },
        _vm._l(_vm.videos, function(video, i) {
          return _c(
            "li",
            {
              key: i,
              staticClass: "item",
              class: { active: _vm.current == i }
            },
            [_c("div", { staticClass: "point" })]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }