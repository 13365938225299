var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "item flip-box", class: { active: _vm.member.opened } },
    [
      _c(
        "div",
        { staticClass: "button button-flip", on: { click: _vm.infoClicked } },
        [
          _c(
            "div",
            { staticClass: "image-wrapper remove-line-height profile-photo" },
            [_c("img", { attrs: { src: _vm.member.imageUrl } })]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "name" }, [
            _vm._v("\n      " + _vm._s(_vm.member.name) + "\n      "),
            _c("span", { staticClass: "label-small position" }, [
              _vm._v(_vm._s(_vm.member.position))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "icon-arrow", class: { active: _vm.member.opened } },
            [_c("div", { staticClass: "icon" })]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flip-box-content" }, [
        _c("div", { staticClass: "text-wrapper label-middle-description" }, [
          _vm._v("\n      " + _vm._s(_vm.member.info) + "\n    ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }