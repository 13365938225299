var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-box stacked" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { attrs: { id: "stacked-bar" } }),
    _vm._v(" "),
    _c("div", { staticClass: "options" }, [
      _c("div", { staticClass: "option-items-wrapper" }, [
        _c(
          "ul",
          { staticClass: "option-items option-categories" },
          _vm._l(_vm.categories, function(category, index) {
            return index !== 0
              ? _c("li", { staticClass: "option-item option-category" }, [
                  _c("div", {
                    staticClass: "color-box",
                    style: { background: _vm.colorRage[index - 1] }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "label label-small-description" }, [
                    _vm._v(_vm._s(category))
                  ])
                ])
              : _vm._e()
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-box-header remove-padding" }, [
      _c("h2", { staticClass: "title label-sub-title bold" }, [
        _vm._v("\n      Sector Allocation\n    ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description label-small-title" }, [
        _vm._v(
          "\n      시간 흐름에 따른 투자 분야의 변화를 통해 트렌드를 파악해보세요!\n    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }