<template>
  <section class="content">
    <div class="reporters-wrapper">
      <h2 class="label-sub-title heavy c-white">
        THE MIILK REPORTERS
      </h2>

      <template>
        <ul class="reporters-list">
          <reporter
            v-for="(reporter, idx) in reporters"
            :reporter="reporter"
            :key="idx"
            @infoClicked="goPage"
          ></reporter>

          <li class="reporters-item register" v-on:click="goRegister">
            <div class="image-wrapper remove-line-height">
              <img
                src="https://assets.themiilk.com/about/logo-mark-only-color.svg"
                alt=""
              />

              <div class="mark"></div>
            </div>

            <div class="text-wrapper">
              <p class="name label-middle-description medium">
                더밀크 기자 지원하기
              </p>
              <p class="description label-xsmall ellipsis two">
                한국 1등 경제지, 전문지, 실리콘밸리 특파원 출신 기자들이
                모였습니다. 데이터 사이언티스트, 석·박사급 연구원들이 미
                현지에서 직접 취재한 콘텐츠를 제공합니다.
              </p>
            </div>
          </li>
        </ul>
      </template>
    </div>
  </section>
</template>

<script>
import Reporter from "./reporter";

export default {
  props: {},
  data() {
    return {
      reporters: [
        {
          id: "s120301230",
          name: "Dolore Est",
          description:
            "Nulla commodo incididunt pariatur esse consectetur proident laboris mollit ut Lorem sunt est. Mollit mollit dolor tempor cillum consectetur incididunt do consequat labore commodo proident. Voluptate minim ad do tempor proident laborum voluptate velit non. Lorem reprehenderit ullamco duis deserunt irure aliquip quis proident. Veniam labore dolore excepteur nulla laborum incididunt sit. Magna sunt mollit elit commodo anim dolor elit laborum nostrud adipisicing non est aliqua amet. Irure sit culpa deserunt sunt commodo Lorem deserunt non eiusmod deserunt.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter1-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Culpa Magna",
          description:
            "Nulla est aliquip esse sint sit incididunt nulla reprehenderit. Lorem magna ut minim voluptate ullamco ex aliqua. Irure consequat velit ut esse excepteur dolor duis nostrud. Do occaecat commodo esse mollit ut officia quis aliqua incididunt in laborum pariatur tempor.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter2-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "ECO",
        },
        {
          id: "s120301230",
          name: "Commodo Enim",
          description:
            "Minim laboris aliquip esse nisi labore. Non sunt sunt dolore veniam. Laboris veniam veniam nulla duis enim elit id deserunt occaecat tempor sit. Culpa pariatur elit non proident anim officia sit eiusmod dolor ex id id.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter3-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "Sports",
        },
        {
          id: "s120301230",
          name: "Lorem Duis",
          description:
            "Officia enim et quis duis reprehenderit ad duis exercitation eiusmod deserunt. Magna laborum quis officia esse sunt consequat officia sint consequat nostrud dolor do. Excepteur qui magna laborum ipsum. Enim magna laborum occaecat commodo nisi ex adipisicing elit mollit non anim.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter1-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "BIO",
        },
        {
          id: "s120301230",
          name: "Dolore Amet Adipisicing",
          description:
            "Commodo consequat eiusmod in labore irure culpa occaecat magna ea minim dolor cupidatat laborum nisi. Excepteur non ipsum cupidatat elit fugiat. Ea in deserunt consectetur ipsum in cillum. Sunt voluptate ad dolor ex dolor in veniam non minim quis in reprehenderit labore sunt.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter2-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Sit Duis",
          description:
            "Ea excepteur qui sint exercitation. Eiusmod mollit eiusmod cupidatat pariatur culpa et laborum elit nostrud excepteur quis aliqua in. Quis consequat nulla amet velit qui cupidatat proident est.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter3-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Incididunt In",
          description:
            "Ut enim nulla velit elit velit amet labore commodo adipisicing sunt fugiat consequat sit et. Amet nisi ullamco cillum nulla nisi nulla irure excepteur non sit aliquip nostrud aliqua aliquip. Adipisicing nisi Lorem ex elit dolor velit quis aliquip.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter1-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Occaecat Eiusmod",
          description:
            "Laboris magna amet ipsum dolor enim velit sint laborum Lorem aliqua pariatur anim. Deserunt exercitation laborum exercitation nostrud cillum culpa velit adipisicing laboris veniam consectetur. Labore id pariatur ad sunt eiusmod tempor quis elit voluptate consequat nulla anim. Et ex nulla ex occaecat aliqua id. Lorem do ipsum ut veniam dolor incididunt ipsum velit. Aliquip do dolore pariatur duis deserunt.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter2-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Mollit Consequat",
          description:
            "Amet aliquip mollit anim consectetur commodo ut non aliqua exercitation. Anim officia voluptate nisi mollit qui sunt ea adipisicing exercitation voluptate velit ullamco. Commodo mollit nisi nulla quis cupidatat laboris ex dolor Lorem esse officia officia commodo.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter3-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Eiusmod Sunt",
          description:
            "Voluptate eu nulla nulla laborum velit pariatur pariatur sint ex velit veniam officia enim. Enim Lorem culpa irure laborum qui enim cupidatat exercitation sint minim incididunt dolore. Consectetur aliquip magna cupidatat ipsum. Sunt velit non exercitation dolor ipsum ut sint cupidatat. Duis ad mollit cupidatat sint voluptate aute. Et cupidatat fugiat magna excepteur. Id enim ad dolor excepteur.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter1-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
        {
          id: "s120301230",
          name: "Aute Nostrud",
          description:
            "Laboris veniam nostrud ipsum mollit aliqua proident Lorem aliquip deserunt culpa. Anim eu eu ea voluptate ea cupidatat magna dolore reprehenderit. Eu non enim occaecat adipisicing cupidatat duis eiusmod quis magna aliquip excepteur ipsum occaecat.",
          thumbnail: "https://assets.themiilk.com/reporters/reporter2-big.png",
          location: {
            city: "San Francisco",
            state: "California",
            country: "USA",
          },
          category: "IT/Tech",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  components: {
    Reporter,
  },
  methods: {
    goPage: function(url) {
      location.href = "/reporter/" + url.id;
    },
    goRegister: function() {
      location.href = "/";
    },
  },
};
</script>
