<template>
  <div class="social-wrapper">
    <div class="social-btn" v-on:click="snsClick('google')" id="g-signin2">
      <div class="wrapper clearfix" >
        <div class="icon google"></div>
        <div class="txt">구글로 {{ actionName }}</div>
      </div>
    </div>

    <div class="social-btn" v-on:click="snsClick('kakao')">
      <div class="wrapper clearfix" >
        <div class="icon kakao"></div>
        <div class="txt">카카오로 {{ actionName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isSignup: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        auth2: null,
      }
    },

    computed: {
      actionName: function () {
        return this.isSignup ? "가입" : "로그인";
      }
    },
    methods: {
      snsClick: function(type) {
        this.$emit("snsClicked", type);
      },

      getReturnUrl: function() {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("url")) {
          return urlParams.get("url");
        }

        return "/";
      }
    }
  }
</script>