var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-wrapper" }, [
    _vm.reports.length > 0
      ? _c(
          "ul",
          { staticClass: "list news" },
          _vm._l(_vm.reports, function(report) {
            return _c("li", { staticClass: "item" }, [
              _c("article", { staticClass: "item-wrapper investing-item" }, [
                _c("div", { staticClass: "image-wrapper remove-line-height" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.reportUrl(report) },
                      on: {
                        click: function($event) {
                          return _vm.clickReport(report)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-box",
                          style: _vm.heroImageUrl(report)
                        },
                        [
                          _c("img", {
                            staticClass: "blank-image",
                            attrs: {
                              src:
                                "https://assets.themiilk.com/common/blank-6-4.png",
                              alt: ""
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-wrapper" }, [
                  report.tags.length > 0
                    ? _c(
                        "ul",
                        { staticClass: "tags" },
                        _vm._l(report.tags, function(tag) {
                          return _c("li", { staticClass: "tag" }, [
                            _c("a", { attrs: { href: _vm.tagUrl(tag) } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(tag.name) +
                                  "\n              "
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.reportUrl(report) },
                      on: {
                        click: function($event) {
                          return _vm.clickReport(report)
                        }
                      }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "title label-middle-title bold ellipsis one"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.removeTag(report.title)) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  report.summary
                    ? _c(
                        "a",
                        {
                          staticClass: "no-deco",
                          attrs: { href: _vm.reportUrl(report) },
                          on: {
                            click: function($event) {
                              return _vm.clickReport(report)
                            }
                          }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "description label-small-description ellipsis three"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.stripTag(_vm.removeTag(report.summary))
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "author label-xsmall" }, [
                    _c("span", { staticClass: "bold" }, [_vm._v("발행일")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "date" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.publishedAt(report.published_at)) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }