var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail" }, [
    _c("div", { staticClass: "table-container contact detail" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row write" }, [
        _c("div", { ref: "category", staticClass: "form-item" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "selectbox" }, [
            _c("span", { staticClass: "selected ellipsis one" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.categoryText) + "\n          "
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                on: {
                  change: function($event) {
                    return _vm.changedCategory($event)
                  }
                }
              },
              [
                _c("option", { attrs: { value: "1" } }, [_vm._v("결제/환불")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [_vm._v("계정관련")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "3" } }, [_vm._v("쿠폰")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "4" } }, [
                  _vm._v("기사 및 리포트 관련")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "0" } }, [_vm._v("기타문의")])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { ref: "titleElement", staticClass: "form-item" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inquiry.title,
                expression: "inquiry.title"
              }
            ],
            staticClass: "input-form",
            attrs: { type: "text", placeholder: "제목을 적어주세요." },
            domProps: { value: _vm.inquiry.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.inquiry, "title", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { ref: "contentElement", staticClass: "form-item" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inquiry.content,
                expression: "inquiry.content"
              }
            ],
            staticClass: "input-form textarea",
            attrs: { type: "text", placeholder: "내용을 적어주세요." },
            domProps: { value: _vm.inquiry.content },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.inquiry, "content", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-item" }, [
          _c("strong", [_vm._v("첨부파일")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-form file" }, [
            _c(
              "button",
              {
                staticClass: "button button-select-file button-box round",
                on: { click: _vm.selectFile }
              },
              [_vm._v("\n            파일 선택\n          ")]
            ),
            _vm._v(" "),
            _c("input", {
              ref: "fileBtn",
              staticStyle: { display: "none" },
              attrs: { accept: "image/*", type: "file" },
              on: { change: _vm.handleFileChange }
            })
          ]),
          _vm._v(" "),
          _vm.inquiry.attachments.length > 0
            ? _c(
                "div",
                { staticClass: "input-form file in-file" },
                [
                  _vm._l(_vm.inquiry.attachments, function(item, idx) {
                    return _c("div", { staticClass: "thumbnail" }, [
                      _c("img", { attrs: { src: item.url, alt: "" } }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button-remove-image",
                          on: {
                            click: function($event) {
                              return _vm.removeFile(idx)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://assets.themiilk.com/mypage/icon-remove.svg",
                              alt: ""
                            }
                          })
                        ]
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button-add-image",
                      on: { click: _vm.selectFile }
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src:
                            "https://assets.themiilk.com/mypage/icon-add-image.svg",
                          alt: ""
                        }
                      })
                    ]
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "explain" }, [
            _vm._v(
              "\n          첨부파일은 JPG, PNG 등 3MB(3072KB) 이하의 이미지를 등록 할 수\n          있습니다.\n        "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-wrapper" }, [
        _c(
          "button",
          {
            staticClass:
              "button button-confirm button-box round bright-blue small",
            on: { click: _vm.createInquiry }
          },
          [_vm._v("\n        글쓰기\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "button button-cancel button-box round bright-blue small",
            on: { click: _vm.cancelInquiry }
          },
          [_vm._v("\n        취소\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-head" }, [
      _c("h2", { staticClass: "title label-middle-title" }, [
        _vm._v("\n        1:1 문의하기\n        "),
        _c("span", { staticClass: "description label-xxsmall fold-hide" }, [
          _vm._v("최대한 빠른 시간 내에 답변 해드리겠습니다.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v("카테고리 "),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v("제목 "),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v("내용 "),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }