<template>
  <div class="content-detail">
    <div class="table-container">
      <h2 class="label-middle-title">구독 결제 내역</h2>

      <div class="row top">
        <div class="col date">결제 일시</div>
        <div class="col name">구독플랜</div>
        <div class="col amount">구매금액</div>
      </div>

      <a class="row no-deco" v-for="order in subscribeOrders" :key="order.id">
        <div class="col date">
          {{ order.created_at_str }}
        </div>
        <div class="col name">
          <span>{{ termsName(order) }}</span>
          <span class="pc">{{period(order)}}</span>
        </div>
        <div class="col amount">
          ${{ order.price }}
        </div>
      </a>

      <div class="row blank" v-if="subscribeOrders.length == 0">
        결제 내역이 없습니다.
      </div>
    </div>

    <div class="table-container">
      <h2 class="label-middle-title">쿠폰 내역</h2>

      <div class="row top">
        <div class="col date">발급일</div>
        <div class="col name">쿠폰명</div>
        <div class="col expired">사용기간</div>
        <div class="col use">사용일</div>
      </div>

      <a class="row no-deco" v-for="coupon in coupons" :key="coupon.id">
        <div class="col date">
          {{ coupon.created_at_str}}
        </div>
        <div class="col name">
          <span>{{ coupon.coupon_type.name }}</span>
        </div>
        <div class="col expired">
          {{ coupon.expire_date_i | intToDate}}
        </div>
        <div class="col use">
          {{ coupon.used_at_str }}
        </div>
      </a>

      <div class="row blank" v-if="coupons.length == 0">
        쿠폰 사용 내역이 없습니다.
      </div>
    </div>

    <div class="info-container">
      <p class="t">
        쿠폰 이용 방법 및 유의사항
      </p>

      <ul class="d">
        <li>
          쿠폰 사용 시 결제 정보가 필요합니다.
        </li>
        <li>
          등록한 결제 정보로 구독기간 종료일에 다음 회차 구독료가 결제됩니다.
        </li>
        <li>
          월간/연간플랜 구독 중 적용이 불가하며, 다음 정기 결제 시 자동으로 쿠폰이 사용됩니다. (사용기한 임박한 쿠폰부터 사용)
        </li>
        <li>
          현재 구독 중인 플랜 변경 시 이용 가능합니다. [마이페이지 > 멤버십 > 플랜 변경하기]
        </li>
        <li>
          쿠폰은 중복 사용이 불가합니다.
        </li>
        <li>
          쿠폰 사용 후 구독 취소 시 쿠폰은 복구되지 않습니다.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default{
    props: {
      subscribeOrders: {
        type: Array,
        default: [],
      },
      coupons: {
        type: Array,
        default: [],
      }
    },
    data() {
      return {};
    },
    computed: {
    },
    methods: {
      termsName: function(subscribe) {
        var planName = subscribe.terms == 'monthly' ? '월간 플랜' : '연간 플랜';
        if (subscribe.order != null && subscribe.order.coupon != null) {
          planName += ` - ${subscribe.order.coupon.name}`;
        }
        return planName;
      },
      period: function(subscribe) {
        var termName = `(${subscribe.start_at_str}-${subscribe.end_at_str})`;
        return termName;
      }
    }
  }
</script>
