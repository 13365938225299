var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail" }, [
    _c(
      "div",
      { staticClass: "table-container" },
      [
        _c("h2", { staticClass: "label-middle-title" }, [_vm._v("구매 내역")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.orders, function(order) {
          return _c(
            "a",
            {
              key: order.id,
              staticClass: "row no-deco",
              attrs: { href: _vm.linkUrl(order) }
            },
            [
              _c("div", { staticClass: "col date" }, [
                _vm._v("\n        " + _vm._s(order.payment_date) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col type" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.orderType(order.orderable_type)) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col name" }, [
                _c("span", { domProps: { innerHTML: _vm._s(order.title) } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col amount" }, [
                _vm._v("\n        $" + _vm._s(order.amount) + "\n      ")
              ])
            ]
          )
        }),
        _vm._v(" "),
        _vm.orders.length == 0
          ? _c("div", { staticClass: "row blank" }, [
              _vm._v("\n      결제 내역이 없습니다.\n    ")
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row top" }, [
      _c("div", { staticClass: "col date" }, [_vm._v("구매일시")]),
      _vm._v(" "),
      _c("div", { staticClass: "col type" }, [_vm._v("유형")]),
      _vm._v(" "),
      _c("div", { staticClass: "col name" }, [_vm._v("상품명")]),
      _vm._v(" "),
      _c("div", { staticClass: "col amount" }, [_vm._v("구매금액")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }