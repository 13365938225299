<template>
  <div class="checkbox-input" :class="{error: v.$error}">
    <input type="checkbox" class="form-control"
           :disabled="disabled"
           :class="{error: v.$error, toggle: isToggle}"
           :id="pk"
           :checked="checked"
           @input="onInput($event.target.checked)"
           @click="onClick">

    <i class="material-icons toggle-off" v-if="isToggle">toggle_off</i>
    <i class="material-icons toggle-on" v-if="isToggle">toggle_on</i>
    <i class="material-icons checked-icon" v-if="!isToggle">check_box</i>
    <label class="label" v-html="label" :for="pk"></label>
  </div>
</template>

<script>
  import {defaultV} from '../validators';

  export default {
    props: {
      label: String,
      val: [String, Boolean],
      disabled: {
        type: Boolean,
        default: false
      },
      isToggle: {
        type: Boolean,
        default: false
      },
      pk: String,
      v: defaultV,
      checked: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      }
    },
    methods: {
      onInput(value) {
        this.v.$reset();
        this.v.$touch();
        this.$emit('input', value);
      },
      onClick(e) {
        this.v.$reset();
        this.v.$touch();
        this.$emit('click', e);
      }
    }
  }
</script>