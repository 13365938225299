var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "reporters-item", on: { click: _vm.infoClicked } },
    [
      _c("div", { staticClass: "image-wrapper remove-line-height" }, [
        _c("img", { attrs: { src: _vm.reporter.thumbnail } }),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-wrapper" }, [
        _c(
          "p",
          { staticClass: "name label-middle-description medium ellipsis one" },
          [_vm._v("\n      " + _vm._s(_vm.reporter.name) + "\n    ")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "category label-xxsmall" }, [
          _vm._v(_vm._s(_vm.reporter.category))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "description label-xsmall ellipsis two" }, [
          _vm._v("\n      " + _vm._s(_vm.reporter.description) + "\n    ")
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cover" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: "https://assets.themiilk.com/about/icon-show-detail.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }