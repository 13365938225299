<template>
  <section class="signup-container padding tiny">
    <div class="content">
      <div class="content-head">
        <p class="label-xsmall bold step">1 / 2 단계</p>
        <h1 class="label-title">회원가입</h1>

        <div class="message-box">
          <div class="message-box-wrapper">
            <h3 class="box-title">
              <span class="label normal">
                회원가입 후 뷰스레터를 <br>
                <span class="point">주 3회 무료</span>로 받아보세요.
              </span>

              <span class="icon-hi remove-line-height">
                <img src="https://assets.themiilk.com/commons/icon-hi.svg" alt="" />
              </span>
            </h3>

            <p class="box-description">
              가치있는 정보를 신선하게 배달하는 메일링 서비스 뷰스레터. 단순 뉴스 서비스가 아닌 세상과 산업의 종합적인 관점(Viewpoints)을 전달드립니다. 뷰스레터는 실리콘밸리 스타트업 이야기를 묶은 '스타트업 포커스'를 포함하여 주 3회(월, 수, 금) 보내드립니다.
            </p>
          </div>
        </div>
      </div>

      <div class="content-detail">
        <div class="login-form social">
          <social-button :is-signup=true @snsClicked="snsClick" />
        </div>

        <div class="splitter">
          <hr class="line" />
          <span class="label label-small">또는 이메일로 회원가입</span>
          <hr class="line" />
        </div>

        <div class="signup-form">
          <text-input v-model.trim="user.login_user_id" :v="$v.user.login_user_id" label="이메일" placeholder="이메일을 입력하세요." :required=true></text-input>
          <text-input v-model.trim="user.password" :v="$v.user.password" label="비밀번호" placeholder="비밀번호를 입력하세요." type="password" :required=true description="영문, 숫자 조합 8자리 이상"></text-input>
          <text-input v-model.trim="user.password_confirm" :v="$v.user.password_confirm" label="비밀번호 확인" placeholder="비밀번호를 입력하세요." type="password" :required=true @input="checkValid"></text-input>
          <text-input v-model.trim="user.name" :v="$v.user.name" label="이름" placeholder="이름을 입력하세요." :required=true @input="checkValid"></text-input>

          <checkbox-input v-model="user.terms" :v="$v.user.terms" :label="termsLabel" val="true" pk="terms" @input="validCheckBox"></checkbox-input>

          <miilk-button :v="$v" name="이메일로 가입" :isDisableStart=true @clicked="signup" />
        </div>

        <div class="message">
          <p>이미 더밀크 계정이 있으신가요? <a href="/login" class="button-link">로그인</a></p>
          <p>기업회원이신가요? <a href="/business" class="button-link">기업회원 상품보러 가기</a></p>
        </div>

        <layer-popup v-if="signupError" :title="popTitle" :content="popContent" @close-layer-popup="closePop"/>
      </div>
    </div>
  </section>
</template>

<script>
  import { required, minLength, maxLength } from 'vuelidate/lib/validators'
  import { requiredName, requiredEmail, requiredPassword, emailValid, confirmPassword } from '../commons/validators';

  export default {
    props: {
    },
    data() {
      return {
        user: this.prepareData(),
        signupError: false,
        popTitle: '',
        popContent: ''
      }
    },
    mounted() {
      const kakaoKey = "e5d202fca4e87a85631146db398a689a";
      $.getScript("//developers.kakao.com/sdk/js/kakao.min.js", function() {
        Kakao.init(kakaoKey);
      });
    },
    computed: {
      termsLabel: function() {
        return "(필수) <a href='/terms' class='button-link' target='_blank'>이용약관</a> 및 <a href='/privacy' class='button-link' target='_blank'>개인정보처리방침</a>에 동의합니다.";
      },
    },
    methods: {
      prepareData() {
        const loginProvider = localStorage.getItem("loginProvider");
        var socialToken = localStorage.getItem("socialToken");
        var socialInfo = localStorage.getItem("socialInfo");

        if (loginProvider !== null && loginProvider.length !== 0 && socialToken !== null && socialToken.length !== 0) {
          socialToken = JSON.parse(socialToken);
          if (socialInfo.length !== 0) {
            socialInfo = JSON.parse(socialInfo);
          }
        }

        var userName = '';
        var email = '';
        var loginUserId = '';
        if (loginProvider === 'kakao') {
          userName = socialInfo.properties.nickname;
          email = socialInfo.kakao_account.email;
          loginUserId = socialInfo.id;
        } else if (loginProvider === 'google') {
          userName = socialInfo.name;
          email = socialInfo.email;
          loginUserId = socialInfo.id;
        } else {
          const guestEmail = $cookies.get("emid");
          if (guestEmail !== null && guestEmail.length > 0) {
            email = atob(guestEmail);
            loginUserId = email;
          }
        }

        const user = {
          login_provider: 'email',
          social_token: null,
          login_user_id: loginUserId,
          password: '',
          password_confirm: '',
          name: userName,
          terms: null,
          email: null,
        };

        return user;
      },
      signup: function() {
        const email = this.user.email || this.user.login_user_id;
        this.user.email = email;
        const formData = common.objectToFormData(this.user);
        axios.post(`/users`, formData).then(response => {
          if (window.fbq) {
            fbq('track', 'CompleteRegistration');
          }
          //location.href = "/orders/signup_plans";
          var returnUrl = this.getReturnUrl();
          location.href = returnUrl;          
        }).catch(e => {
          if (e.response.data.errorCode == 10003) {
            this.popTitle = "이미 가입하신 이메일입니다.";
            this.popContent = e.response.data.message;
            this.signupError = true;
          } else if (e.response.data.errorCode == 10007) {
            this.popTitle = "소셜로그인을 이용해주세요.";
            this.popContent = e.response.data.message;
            this.signupError = true;
          }
        });
      },
      snsClick: function(type) {
        const curDom = this;

        if (type == "kakao") {
          Kakao.Auth.authorize({redirectUri: `${window.redirectHost}/users/kakao/oauth`, state: `${this.getReturnUrl()}`});
        } else {
          const googleClientId = '246544490991-crfiaqauri720n18gj1l6om2s33sppo5.apps.googleusercontent.com';
          const redirectUrl = `${window.redirectHost}/users/google/oauth`;
          location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile%20email&response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUrl}&state=${this.getReturnUrl()}`;
        }
      },
      checkValid: function() {
        this.$v.$reset();
        this.$v.$touch();
      },
      validCheckBox: function(checked) {
        if (checked == true) {
          this.user.terms = true;
        } else {
          this.user.terms = null;
        }
      },
      closePop: function(e) {
        this.signupError = false;
      },
      getReturnUrl: function() {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("url")) {
          return `/orders/signup_plans?url=${urlParams.get("url")}`;
        }
        else return '/orders/signup_plans'
      }      
    },
    validations: {
      user: {
        name: {requiredName, maxLength: maxLength(50)},
        login_user_id: {requiredEmail, emailValid},
        password: {requiredPassword, minLength: minLength(8), },
        password_confirm: {requiredPassword, confirmPassword},
        terms: {required},
      }
    }
  }
</script>