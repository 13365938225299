<template>
  <li class="item" :class="{selected: isSelected}" v-on:click="clicked">
    <div class="floating-tag animation-up-down-default label-small-description bold" v-if="isDiscount && isYearly">
      1주년 이벤트
    </div>

    <div class="plan-box">
      <div class="plan-checkbox" :class="{selected: isSelected}">
        <svg class="icon" viewBox="0 -46 417.81333 417" xmlns="http://www.w3.org/2000/svg">
          <path class="icon-path" fill="#2196f3" d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"/>
        </svg>
      </div>

      <p class="label-small-description plan-name bold">
        {{plan.name}} 플랜
        <span class="current thin mobile-only" v-if="currentPlan">
          현재 구독중
        </span>
      </p>

      <p class="price">
        <span class="label">
          ${{discountPrice()}}
          <span class="origin">
            <span v-if="isDiscount && isYearly">${{discountPrice()}} &#x2190;</span>
            {{ originPrice }}
          </span>
        </span><span class="term">/{{terms}}
          <span class="option" v-if="isYearly">
            {{originDiscountRate}}% 할인
            <template v-if="isDiscount">
            (+ 추가 {{additionalDiscountRate}}% 할인)
            </template>
          </span>
        </span>
      </p>

      <p class="discount">{{ benefitText() }}</p>

      <button class="button button-bordered small mobile-only button-benefits" v-on:click="showBenefit" :class="{show: isDetailShow}">
        혜택보기
        <div class="icon-arrow">
          <div class="icon"></div>
        </div>
      </button>
    </div>

    <ul class="details" :class="{show: isDetailShow}">
      <li class="item">
        <i class="material-icons done">done</i>
        <span class="label">뷰스레터(주3회)</span>
      </li>
      <li class="item" :class="{not: isFree}">
        <i class="material-icons done" v-if="!isFree">done</i>
        <i class="material-icons close" v-if="isFree">close</i>
        <span class="label">더밀크 Top Pick 투자 정보</span>
      </li>
      <li class="item" :class="{not: isFree}">
        <i class="material-icons done" v-if="!isFree">done</i>
        <i class="material-icons close" v-if="isFree">close</i>
        <span class="label">실리콘밸리 테크 및 경제 기사</span>
      </li>
      <li class="item" :class="{not: isFree}">
        <i class="material-icons done" v-if="!isFree">done</i>
        <i class="material-icons close" v-if="isFree">close</i>
        <span class="label">실리콘밸리 주요 인물정보</span>
      </li>
      <li class="item" :class="{not: isFree}">
        <i class="material-icons done" v-if="!isFree">done</i>
        <i class="material-icons close" v-if="isFree">close</i>
        <span class="label">미국 현지 컨퍼런스 및 행사 단독 커버</span>
      </li>
      <li class="item" :class="{not: !isYearly}">
        <i class="material-icons done" v-if="isYearly">done</i>
        <i class="material-icons close" v-else>close</i>
        <span class="label">개별 기업 분석 리포트</span>
      </li>
      <li class="item" :class="{not: !isYearly}">
        <i class="material-icons done" v-if="isYearly">done</i>
        <i class="material-icons close" v-else>close</i>
        <span class="label">실리콘밸리 전문가 Live Q&amp;A</span>
      </li>
    </ul>

    <div class="button-wrapper" :class="buttonSelectedClass" v-if="!currentPlan">
      <button class="button button-box">{{ plan.name }} 플랜 선택</button>
    </div>

    <div class="button-wrapper" v-if="currentPlan">
      <button class="button button-box current">현재 구독 중인 플랜</button>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
    },
    selectedType: {
      type: String,
    },
    currentPlan: {
      type: Boolean,
      default: false,
    },
    isChangeMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isDetailShow: false,
      isDiscount: false,
    }
  },
  mounted: function() {
    // PST: UTC - 8
    const startDate = new Date("2021-10-22T08:00:00");
    const endDate = new Date("2021-12-01T00:00:00");
    const now = new Date();

    this.isDiscount = (startDate.getTime() <= now.getTime()) && (endDate.getTime() >= now.getTime());
    // this.isDiscount = true;
  },
  computed: {
    isYearly() {
      return this.plan.terms == "yearly";
    },
    priceText() {
      if (this.plan.price == 0) {
        return 'FREE';
      } else {
        return `$${this.plan.price}`;
      }
    },

    isFree() {
      return this.plan.terms == "free";
    },
    isMonthly() {
      return this.plan.terms == "monthly";
    },
    isSelected() {
      return this.plan.terms == this.selectedType;
    },
    buttonClass() {
      return this.plan.terms == "monthly" ? "secondary" : "primary";
    },
    buttonSelectedClass() {
      return this.plan.terms == this.selectedType ? "primary" : "secondary";
    },
    buttonName() {
      return this.plan.terms == "monthly" ? "월간" : "연간";
    },
    originPrice() {
      const isOrigin = this.plan.price == this.plan.origin_price;
      return isOrigin ? "" : `$${this.plan.origin_price}`;
    },
    terms() {
      return this.plan.terms == "monthly" ? "월" : "년";
    },
    originDiscountRate() {
      var rate = 100 - Math.trunc(((this.plan.price / this.plan.origin_price) * 100));
      return rate;
    },
    additionalDiscountRate() {
      const additional = this.isChangeMode ? 20 : 10;
      return additional;
    }
  },
  methods: {
    clicked() {
      this.$emit('selected', this.plan.terms, this.plan.id);
    },
    showBenefit() {
      this.isDetailShow = !this.isDetailShow;
    },
    calcDiscountRate() {
      if (this.plan.terms == 'monthly' || this.plan.terms == 'free') {
        return 0;
      }

      var rate = 100 - ((this.plan.price / this.plan.origin_price) * 100);
      if (!this.isDiscount) {
        return rate;
      }

      const additional = this.isChangeMode ? 20 : 10;
      const price = this.plan.price * ((100 - additional) / 100);
      rate = 100 - ((price / this.plan.origin_price) * 100);
      return rate;
    },
    discountPrice() {
      const rate = this.calcDiscountRate();
      return Math.round(this.plan.origin_price * ((100 - rate) / 100));
    },
    benefitText() {
      if (this.plan.price == 0) {
        return '무료 혜택';
      } else if (this.plan.price == this.plan.origin_price) {
        return '기본 혜택';
      }

      const rate = Math.round(this.calcDiscountRate());
      return rate === 0 ? "" : `${rate}% 할인 혜택`;
    },
    discountRate() {
      const rate = this.calcDiscountRate();
      return rate === 0 ? "" : `${rate}% 할인 혜택`;
    },
  }
}
</script>
