var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "reporters-wrapper" },
      [
        _c("h2", { staticClass: "label-sub-title heavy c-white" }, [
          _vm._v("\n      THE MIILK REPORTERS\n    ")
        ]),
        _vm._v(" "),
        [
          _c(
            "ul",
            { staticClass: "reporters-list" },
            [
              _vm._l(_vm.reporters, function(reporter, idx) {
                return _c("reporter", {
                  key: idx,
                  attrs: { reporter: reporter },
                  on: { infoClicked: _vm.goPage }
                })
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "reporters-item register",
                  on: { click: _vm.goRegister }
                },
                [_vm._m(0), _vm._v(" "), _vm._m(1)]
              )
            ],
            2
          )
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        attrs: {
          src: "https://assets.themiilk.com/about/logo-mark-only-color.svg",
          alt: ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mark" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper" }, [
      _c("p", { staticClass: "name label-middle-description medium" }, [
        _vm._v("\n              더밀크 기자 지원하기\n            ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description label-xsmall ellipsis two" }, [
        _vm._v(
          "\n              한국 1등 경제지, 전문지, 실리콘밸리 특파원 출신 기자들이\n              모였습니다. 데이터 사이언티스트, 석·박사급 연구원들이 미\n              현지에서 직접 취재한 콘텐츠를 제공합니다.\n            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }