<template>
  <div class="button-wrapper">
    <div :type="type" class="button button-box fix" :class="{error: hasError, loading: isLoading}" v-on:click="save" v-if="isLoading">
      <div class="txt-wrapper">
        <div class="spin" v-if="isLoading"></div>
        {{ buttonName }}
      </div>
    </div>

    <div :type="type" class="button button-box fix" :class="{error: hasError}" v-on:click="save" v-if="!isLoading">
      {{ buttonName }}
    </div>
  </div>
</template>

<script>
  import {defaultV} from '../validators'

  export default {
    props: {
      type: {
        type: String,
        default: 'button'
      },
      level: {
        type: String,
        default: 'default'
      },
      name: String,
      isDisableStart: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      loadingText: {
        type: String,
        default: null,
      },
      v: defaultV,
    },
    data () {
      return {
        cnt: 0,
        errorStart: true,
      }
    },
    mounted() {
    },
    computed: {
      hasError() {
        if (this.cnt == 0) {
          this.cnt += 1;
          this.errorStart = this.isDisableStart;
        } else {
          this.errorStart = false;
        }

        return this.v.$error || this.errorStart;
      },
      buttonName() {
        if (this.loadingText == null || this.loadingText.length == 0) {
          return this.name;
        }

        return this.isLoading ? this.loadingText : this.name;
      }
    },
    methods: {
      save() {
        if (this.isLoading) {
          return;
        }

        this.v.$reset();

        this.$nextTick(() => {
          this.v.$touch();
          if (this.v.$error) {
            return;
          }

          this.$emit("clicked");
        });

      }
    }
  }
</script>
