<template>
  <div id='arc'>
  </div>
</template>

<script>
import * as d3 from "d3";
export default {
  props: {
    orders: {
      type: Array,
      default: [],
    }    
  },    
  data() {
    return {
      gdp: [
        {country: "USA", value: 20.5 },
        {country: "China", value: 13.4 },
        {country: "Germany", value: 4.0 },
        {country: "Japan", value: 4.9 },
        {country: "France", value: 2.8 }
      ],
      childData: [
        10, 20, 30, 40, 50
      ]
    };
  },
  computed: {
    svgWidth: function(){return 600; },
    svgHeight: function(){return 600; },
    margin:function(){return {top: 20, right: 30, bottom: 30, left: 40}}
  },
  mounted() {
    this.generateArc();
  },
  methods: {
    generateArc() {
      // 데이터 가공, 변수 선언
      const svgWidth = 640;
      const svgHeight = 300;
      const innerRadius = svgWidth / 10;
      const outerRadius = svgWidth / 2;
      const middleRadius = (innerRadius + outerRadius) / 2;
      const sortedGDP = this.gdp.sort((a, b) => (a.value > b.value ? 1 : -1));
      const sGdpValues = sortedGDP.map(d => d['value']);
      const sumGDP = this.gdp.map(d => d['value']).reduce((a,b)=>a+b)
      const sumTest = this.childData.reduce((a,b) => a+b)
      const margin = ({top: 20, right: 30, bottom: 30, left: 40})
      // <svg> element 생성
      let svg = d3
        .select("#arc")
        .append("svg")
        .attr("viewBox",[0, 0, svgWidth, svgHeight])

      
      const g = svg.append("g");




      let data = this.orders;

      const x = d3.scaleLinear().domain(d3.extent(data, d=>d.value)).nice().range([margin.left, svgWidth - margin.right]);
      const y = d3.scaleLinear().domain(d3.extent(data, d=>d.change)).nice().range([svgHeight - margin.bottom, margin.top]);
      const radius = d3.scaleSqrt().domain(d3.extent(data, d=>d.marketCap)).range([svgWidth / 120, svgWidth / 18]);
      const color = d3.scaleOrdinal(data.map(d => d.sector), d3.schemeSet3).unknown("black");

      const xAxis = g => g.attr("transform", `translate(0,${svgHeight - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(svgWidth / 80, ","))
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
            .attr("x", svgWidth)
            .attr("y", margin.bottom - 4)
            .attr("fill", "currentColor")
            .attr("text-anchor", "end")
            .text("Market Cap(billion dollars) →"));

      const yAxis = g => g.attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y))
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text("↑ changes"));
    
    svg.append("g").call(xAxis);
    svg.append("g").call(yAxis);

/*    
     const grid = g => g.attr("stroke", "currentColor")
    .attr("stroke-opacity", 0.1)
    .call(g => g.append("g")
      .selectAll("line")
      .data(x.ticks())
      .join("line")
        .attr("x1", d => 0.5 + x(d))
        .attr("x2", d => 0.5 + x(d))
        .attr("y1", margin.top)
        .attr("y2", svgHeight - margin.bottom))
    .call(g => g.append("g")
      .selectAll("line")
      .data(y.ticks())
      .join("line")
        .attr("y1", d => 0.5 + y(d))
        .attr("y2", d => 0.5 + y(d))
        .attr("x1", margin.left)
        .attr("x2", svgWidth - margin.right));
      

     // g.call(grid);
     //
     //g.call(yAxis);
    */
    const circle = g.attr("stroke", "white")
      .attr("stroke-opacity", "0.2")
      .selectAll("circle")
      .data(data, d => d.symbol)
      .join("circle")
      .sort((a, b) => d3.descending(a.marketCap, b.marketCap))
      .attr("cx", d => x(d.value))
      .attr("cy", d => y(d.change))
      .attr("r", d => radius(d.marketCap))
      .attr("fill", d => color(d.sector))


    
    
    //g.call(circle)
         
      
      
      svg.append("g")
      .attr("font-family", "sans-serif")
      .selectAll("text")
      .data(data)
      .join("text")
      .sort((a, b) => d3.descending(a.marketCap, b.marketCap))
      .filter((d,i)=>i<10)
      .attr("x", d => x(d.value) )
      .attr("y", d => y(d.change))
      .attr("dy", ".3em")
      .attr("text-anchor", "middle")
      .attr('font-size', '8px')
      .text(d => d.symbol)
       .call(text => text.append("tspan")
          .attr("fill-opacity", 0.7)
          .attr("font-weight", "normal")
          .attr("x", -6)
          .attr("dy", "1.15em"));

        

    }
  }
}
</script>
<style>

</style>