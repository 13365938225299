var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail" }, [
    _c("div", { staticClass: "section-container" }, [
      _c("h2", { staticClass: "label-middle-title" }, [
        _vm._v("\n      뷰스레터 관리\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "toggle-container" },
        [
          _c("toggle-button", {
            attrs: { sync: true, width: 44, height: 22, color: "#4C5FC1" },
            on: { change: _vm.newsletterChanged },
            model: {
              value: _vm.user.newsletter,
              callback: function($$v) {
                _vm.$set(_vm.user, "newsletter", $$v)
              },
              expression: "user.newsletter"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "label", on: { click: _vm.newsletterChanged } },
            [_vm._v("뷰스레터 수신")]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "section-container" }, [
      _c("h2", { staticClass: "label-middle-title" }, [
        _vm._v("\n      구독 관리\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "info-wrapper" }, [
          _c("h3", { staticClass: "label-middle-description bold point" }, [
            _vm._v("구독 기간")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "label-small-description description" }, [
            _vm._v(_vm._s(_vm.subscribeTerms))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "message" }, [
      _vm._v(
        "* 기업용 구독 플랜으로 가입된 고객들은 구독 시작 날짜에 상관없이 해당 기업의 구독 종료일에 구독이 종료됩니다."
      )
    ]),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-wrapper" }, [
      _c("h3", { staticClass: "label-middle-description bold point" }, [
        _vm._v("구독 플랜")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "label-small-description description" }, [
        _vm._v("기업용 연간 구독 플랜 12개월")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "message" }, [
      _vm._v("* 개인 회원으로 변경 및 구독 연장을 원하시면 "),
      _c(
        "a",
        {
          staticClass: "link-a",
          attrs: { href: "mailto:support@themiilk.com" }
        },
        [_vm._v("support@themiilk.com")]
      ),
      _vm._v(" 으로 연락주십시오.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }