var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "search-form-landing" }, [
    _c("div", { staticClass: "search-form-landing-sticky" }, [
      _c("div", { staticClass: "search-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query,
              expression: "query"
            }
          ],
          ref: "search",
          staticClass: "search-box-input",
          attrs: {
            type: "text",
            placeholder: "토픽, 제목, 본문 등을 입력해 주세요."
          },
          domProps: { value: _vm.query },
          on: {
            focus: function($event) {
              return _vm.searchInputFocus()
            },
            blur: function($event) {
              return _vm.searchInputBlur()
            },
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.query = $event.target.value
              },
              function($event) {
                return _vm.onInput($event.target.value)
              }
            ],
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onEnter.apply(null, arguments)
            }
          }
        }),
        _vm._v(" "),
        _vm.query && _vm.query.length > 0
          ? _c(
              "button",
              {
                staticClass: "button-cancel icon-circle icon-cancel activated",
                on: { click: _vm.delQuery }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src:
                      "https://assets.themiilk.com/common/icon-cancel-white.svg",
                    alt: ""
                  }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button-search", on: { click: _vm.onEnter } },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: "https://assets.themiilk.com/common/icon-search.svg",
                alt: "Search"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "work label-small-description bold c-white" },
              [_vm._v("검색하기")]
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "search-form-landing-head-form" }, [
      _c("div", { staticClass: "content single" }, [
        _c(
          "a",
          {
            staticStyle: { "text-decoration-line": "none" },
            attrs: { href: "/search" },
            on: { click: _vm.clickSearchHome }
          },
          [
            _c("h1", { staticClass: "label-title title bold" }, [
              _vm._v("SEARCH")
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "label-xxsmall description bold c-white" }, [
          _vm._v("\n        더밀크에 총 "),
          _c("span", { staticClass: "point" }, [
            _vm._v(_vm._s(_vm.totalCount))
          ]),
          _vm._v("개의 글이 있습니다.\n      ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-box-container" }, [
          _c("div", { staticClass: "search-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.query,
                  expression: "query"
                }
              ],
              ref: "search",
              staticClass: "search-box-input",
              attrs: {
                type: "text",
                placeholder: "토픽, 제목, 본문 등을 입력해 주세요."
              },
              domProps: { value: _vm.query },
              on: {
                focus: function($event) {
                  return _vm.searchInputFocus()
                },
                blur: function($event) {
                  return _vm.searchInputBlur()
                },
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.query = $event.target.value
                  },
                  function($event) {
                    return _vm.onInput($event.target.value)
                  }
                ],
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter.apply(null, arguments)
                }
              }
            }),
            _vm._v(" "),
            _vm.query && _vm.query.length > 0
              ? _c(
                  "button",
                  {
                    staticClass:
                      "button-cancel icon-circle icon-cancel activated",
                    on: { click: _vm.delQuery }
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src:
                          "https://assets.themiilk.com/common/icon-cancel-white.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button-search", on: { click: _vm.onEnter } },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://assets.themiilk.com/common/icon-search.svg",
                    alt: "Search"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "work label-small-description bold c-white" },
                  [_vm._v("\n              검색하기\n            ")]
                )
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.reporters.length !== 0
      ? _c(
          "section",
          {
            staticClass: "search-form-landing-reporters",
            class: { active: _vm.reporters, result: _vm.searched }
          },
          [
            _c("div", { staticClass: "content single set-underline" }, [
              _c("div", { staticClass: "reporters-searchs" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "reporters-list" },
                  _vm._l(_vm.reporters, function(item) {
                    return _c(
                      "li",
                      {
                        key: "reporter-item-" + item.id,
                        staticClass: "reporter-item"
                      },
                      [
                        _c(
                          "a",
                          { attrs: { href: "/reporter/" + item.author.id } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-wrapper remove-line-height"
                              },
                              [
                                _c("img", {
                                  staticClass: "profile",
                                  attrs: {
                                    src: item.profile_url,
                                    alt: "reporter-profile-image"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-wrapper" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "name label-small-description bold"
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "description label-xsmall ellipsis two"
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.description)
                                    }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.searched
      ? _c("section", { staticClass: "search-form-landing-tabs" }, [
          _c("div", { staticClass: "content single set-underline" }, [
            _c("ul", { staticClass: "tabs" }, [
              _c(
                "li",
                {
                  staticClass: "tab",
                  class: { active: _vm.contentstype == "all" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-item",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("all")
                        }
                      }
                    },
                    [
                      _vm._v("\n            전체"),
                      _c("span", { staticClass: "count" }, [
                        _vm._v(
                          _vm._s(
                            _vm.newsCount + _vm.videoCount + _vm.reportCount
                          )
                        )
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "tab",
                  class: { active: _vm.contentstype == "news" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-item",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("news")
                        }
                      }
                    },
                    [
                      _vm._v("\n            News"),
                      _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.newsCount))
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "tab",
                  class: { active: _vm.contentstype == "video" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-item",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("video")
                        }
                      }
                    },
                    [
                      _vm._v("\n            Video"),
                      _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.videoCount))
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "tab",
                  class: { active: _vm.contentstype == "reports" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-item",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("reports")
                        }
                      }
                    },
                    [
                      _vm._v("\n            Report"),
                      _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.reportCount))
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sort" }, [
              _vm.type == "all" || _vm.type == "news"
                ? _c(
                    "button",
                    {
                      staticClass: "sort-item",
                      class: { active: _vm.sort == "acc" },
                      on: {
                        click: function($event) {
                          return _vm.changeSort("acc")
                        }
                      }
                    },
                    [
                      _vm._v("\n          정확"),
                      _c("span", { staticClass: "mobile-hide" }, [
                        _vm._v("도순")
                      ]),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: {
                            width: "12px",
                            height: "14px",
                            viewBox: "0 0 12 14",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                stroke: "none",
                                "stroke-width": "1",
                                fill: "none",
                                "fill-rule": "evenodd"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(-1262.000000, -420.000000)"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(388.000000, 403.000000)"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(874.000000, 17.000000)"
                                          }
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              x: "0",
                                              y: "0",
                                              width: "12",
                                              height: "14"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "g",
                                            {
                                              staticClass: "svg",
                                              attrs: {
                                                transform:
                                                  "translate(2.000000, 1.000000)",
                                                "fill-rule": "nonzero"
                                              }
                                            },
                                            [
                                              _c("polygon", {
                                                attrs: {
                                                  points:
                                                    "4.66156356 0 4.66150638 9.94940104 7.49524399 7.116677 8.25237799 7.873811 4.12618899 12 9.51016282e-16 7.873811 0.757133979 7.116677 3.59075724 9.94940104 3.59081442 1.90203256e-15"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "all" || _vm.type == "news"
                ? _c(
                    "button",
                    {
                      staticClass: "sort-item",
                      class: { active: _vm.sort == "new" },
                      on: {
                        click: function($event) {
                          return _vm.changeSort("new")
                        }
                      }
                    },
                    [
                      _vm._v("\n          최신"),
                      _c("span", { staticClass: "mobile-hide" }, [
                        _vm._v("순")
                      ]),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: {
                            width: "12px",
                            height: "14px",
                            viewBox: "0 0 12 14",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                stroke: "none",
                                "stroke-width": "1",
                                fill: "none",
                                "fill-rule": "evenodd"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(-1262.000000, -420.000000)"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(388.000000, 403.000000)"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(874.000000, 17.000000)"
                                          }
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              x: "0",
                                              y: "0",
                                              width: "12",
                                              height: "14"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "g",
                                            {
                                              staticClass: "svg",
                                              attrs: {
                                                transform:
                                                  "translate(2.000000, 1.000000)",
                                                "fill-rule": "nonzero"
                                              }
                                            },
                                            [
                                              _c("polygon", {
                                                attrs: {
                                                  points:
                                                    "4.66156356 0 4.66150638 9.94940104 7.49524399 7.116677 8.25237799 7.873811 4.12618899 12 9.51016282e-16 7.873811 0.757133979 7.116677 3.59075724 9.94940104 3.59081442 1.90203256e-15"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.searched
      ? _c("section", { staticClass: "search-form-landing-words" }, [
          _c("div", { staticClass: "content single set-underline" }, [
            _c("div", { staticClass: "recent-searchs" }, [
              _c("div", { staticClass: "section-head" }, [
                _c("h3", { staticClass: "section-title bold" }, [
                  _vm._v("최근 검색어")
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button button-all-remove button-arrow middle",
                    on: { click: _vm.delAllCookies }
                  },
                  [_vm._v("\n            전체 삭제\n          ")]
                )
              ]),
              _vm._v(" "),
              _vm.recentSearchList == null || _vm.recentSearchList.length == 0
                ? _c("div", { staticClass: "word-null" }, [
                    _vm._v("\n          최근 검색기록이 없습니다.\n        ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "word-list" },
                _vm._l(_vm.recentSearchList, function(item, index) {
                  return _c("li", { staticClass: "word-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "word ellipsis one",
                        on: {
                          click: function($event) {
                            return _vm.sendQuery(item)
                          }
                        }
                      },
                      [_c("span", [_vm._v(_vm._s(_vm._f("substring")(item)))])]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button-remove-word",
                        on: {
                          click: function($event) {
                            return _vm.delHistory(index)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "bar" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "bar" })
                      ]
                    )
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "popular-words" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "word-list" },
                _vm._l(_vm.favorites, function(item, index) {
                  return _c(
                    "li",
                    {
                      staticClass: "word-item",
                      on: {
                        click: function($event) {
                          return _vm.sendQuery(item, index)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "rank" }, [
                        _vm._v(_vm._s(index + 1))
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "word ellipsis one",
                          attrs: { href: "#" }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("substring")(item)))
                          ])
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.tags && _vm.tags.length !== 0 && !_vm.q
      ? _c("section", { staticClass: "search-form-landing-tags" }, [
          _c("div", { staticClass: "content single set-underline" }, [
            _c("div", { staticClass: "relative-tag" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "tag-list" },
                _vm._l(_vm.tags, function(item, index) {
                  return item.tag
                    ? _c("li", { key: item.tag.id, staticClass: "tag-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "tag no-deco",
                            attrs: { href: "/tags/" + item.tag.id },
                            on: {
                              click: function($event) {
                                return _vm.clickTag(item.tag.name, index)
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(item.tag.name))])]
                        )
                      ])
                    : _vm._e()
                }),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.searched && _vm.collectionLevel1 != null
      ? _c("section", { staticClass: "search-form-landing-articles" }, [
          _c("div", { staticClass: "content single set-underline" }, [
            _c("div", { staticClass: "articles-box" }, [
              _c("div", { staticClass: "section-head" }, [
                _c("h3", { staticClass: "section-title bold" }, [
                  _vm._v(_vm._s(_vm.collectionLevel1.name))
                ])
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "article-list" },
                _vm._l(
                  _vm.filteredItems(_vm.collectionLevel1.articles),
                  function(item) {
                    return _c(
                      "div",
                      {
                        key: "collection1-article-" + item.article.id,
                        staticClass: "article-item"
                      },
                      [
                        _c("div", { staticClass: "image-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/articles/" + item.article.nickname
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickCollectionArticle(
                                    _vm.collectionLevel1.name,
                                    item.article
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-box",
                                  style: _vm.collectionHeroImageUrl(item)
                                },
                                [
                                  _c("img", {
                                    staticClass: "blank-image",
                                    attrs: {
                                      src:
                                        "https://assets.themiilk.com/common/blank-6-4.png",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          _c(
                            "ul",
                            { staticClass: "tags small-margin" },
                            _vm._l(item.tags, function(tag) {
                              return _c("li", { staticClass: "tag" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag-linkage",
                                    attrs: { href: "/tags/" + tag.id },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickCollectionArticle(
                                          _vm.collectionLevel1.name,
                                          item.article
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(tag.name) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/articles/" + item.article.nickname
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickCollectionArticle(
                                    _vm.collectionLevel1.name,
                                    item.article
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title label-middle-description bold ellipsis two"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.article.title) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "author label-xsmall ellipsis one" },
                            [
                              _c("author-name", {
                                attrs: { article: item.article }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "date" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      item.article.published_at.substring(0, 10)
                                    ) +
                                    "\n                "
                                )
                              ])
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }
                ),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.searched && _vm.collectionLevel2 != null
      ? _c("section", { staticClass: "search-form-landing-articles" }, [
          _c("div", { staticClass: "content single set-underline" }, [
            _c("div", { staticClass: "articles-box" }, [
              _c("div", { staticClass: "section-head" }, [
                _c("h3", { staticClass: "section-title bold" }, [
                  _vm._v(_vm._s(_vm.collectionLevel2.name))
                ])
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "article-list" },
                _vm._l(
                  _vm.filteredItems(_vm.collectionLevel2.articles),
                  function(item) {
                    return _c(
                      "div",
                      {
                        key: "collection2-article-" + item.article.id,
                        staticClass: "article-item"
                      },
                      [
                        _c("div", { staticClass: "image-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/articles/" + item.article.nickname
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickCollectionArticle(
                                    _vm.collectionLevel2.name,
                                    item.article
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-box",
                                  style: _vm.collectionHeroImageUrl(item)
                                },
                                [
                                  _c("img", {
                                    staticClass: "blank-image",
                                    attrs: {
                                      src:
                                        "https://assets.themiilk.com/common/blank-6-4.png",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          _c(
                            "ul",
                            { staticClass: "tags small-margin" },
                            _vm._l(item.tags, function(tag) {
                              return _c("li", { staticClass: "tag" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag-linkage",
                                    attrs: { href: "/tags/" + tag.id },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickCollectionArticle(
                                          _vm.collectionLevel2.name,
                                          item.article
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(tag.name) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/articles/" + item.article.nickname
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickCollectionArticle(
                                    _vm.collectionLevel2.name,
                                    item.article
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title label-middle-description bold ellipsis two"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.article.title) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "author label-xsmall ellipsis one" },
                            [
                              _c("author-name", {
                                attrs: { article: item.article }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "date" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      item.article.published_at.substring(0, 10)
                                    ) +
                                    "\n                "
                                )
                              ])
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }
                ),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.searched
      ? _c("section", { staticClass: "search-form-landing-result" }, [
          _c("div", { staticClass: "bottom-options" }, [
            _c("div", { staticClass: "sort" }, [
              _vm.type == "all" || _vm.type == "news"
                ? _c(
                    "button",
                    {
                      staticClass: "sort-item",
                      class: { active: _vm.sort == "acc" },
                      on: {
                        click: function($event) {
                          return _vm.changeSort("acc")
                        }
                      }
                    },
                    [
                      _vm._v("\n          정확도순\n          "),
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: {
                            width: "12px",
                            height: "14px",
                            viewBox: "0 0 12 14",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                stroke: "none",
                                "stroke-width": "1",
                                fill: "none",
                                "fill-rule": "evenodd"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(-1262.000000, -420.000000)"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(388.000000, 403.000000)"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(874.000000, 17.000000)"
                                          }
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              x: "0",
                                              y: "0",
                                              width: "12",
                                              height: "14"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "g",
                                            {
                                              staticClass: "svg",
                                              attrs: {
                                                transform:
                                                  "translate(2.000000, 1.000000)",
                                                "fill-rule": "nonzero"
                                              }
                                            },
                                            [
                                              _c("polygon", {
                                                attrs: {
                                                  points:
                                                    "4.66156356 0 4.66150638 9.94940104 7.49524399 7.116677 8.25237799 7.873811 4.12618899 12 9.51016282e-16 7.873811 0.757133979 7.116677 3.59075724 9.94940104 3.59081442 1.90203256e-15"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "all" || _vm.type == "news"
                ? _c(
                    "button",
                    {
                      staticClass: "sort-item",
                      class: { active: _vm.sort == "new" },
                      on: {
                        click: function($event) {
                          return _vm.changeSort("new")
                        }
                      }
                    },
                    [
                      _vm._v("\n          최신순\n          "),
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: {
                            width: "12px",
                            height: "14px",
                            viewBox: "0 0 12 14",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                stroke: "none",
                                "stroke-width": "1",
                                fill: "none",
                                "fill-rule": "evenodd"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(-1262.000000, -420.000000)"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(388.000000, 403.000000)"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(874.000000, 17.000000)"
                                          }
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              x: "0",
                                              y: "0",
                                              width: "12",
                                              height: "14"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "g",
                                            {
                                              staticClass: "svg",
                                              attrs: {
                                                transform:
                                                  "translate(2.000000, 1.000000)",
                                                "fill-rule": "nonzero"
                                              }
                                            },
                                            [
                                              _c("polygon", {
                                                attrs: {
                                                  points:
                                                    "4.66156356 0 4.66150638 9.94940104 7.49524399 7.116677 8.25237799 7.873811 4.12618899 12 9.51016282e-16 7.873811 0.757133979 7.116677 3.59075724 9.94940104 3.59081442 1.90203256e-15"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content single set-underline" },
            [
              _vm.contentstype == "all" && _vm.news && _vm.videos && _vm.the100
                ? _c("all-result", {
                    attrs: {
                      query: _vm.query,
                      articles: _vm.news,
                      newsCount: _vm.newsCount,
                      videos: _vm.videos,
                      videoCount: _vm.videoCount,
                      reportCount: _vm.reportCount,
                      reports: _vm.reports,
                      the100: _vm.the100,
                      isYearlyPlan: _vm.isYearlyPlan
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contentstype == "news" && _vm.news
                ? _c("news-result", {
                    attrs: { articles: _vm.news, pageCount: _vm.newsPageCount }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contentstype == "video" && _vm.videos
                ? _c("video-result", {
                    attrs: { videos: _vm.videos, pageCount: _vm.videoPageCount }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contentstype == "reports" && _vm.reports
                ? _c("report-result", {
                    attrs: {
                      reports: _vm.reports,
                      pageCount: _vm.reportPageCount
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isSearching && _vm.isEmpty
            ? _c("div", { staticClass: "empty-article" }, [
                _vm._v("\n      검색 결과가 없습니다.\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSearching &&
          (_vm.news.length === 0) &
            (_vm.videos.length === 0) &
            (_vm.reports.length === 0)
            ? _c("div", { staticClass: "empty-article" }, [
                _vm._v("\n      요청하신 자료를 검색중입니다.\n    ")
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasPage && _vm.contentstype != "all"
      ? _c("section", { staticClass: "search-form-landing-pagenation" }, [
          _c("div", { staticClass: "content single" }, [
            _c("div", { staticClass: "pagination-wrapper" }, [
              _c("ul", { staticClass: "pagination" }, [
                _c("li", { staticClass: "page-item prev" }, [
                  _c(
                    "div",
                    {
                      staticClass: "page-link",
                      class: { "link-a": _vm.page != 1 },
                      on: {
                        click: function($event) {
                          return _vm.pageClick(_vm.page - 1)
                        }
                      }
                    },
                    [_vm._m(3), _vm._v(" "), _c("span", [_vm._v("이전")])]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "number" },
                  _vm._l(this.pageCount, function(idx) {
                    return _vm.liPageNumber(idx) > 0
                      ? _c(
                          "li",
                          {
                            key: idx,
                            staticClass: "page-item page-link",
                            class: {
                              active: _vm.liPageNumber(idx) == _vm.page
                            },
                            on: {
                              click: function($event) {
                                _vm.pageClick(_vm.liPageNumber(idx))
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.liPageNumber(idx)))])]
                        )
                      : _vm._e()
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "page-item next",
                    class: { ex: _vm.lastPage() }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "page-link",
                        class: { "link-a": !_vm.lastPage() },
                        on: {
                          click: function($event) {
                            return _vm.pageClick(_vm.page + 1)
                          }
                        }
                      },
                      [_c("span", [_vm._v("다음")]), _vm._v(" "), _vm._m(4)]
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-head" }, [
      _c("h3", { staticClass: "section-title bold" }, [_vm._v("관련 기자")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "section-title bold" }, [
      _vm._v("\n          인기 검색어"),
      _c("span", { staticClass: "time normal" }, [_vm._v("최근 7일")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-head" }, [
      _c("h3", { staticClass: "section-title bold" }, [
        _vm._v("\n            인기 태그\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }