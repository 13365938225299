<template>
  <section class="plan-container padding tiny">
    <div class="content">
      <div class="content-head">
        <p class="label-xsmall bold step" v-if="signup">2 / 2 단계</p>
        <h1 class="label-title">구독결제</h1>
        <h2 class="label-small-title plan-title">
          <span>구독 플랜 선택</span>
          <hr class="mobile-only" />
        </h2>
      </div>

      <div class="content-detail">
        <ul class="plans">
          <plan-card
              v-for="plan in plans"
              :key="plan.id"
              :plan="plan"
              :selectedType="selectedType"
              :couponId="selectedCoupon"
              @selected="planSelected"
          />
        </ul>

        <template v-if="this.selectedType != 'free'">
          <div class="payment payment-container">
            <h2 class="label-small-title payment-title">
              <span>구매 정보</span>
              <hr />
            </h2>

            <div class="amount">
              <div class="row">
                <div class="key">구매 상품</div>
                <div class="val plan" v-if="selectedPlan">{{selectedPlan.name}} 플랜</div>
              </div>

              <div class="row">
                <div class="key">결제 금액</div>
                <div class="val">${{ amount }}</div>
              </div>

              <div class="row discount" v-if="(paymentInfo.discount > 0)">
                <div class="key">할인 <span class="small"></span></div>

                <div class="val">${{paymentInfo.discount}}</div>
              </div>


              <div class="row total">
                <div class="key"><strong>최종 결제 금액</strong></div>
                <div class="val"><strong>${{ paymentInfo.totalAmount }}</strong></div>
              </div>
            </div>
          </div>

          <div class="payment payment-container" v-if="coupons.length > 0">
            <h2 class="label-small-title payment-title">
              <span>쿠폰</span>
              <hr />
            </h2>

            <div class="text-input">
              <div class="selectbox-element">
                <div class="selectbox-element-wrapper">
                  <div class="selected">
                    <span class="">{{couponName}}</span>
                  </div>
                  <div class="icon-arrow">
                    <div class="icon"></div>
                  </div>

                  <select v-model="selectedCoupon" @change="changedCoupon">
                    <option value="-1">
                      쿠폰을 선택해주세요.
                    </option>

                    <option :value="coupon.id" v-for="coupon in coupons">
                      {{coupon.name}}
                    </option>
                  </select>
                </div>

                <label class="state-message" v-if="selectedCoupon != -1">
                  <i class="material-icons done">done</i>
                  <small>할인 코드가 적용 되었습니다.</small>
                </label>
              </div>
            </div>
          </div>

          <div class="payment payment-container">
            <h2 class="label-small-title payment-title" style="margin-bottom:0">
              <span>결제 정보 입력</span>
              <hr />
            </h2>
            <p style="font-size:10px;margin-bottom:20px;">
              *구독기간 종료일에 다음 회차 구독료가 자동 결제 됩니다.
            </p>

            <div class="selectbox-element">
              <div class="selectbox-element-wrapper">
                <div class="selected">
                  <span class="">{{cardName}}</span>
                </div>
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>

                <select v-model="paymentInfo.selectedMethod">
                  <option value="-1">
                    새로운 카드 등록
                  </option>

                  <option :value="method.id" v-for="method in paymentMethods">
                    {{getCardName(method)}}
                  </option>
                </select>
              </div>
            </div>

            <template v-if="paymentInfo.selectedMethod == -1">
              <card-input v-model="paymentInfo.cardInfo" :v="$v.paymentInfo.cardInfo" label="카드번호" placeholder="카드번호를 입력하세요." :required="true" :clientSecret="clientSecret" @card-change="cardNumberChange" @card-error="cardError" @card-valid="setCardValid" />
              <text-input v-model="paymentInfo.name" :v="$v.paymentInfo.name" label="이름" placeholder="카드에 적힌 이름을 입력하세요." :required=true @input="checkValid" />
            </template>

            <checkbox-input v-model="paymentInfo.paymentTerm" :v="$v.paymentInfo.paymentTerm" :label="termsLabel" val="true" pk="terms" @input="validCheckBox"></checkbox-input>
            <miilk-button :v="$v" name="구독 시작" class="primary" :isLoading="isLoading" loading-text="결제 처리중..." :isDisableStart=true @clicked="subscribe" />

            <div class="message small">
              <p>* 구독 취소 또는 해지를 원하시면 <a class="button-link" href="mailto:support@themiilk.com">support@themiilk.com</a> 으로 연락주십시오.</p>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="payment payment-container">
            <miilk-button :v="$v" name="시작하기" class="primary" :isLoading="isLoading" :isDisableStart=false @clicked="startService" />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import {cardValidation, cvcValid, requiredName, requiredPaymentTerm } from "../commons/validators";
import PlanCard from "./plan_card";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Vue from "vue";
Vue.use(Antd);

export default {
  components: {
    PlanCard
  },
  props: {
    signup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: null,
      uid: null,
      plans: [],
      initClientSecret: null,

      selectedCoupon: -1,
      coupons: [],

      paymentMethods: [],

      createdKey: 0,

      selectedType: 'yearly',
      selectedPlanId: 2,
      selectedPlan: null,
      isLoading: false,
      paymentInfo: {
        cardInfo: null,
        isValidCard: false,
        isValidCvc: true,

        cardInfoError: null,
        name: null,

        discount: 0,
        discountRate: 0,
        paymentTerm: null,
        totalAmount: 0,
        isFree: false,
        selectedMethod: -1,
      },
      clientSecret: null,
      expiredMethodIds: [],
    }
  },
  created() {
    // get user infos
    axios.get("/api/users/me").then(res => {
      this.user = res.data.user;

      if (this.user.is_subscribe) {
        location.href = "/users/me/membership";
      }
    }).catch(e => {
      if (error.response && error.response.data && error.response.data.errorCode == 10002) {
        location.href = "/";
      }
    });

    // get plans
    axios.get(`/api/orders/plans?free=${this.signup}`).then(res => {
      this.plans = res.data.plans;

      const items = this.plans.map(plan => ({
        item_id: plan.id,
        item_name: plan.terms,
        item_category: 'Subscription',
        currency: 'USD',
        price: plan.price,
        quantity: 1
      }))

      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: items
        }
      });

      this.selectedPlan = this.plans.find((plan) => {
        return plan.terms == 'yearly';
      });

      this.initPaymentInfo();
    });

    // get coupons
    axios.get("/api/users/coupons").then(res => {
      if (res.data.coupons) {
        res.data.coupons.forEach((coupon) => {
          this.coupons.push(coupon);
        });
      }
    });

    // get cards
    axios.get("/api/users/payment_methods").then(res => {
      if (res.data.payment_methods) {
        res.data.payment_methods.forEach(method => {
          this.paymentMethods = res.data.payment_methods;

          if (this.paymentMethods.length > 0) {
            this.paymentInfo.selectedMethod = this.paymentMethods[0].id;
          }
        });
      }
    });

    this.uid = this.generateUUID();

    this.$v.$reset();
    this.$v.$touch();

    // client secret
    this.updateClientSecret();
  },
  methods: {
    generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : r & 0x7 | 0x8).toString(16);
      });
      return uuid;
    },
    initPaymentInfo() {
      this.paymentInfo.discount = 0;
      this.paymentInfo.discountRate = 0;
      this.paymentInfo.totalAmount = this.selectedPlan.price;
    },

    updateClientSecret() {
      axios.get("/api/users/client_secret").then(res => {
        this.clientSecret = res.data.client_secret;
      });
    },
    planSelected(type, id) {
      this.selectedType = type;
      this.selectedPlan = this.plans.find((plan) => {
        return plan.id == id;
      });

      this.paymentInfo.isFree = this.selectedPlan.price == 0;
      this.selectedCoupon = -1;
      this.initPaymentInfo();
    },
    cardNumberChange: function(event) {
      if (event != null && event.error != null) {
        this.paymentInfo.isValidCard = false;
      }
    },
    cardError: function(event) {
      this.paymentInfo.isValidCard = false;
      // this.$v.$reset();
      this.$v.$touch();
    },
    setCardValid: function(event) {
      this.paymentInfo.isValidCard = true;
    },
    subscribe: function() {
      if (this.paymentInfo.selectedMethod == -1) {
        var stripe = MiilkStripeElement.stripe;
        var card = MiilkStripeElement.card;
        const self = this;

        this.isLoading = true;
        stripe.confirmCardSetup(
            this.clientSecret,
            {
              payment_method: {
                card: card,
                billing_details: {
                  name: this.paymentInfo.name,
                },
              },
            }
        ).then(function (result) {
          if (result.error) {
            console.log(result.error);
            self.paymentInfo.isValidCard = false;
            self.isLoading = false;
            self.updateClientSecret();
          } else {
            self.payment(result.setupIntent.payment_method, -1);
          }
        });
      } else {
        this.isLoading = true;
        this.payment(null, this.paymentInfo.selectedMethod);
      }
    },
    payment: function (stripePaymentId, miilkPaymentId) {
      const data = {
        payment_id: stripePaymentId,
        payment_method_id: miilkPaymentId,
        plan_id: this.selectedPlan.id,
        plan_name: this.selectedPlan.terms,
        coupon_id: this.selectedCoupon,
        total_amount: this.paymentInfo.totalAmount,
        uid: this.uid,
      };

      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "begin_purchase",
        ecommerce: {
          items: [
            {
              item_id: data.plan_id,
              item_name: data.plan_name,
              item_category: 'Subscription',
              currency: "USD",
              price: data.total_amount,
              quantity: 1,
            }
          ]
        }
      });

      const self = this;
      const formData = common.objectToFormData(data);
      axios.post("/api/orders/payment", formData).then(res => {
        try {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ecommerce:null});
          dataLayer.push({
            'event':'purchase',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': res.data.order.id,
                  'revenue': data.total_amount,
                },
                'products': [{
                  'name': (data.plan_id==1) ? 'monthly': 'yearly',
                  'id': data.plan_id,
                  'price': data.total_amount,
                  'category': 'Subscription',
                  'quantity': 1
                }]
              },
            }
          })

          if(data.coupon_id > 0){
            heap.track('Purchase', {
              order_id: res.data.order.id,
              product_id: data.plan_id,
              product_name: (data.plan_id==1) ? 'monthly': 'yearly',
              product_amount: data.total_amount,
              product_category: 'Subscription',
              order_quntity: 1,
              order_currency: 'USD',
              coupon_id: data.coupon_id
            });              
            heap.addUserProperties({coupon_id: data.coupon_id})
          }
          else {
            heap.track('Purchase', {
              order_id: res.data.order.id,
              product_id: data.plan_id,
              product_name: (data.plan_id==1) ? 'monthly': 'yearly',
              product_amount: data.total_amount,
              product_category: 'Subscription',
              order_quntity: 1,
              order_currency: 'USD'
            });                    
          }


          fbq('track', 'Purchase', {currency: "USD", value: data.total_amount, content_type: data.plan_id});
        } catch (e) {
        }
        location.href = "/orders/complete?url=" + self.getReturnUrl();
      }).catch(this.paymentError)


    },
    getReturnUrl: function() {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("url")) {
        return urlParams.get("url");
      }

      return "/";
    },
    paymentError(error) {
      console.error(error);
      this.isLoading = false;
      this.paymentInfo.isValidCard = false;
      this.updateClientSecret();

      // TODO error valid
    },
    validCheckBox: function(checked) {
      if (checked) {
        this.paymentInfo.paymentTerm = true;
      } else {
        this.paymentInfo.paymentTerm = null;
      }
    },
    checkValid: function() {
      this.$v.$reset();
      this.$v.$touch();
    },
    startService: function() {
      location.href = this.getReturnUrl();
    },
    changedCoupon() {
      // check price
      axios.get(`/api/orders/calculate_price?plan_id=${this.selectedPlan.id}&coupon_id=${this.selectedCoupon}`).then(res => {
        this.paymentInfo.discount = res.data.discount;
        this.paymentInfo.discountRate = res.data.discount_rate;
        this.paymentInfo.totalAmount = res.data.price;
      });
    },
    getCardName(payMethod) {
      return `${payMethod.brand} ···· ···· ···· ${payMethod.last_number}`
    },
  },
  computed: {
    termsLabel: function() {
      return "(필수) 상품, 가격, <a href='/payment' class='button-link' target='_blank'>결제 및 환불 관련 정책</a> 등을 확인하였으며 구매에 동의합니다. ";
    },
    amount: function() {
      return this.selectedPlan?.price;
    },
    cardName() {
      if (this.paymentInfo.selectedMethod == -1) {
        return '새로운 카드 등록';
      }

      const payMethod = this.paymentMethods.find(x => x.id == this.paymentInfo.selectedMethod);
      return `${payMethod.brand} ···· ···· ···· ${payMethod.last_number}`
    },
    couponName() {
      if (this.selectedCoupon == -1) {
        return '쿠폰을 선택해주세요.';
      }

      const coupon = this.coupons.find(x => x.id == this.selectedCoupon);
      return coupon.name;
    },
  },
  validations: {
    paymentInfo: {
      cardInfo: {cardValidation, cvcValid},
      name: {requiredName},
      paymentTerm: {requiredPaymentTerm}
    }
  }
}
</script>
