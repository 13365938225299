<template>
  <div class="reset-password-container">
    <div class="content">
      <p class="title">비밀번호 찾기</p>
      <p class="sub-title">입력하신 이메일로 비밀번호 재설정 링크를 보내드립니다.</p>
      <text-input v-model="email" :v="$v.email" label="이메일" placeholder="이메일을 입력하세요." @enter="requestSend" :required=true></text-input>

      <miilk-button :v="$v" name="비밀번호 재설정 링크 보내기" class="primary" :isDisableStart=true @clicked="requestSend" />
    </div>

    <layer-popup v-if="serverError" :title="popTitle" :content="popContent" @close-layer-popup="closePop"/>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import {emailValid, requiredEmail} from '../commons/validators';

  export default {
    props: {
    },
    data() {
      return {
        email: null,
        serverError: false,
        popTitle: '',
        popContent: ''
      }
    },
    methods: {
      requestSend: function() {
        this.$v.$reset();

        this.$nextTick(() => {
          this.$v.$touch();
          if (this.$v.$error) {
            return;
          }

          const formData = common.objectToFormData({email: this.email});
          axios.post(`/users/request_reset_link`, formData).then(response => {
            location.href = "/login";
          }).catch(e => {
            if (e.response.data.errorCode == 20002) {
              this.popTitle = "소셜 로그인 사용자입니다.";
              this.popContent = e.response.data.message;
              this.serverError = true;
            }
          });
        });
      },
      closePop: function() {
        this.serverError = false;
      }
    },
    validations: {
      email: {requiredEmail, emailValid},
    }
  }
</script>