<template>
  <section class="faqs-container padding tiny">
    <div class="content">
      <div class="content-head">
        <h1 class="label-sub-title">자주 묻는 질문</h1>
      </div>

      <ul class="list-flip">
        <faq-item v-for="faq in faqs" :key="faq.id" :faq="faq" />
      </ul>
    </div>
  </section>
</template>

<script>
  import FaqItem from "./item";

  export default {
    props: ['faqs'],
    components: {
      FaqItem
    }
  }
</script>