<template>
  <div class="content-detail">
    <div class="section-container">
      <h2 class="label-middle-title">
        뷰스레터 관리
      </h2>

      <div class="toggle-container">
        <toggle-button v-model="user.newsletter" :sync="true" :width=44 :height=22 color="#4C5FC1" @change="newsletterChanged" />
        <label class="label" v-on:click="newsletterChanged" >뷰스레터 수신</label>
      </div>
    </div>

    <div class="section-container">
      <h2 class="label-middle-title">
        구독 관리
      </h2>

      <div class="info-container">
        <div class="info-wrapper">
          <h3 class="label-middle-description bold point">구독 플랜</h3>
          <p class="label-small-description description">기업용 연간 구독 플랜 12개월</p>
        </div>

        <div class="info-wrapper">
          <h3 class="label-middle-description bold point">구독 기간</h3>
          <p class="label-small-description description">{{subscribeTerms}}</p>
        </div>
      </div>
    </div>

    <p class="message">* 기업용 구독 플랜으로 가입된 고객들은 구독 시작 날짜에 상관없이 해당 기업의 구독 종료일에 구독이 종료됩니다.</p>
    <p class="message">* 개인 회원으로 변경 및 구독 연장을 원하시면 <a class="link-a" href="mailto:support@themiilk.com">support@themiilk.com</a> 으로 연락주십시오.</p>

  </div>
</template>

<script>
  export default {
    props: {
      initUser: {
        type: Object,
        default: {},
      },
      corporate: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        user: this.prepareUser(this.initUser),
      }
    },
    computed: {
      subscribeTerms: function() {
        const startAt = new Date(this.corporate.start_at);
        const endAt = new Date(this.corporate.end_at);

        return `${common.dateToString(startAt)}-${common.dateToString(endAt)}`;
      }
    },
    components: {
    },
    methods: {
      newsletterChanged: function() {
        const data = {
          newsletter: this.user.newsletter
        };
        const formData = common.objectToFormData(data);
        axios.put(`/users/${this.user.id}`, formData).then(response => {
        }).catch();
      },
      prepareUser: function(initUser) {
        const user = _.cloneDeep(initUser);
        return user;
      }
    }
  }
</script>