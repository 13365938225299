<template>
  <div class="dialog-container">
    <div class="dialog-background"></div>
    <div class="dialog-wrapper">
      <div class="button button-circle close-btn" v-on:click="cancel">
        <img class="icon-close" src="https://assets.themiilk.com/common/icon-close-white.svg" alt="Close" />
      </div>
      <div class="dialog-box">
        <div class="dialog-content">
          <div class="section-title label-small-title bold" v-if="title">{{ title }}</div>
          <div class="content-wrapper label-middle-description" v-html="content"></div>
        </div>
        <div class="dialog-button">
          <div class="button button-box cancel-btn" v-on:click="cancel">취소</div>
          <div class="button button-box confirm-btn" v-on:click="confirm">확인</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String
      },
      content: {
        type: String
      }
    },
    methods: {
      confirm: function() {
        this.$emit("confirmed");
      },
      cancel: function() {
        this.$emit("canceled");
      }
    }
  }
</script>