<template>
  <div class="content-detail payment-method">
    <h2 class="label-middle-title">결제 수단 관리</h2>

    <div class="payment-method-container" v-if="paymentMethods.length > 0">
      <ul class="method-list">
        <li class="method" v-for="method in paymentMethods" :key="method.id">
          <div class="info-wrapper">
            <div class="brand-img" :class="method.brand"></div>

            <div class="name">
              {{ method.brand }}
            </div>

            <div class="numbers">
              **** **** **** {{ method.last_number }}
            </div>
          </div>
          
          <div class="button-wrapper">
            <button class="button button-bordered dissabled-default" :class="{active: method.is_default}" v-on:click="changeDefault(method)">
              <span class="dot" v-if="method.is_default">
                현재 대표 결제
              </span>
              <span class="" v-if="!method.is_default">
               대표 결제 설정
              </span>
            </button>
            <button class="button button-bordered" v-on:click="deleteCard(method)">
              삭제
            </button>
          </div>
        </li>
      </ul>
    </div>

    <div class="payment-method-container add-card" v-else>
      <div class="blank-card" v-if="!addCard" v-on:click="addNewCard">
        <div class="info-wrapper">
          등록된 카드가 없습니다.
        </div>
      </div>
    </div>

    <change-card :class="add-other-payment" :initClientSecret="clientSecret" v-if="addCard" />

    <div class="button-wrapper" v-if="!addCard">
      <button class="button button-bordered" v-on:click="addNewCard">
        새로운 카드 등록하기
      </button>
    </div>

    <layer-confirm-popup v-if="setDefault" :content="popContent" @close-layer-popup="closePop"/>
    <layer-popup v-if="showAlert" :content="alertMessage" @close-layer-popup="closeAlert" />
  </div>
</template>

<script>
  import ChangeCard from './change_card';
  import LayerConfirmPopup from '../../commons/components/layer_confirm_popup';

  export default{
    props: {
      paymentMethods: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        addCard: false,
        clientSecret: null,
        setDefault: false,
        popContent: null,
        popType: null,
        popMethod: null,
        showAlert: false,
        alertMessage: null,
      };
    },
    computed: {
    },
    components: {
      ChangeCard, LayerConfirmPopup
    },
    methods: {
      deleteCard: function(paymentMethod) {
        this.popType = 'DELETE';
        this.popContent = `선택하신 ${paymentMethod.brand} **** ${paymentMethod.last_number}를<br/> 결제 수단 관리에서 삭제하시겠습니까?`;
        this.setDefault = true;
        this.popMethod = paymentMethod;
      },
      changeDefault: function(paymentMethod) {
        if (paymentMethod.is_default) {
          return;
        }

        this.popType = 'DEFAULT';
        this.popContent = `선택하신 ${paymentMethod.brand} **** ${paymentMethod.last_number}를<br/> 대표 결제 수단으로 하시겠습니까?`;
        this.setDefault = true;
        this.popMethod = paymentMethod;
      },
      addNewCard: function() {
        var curDom = this;
        axios.get("/users/client_secret").then(res => {
          curDom.clientSecret = res.data.client_secret;
          curDom.addCard = true;
        });
      },
      closePop: function(confirmed) {
        if (!confirmed) {
          this.popType = null;
          this.popContent = null;
          this.popMethod = null;
          this.setDefault = false;
          return;
        }

        if (this.popType == 'DELETE') {
          this.removeCard(this.popMethod.id);
        } else {
          this.defaultCardSet(this.popMethod.id);
        }

        this.popType = null;
        this.popContent = null;
        this.popMethod = null;
        this.setDefault = false;
      },
      closeAlert: function() {
        this.showAlert = false;
        location.reload();
      },
      removeCard: function(paymentMethodId) {
        axios.post("/users/delete_card", {id: paymentMethodId}).then((res)=>{
          this.alertMessage = "선택하신 결제 수단을 삭제했습니다";
          this.showAlert = true;
        }).catch((e) => {
          try {
            if (e.response.data.message) {
              this.alertMessage = e.response.data.message;
              this.showAlert = true;
            }
          } catch(e1) {
          }
        });
      },
      defaultCardSet: function(paymentMethodId) {
        axios.post("/users/default_card", {id: paymentMethodId}).then((res)=>{
          location.reload();
        });
      }
    }
  }
</script>
