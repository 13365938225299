<template>
  <div class="content-detail account">
    <h2 class="label-middle-title">계정 정보</h2>

    <div class="form-wrapper">
      <text-input v-model="user.name" :v="$v.user.name" label="이름" placeholder="이름을 입력하세요." :required=true></text-input>
      <text-input v-model="user.email" :v="$v.user.email" label="이메일" placeholder="이메일을 입력하세요." :required=true :readonly="true"></text-input>

      <div class="toggle-wrapper">
        <label class="label">뷰스레터 관리</label>

        <div class="toggle-container">
          <toggle-button v-model="user.newsletter" :sync="true" :width=44 :height=22 color="#070d92" @change="newsletterChanged" />
          <label class="label" v-on:click="newsletterChanged" >뷰스레터 수신</label>
        </div>
      </div>
    </div>

    <div class="form-wrapper button-wrapper">
      <miilk-button :v="$v" name="계정 정보 변경하기" class="primary" @clicked="modify" :isDisableStart=true />
    </div>
  </div>
</template>

<script>
  import { emailValid, requiredChanged } from '../../commons/validators';

  export default{
    props: {
      initUser: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        user: this.initializeUser(this.initUser),
      };
    },
    computed: {
    },
    methods: {
      initializeUser: function(initUser) {
        const user = _.cloneDeep(initUser);
        user.oldName = initUser.name;
        return user;
      },
      newsletterChanged: function() {
        const data = {
          newsletter: this.user.newsletter
        };
        const formData = common.objectToFormData(data);
        axios.put(`/users/${this.user.id}`, formData).then(response => {
        }).catch();
      },
      modify: function() {
        const data = {
          name: this.user.name
        };
        const formData = common.objectToFormData(data);

        const curDom = this;

        axios.put(`/users/${this.user.id}`, formData).then(response => {
          curDom.user.oldName = data.name;
          curDom.$v.$reset();
          curDom.$notification.open({
            message: '계정 정보 변경 완료.',
            description: '계정 정보가 성공적으로 업데이트 되었습니다.',
            top: '88px',
          });
        }).catch(this.onSaveError)
      },
    },
    validations: {
      user: {
        name: {requiredChanged},
        email: {}
      }
    }
  }
</script>