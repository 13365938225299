var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "item", class: { active: _vm.isShow } }, [
    _c("div", { staticClass: "item-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "label-description question",
          on: { click: _vm.changeShow }
        },
        [
          _c("span", { staticClass: "type label-small" }, [
            _vm._v(_vm._s(_vm.typeName))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "label", class: { bold: _vm.isShow } }, [
            _vm._v(_vm._s(_vm.faq.question))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "icon-arrow", class: { active: _vm.isShow } },
            [_c("div", { staticClass: "icon" })]
          )
        ]
      ),
      _vm._v(" "),
      _vm.isShow
        ? _c("div", {
            staticClass: "answer label-small-description",
            domProps: { innerHTML: _vm._s(_vm.answer) }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }