var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "reports-landing-detail-head-short" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            {
              staticClass: "content content-wrapper single padding for-sticky"
            },
            [
              _c("div", { staticClass: "image-wrapper remove-lineheight" }, [
                _c("img", {
                  attrs: {
                    src: _vm.report.cover_image_url,
                    alt: _vm.removeTag(_vm.report.title)
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-wrapper" }, [
                _c(
                  "p",
                  { staticClass: "item-label label-xsmall bold ellipsis one" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.report.sub_title) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  { staticClass: "item-point label-small-title c-white" },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.report.title) }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-wrapper" }, [
                !_vm.current_user
                  ? _c(
                      "p",
                      {
                        staticClass: "for-guest label-small-description c-white"
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "linkage",
                            attrs: {
                              href: "/signup?url=/reports/" + _vm.report.id
                            }
                          },
                          [_vm._v("회원가입")]
                        ),
                        _vm._v("\n            또는\n            "),
                        _c(
                          "a",
                          {
                            staticClass: "linkage",
                            attrs: {
                              href: "/login?url=/reports/" + _vm.report.id
                            }
                          },
                          [_vm._v("로그인")]
                        ),
                        _vm._v("\n            후\n          ")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "button-wrapper",
                    class: { "for-guest": !_vm.current_user }
                  },
                  [
                    _vm.isPurchased
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "button button-download button-box round no-deco",
                            on: { click: _vm.downloadFile }
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src:
                                  "https://assets.themiilk.com/reports/icon-download-white.svg",
                                alt: "downloadIcon"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "mobile-hide" }, [
                              _vm._v("지금 바로")
                            ]),
                            _vm._v("\n              다운로드"),
                            _c("span", { staticClass: "fold-hide" }, [
                              _vm._v(" 받기")
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "sub label-small thin mobile-hide"
                              },
                              [_vm._v(_vm._s(_vm.file.file_size))]
                            )
                          ]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "button button-download button-box round no-deco",
                            class: { disabled: !_vm.current_user },
                            on: { click: _vm.order }
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src:
                                  "https://assets.themiilk.com/reports/icon-download-white.svg",
                                alt: "downloadIcon"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.orderMessage) }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "sub label-small thin" },
                              [_vm._v(_vm._s(_vm.file.file_size))]
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "button button-share button-bordered round white no-deco",
                        on: { click: _vm.copyToClipboard }
                      },
                      [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src:
                              "https://assets.themiilk.com/reports/icon-share-arrow-white.svg",
                            alt: "shareIcon"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "plan-list label-xsmall c-white" }, [
                  _c("span", { staticClass: "plan" }, [
                    _vm._v("무료 플랜: $" + _vm._s(_vm.normal_price))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "plan" }, [
                    _vm._v("개인 플랜: $" + _vm._s(_vm.monthly_price))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "plan" }, [
                    _vm._v("기업 플랜: $" + _vm._s(_vm.yearly_price))
                  ])
                ])
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "reports-landing-detail-head" }, [
        _c("div", { staticClass: "content no-padding" }, [
          _c(
            "div",
            { staticClass: "content content-wrapper single padding for-head" },
            [
              _c("div", { staticClass: "image-wrapper remove-lineheight" }, [
                _c("img", {
                  attrs: {
                    src: _vm.report.cover_image_url,
                    alt: _vm.removeTag(_vm.report.title)
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-wrapper" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "item-label label-small-title bold ellipsis one"
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.report.sub_title) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticClass: "item-point label-title c-white ellipsis two"
                  },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.report.title) }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "item-description label-small-description c-white two"
                  },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.report.summary) }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bottom-wrapper" }, [
                  !_vm.current_user
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "for-guest label-middle-description c-white"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "linkage",
                              attrs: {
                                href: "/signup?url=/reports/" + _vm.report.id
                              }
                            },
                            [_vm._v("회원가입")]
                          ),
                          _vm._v("\n              또는\n              "),
                          _c(
                            "a",
                            {
                              staticClass: "linkage",
                              attrs: {
                                href: "/login?url=/reports/" + _vm.report.id
                              }
                            },
                            [_vm._v("로그인")]
                          ),
                          _vm._v("\n              후\n            ")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "button-wrapper" }, [
                    _vm.isPurchased
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "button button-download button-box round no-deco",
                            on: { click: _vm.downloadFile }
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src:
                                  "https://assets.themiilk.com/reports/icon-download-white.svg",
                                alt: "downloadIcon"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "mobile-hide" }, [
                              _vm._v("지금 바로")
                            ]),
                            _vm._v(
                              "\n                다운로드 받기\n                "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "sub label-small thin mobile-hide"
                              },
                              [_vm._v(_vm._s(_vm.file.file_size))]
                            )
                          ]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "button button-download button-box round no-deco",
                            class: { disabled: !_vm.current_user },
                            on: { click: _vm.order }
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src:
                                  "https://assets.themiilk.com/reports/icon-download-white.svg",
                                alt: "downloadIcon"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.orderMessage) }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "sub label-small thin mobile-hide"
                              },
                              [_vm._v(_vm._s(_vm.file.file_size))]
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "button button-share button-bordered round white no-deco",
                        on: { click: _vm.copyToClipboard }
                      },
                      [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src:
                              "https://assets.themiilk.com/reports/icon-share-arrow-white.svg",
                            alt: "shareIcon"
                          }
                        }),
                        _vm._v("\n                공유"),
                        _c("span", { staticClass: "fold-hide" }, [
                          _vm._v("하기")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "plan-list label-small c-white" }, [
                    _c("span", { staticClass: "plan" }, [
                      _vm._v("무료 플랜"),
                      _c("span", { staticClass: "fold-hide" }, [_vm._v(": ")]),
                      _c("span", [_vm._v("$" + _vm._s(_vm.normal_price))])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "plan" }, [
                      _vm._v("개인 플랜"),
                      _c("span", { staticClass: "fold-hide" }, [_vm._v(": ")]),
                      _c("span", [_vm._v("$" + _vm._s(_vm.monthly_price))])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "plan" }, [
                      _vm._v("기업 플랜"),
                      _c("span", { staticClass: "fold-hide" }, [_vm._v(": ")]),
                      _c("span", [_vm._v("$" + _vm._s(_vm.yearly_price))])
                    ])
                  ])
                ])
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "reports-landing-detail-content" }, [
        _c("div", { staticClass: "content padding for-list" }, [
          _c("div", { staticClass: "post-content" }, [
            _c("h2", {}, [_vm._v("소개")]),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.report.detail) } }),
            _vm._v(" "),
            _c("h2", {}, [_vm._v("목차")]),
            _vm._v(" "),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.report.contents) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "option-box" }, [
              _c("div", { staticClass: "option" }, [
                _c("b", [_vm._v("페이지")]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.report.total_pages) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bar" }),
              _vm._v(" "),
              _c("div", { staticClass: "option" }, [
                _c("b", [_vm._v("발행일")]),
                _vm._v(
                  "\n            " + _vm._s(_vm.publishedAt) + "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bar" }),
              _vm._v(" "),
              _c("div", { staticClass: "option" }, [
                _c("b", [_vm._v("파일")]),
                _vm._v("\n            " + _vm._s(_vm.fileType) + "\n          ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bar" }),
              _vm._v(" "),
              _c("div", { staticClass: "option" }, [
                _c("b", [_vm._v("용량")]),
                _vm._v(
                  "\n            " + _vm._s(_vm.file.file_size) + "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("h2", {}, [_vm._v("태그")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tag-box" },
              [
                _vm._l(_vm.tags, function(tag) {
                  return [
                    _c(
                      "a",
                      {
                        key: tag.name,
                        staticClass: "tag",
                        attrs: { href: "/tags/" + tag.id }
                      },
                      [_vm._v("#" + _vm._s(tag.name))]
                    )
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm._m(0)
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "article-detail-options padding xsmall type-blue-" },
        [
          _c("div", { staticClass: "content single" }, [
            _c("div", { staticClass: "article-detail-option" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "related-list" },
                _vm._l(_vm.recent_reports, function(report) {
                  return _c("article", { staticClass: "related-report-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "report-item no-deco",
                        attrs: { href: "/reports/" + report.id }
                      },
                      [
                        _c(
                          "div",
                          {
                            class:
                              "report-item-background remove-line-height type-" +
                              report.cover_color_type +
                              " type-1"
                          },
                          [
                            _c("img", {
                              staticClass: "cover",
                              attrs: {
                                src:
                                  "https://assets.themiilk.com/reports/report-ratio.png",
                                alt: ""
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "report-item-cover" }, [
                              _c("img", {
                                attrs: { src: report.cover_image_url, alt: "" }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "report-item-information" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "item-label label-xsmall c-white ellipsis one"
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(report.sub_title) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h2",
                            {
                              staticClass:
                                "item-point label-small-title bold c-white ellipsis three"
                            },
                            [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(report.title) }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "button-wrapper" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button button-show button-box white bold no-deco",
                                attrs: { href: "/reports/" + report.id }
                              },
                              [
                                _vm._v(
                                  "\n                    자세히 보기\n                  "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                }),
                0
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.paymentPop
        ? _c("payment-popup", {
            attrs: { title: _vm.popTitle, content: _vm.popContent },
            on: { confirmed: _vm.payment, canceled: _vm.cancelOrder }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.sharePop
        ? _c("toast", {
            attrs: {
              title: "이 리포트를 지금 공유해보세요.",
              content:
                "이 리포트에 관심이 있을 만한 사람들과 공유하고 더밀크를 추천해 주세요.",
              facebook: "#",
              twitter: "#",
              kakaotalk: "#",
              linkedin: "#",
              report: _vm.report,
              url: _vm.reportUrl
            },
            on: { canceled: _vm.closeSharePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.completePop
        ? _c("toast", {
            attrs: {
              title: "리포트 결제 완료",
              content:
                "결제가 완료되었습니다.<br/>지금 바로 리포트를 다운로드 받아보세요!"
            },
            on: { canceled: _vm.cancelCompletePop }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPopup
        ? _c("browser-popup", { on: { closeModal: _vm.closeModal } })
        : _vm._e(),
      _vm._v(" "),
      _c("error-popup", {
        attrs: {
          isShow: _vm.paymentErrorPop,
          title: "결제 중 오류가 발생했습니다.",
          description: _vm.errorContent
        },
        on: { close: _vm.closeErrorPopup }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payment-information" }, [
      _c("h2", {}, [_vm._v("결제 및 이용안내")]),
      _vm._v(" "),
      _c("ul", { staticClass: "list dot" }, [
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n              본 리포트에 대한 저작권은 더밀크에 있으며, 무단전재 및 재배포를\n              금지합니다.\n            "
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n              결제된 리포트는 다운로드 여부와는 상관없이 환불이 불가합니다.\n            "
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n              리포트에 대해 문의 사항이 있으시면\n              "
          ),
          _c("a", { attrs: { href: "mailto:support@themiilk.com" } }, [
            _vm._v("support@themiilk.com")
          ]),
          _vm._v("으로 연락주시기 바랍니다.\n            ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "option-title label-small-title bold" }, [
      _c("span", { staticClass: "label" }, [
        _c("span", [_vm._v("최신 리포트")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "button-wrapper" }, [
        _c(
          "a",
          {
            staticClass: "button button-arrow small arrow-emerald no-deco",
            attrs: { href: "/reports" }
          },
          [
            _vm._v("\n              더 보기\n              "),
            _c("div", { staticClass: "icon-arrow" }, [
              _c("div", { staticClass: "icon" })
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }