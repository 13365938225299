<template>
  <section class="plan-container padding tiny">
    <div class="content">
      <div class="content-head">
        <p class="label-xsmall bold step" v-if="signup">2 / 2 단계</p>
        <h1 class="label-title">구독결제</h1>
        <h2 class="label-small-title plan-title">
          <span>구독 플랜 선택</span>
          <hr class="mobile-only" />
        </h2>
      </div>

      <div class="content-detail">
        <ul class="plans">
          <plan-card
              v-for="plan in plans"
              :key="plan.id"
              :plan="plan"
              :selectedType="selectedType"
              @selected="planSelected"
          />
        </ul>

        <div class="payment payment-container">
          <h2 class="label-small-title payment-title">
            <span>구매 정보</span>
            <hr />
          </h2>

          <div class="amount">
            <div class="row">
              <div class="key">구매 상품</div>
              <div class="val plan">{{selectedPlan.name}} 플랜</div>
            </div>

            <div class="row">
              <div class="key">결제 금액</div>
              <div class="val">${{ amount }}</div>
            </div>

            <div class="row discount" v-if="paymentInfo.coupon != null">
              <div class="key">할인 <span class="small"></span></div>

              <div class="val" v-if="paymentInfo.coupon.coupon_type.discount_type == 'rate'">{{paymentInfo.coupon.coupon_type.discount_rate}}%</div>
              <div class="val" v-else-if="paymentInfo.coupon.coupon_type.discount_type == 'terms'">{{paymentInfo.coupon.coupon_type.discount_rate}}개월 무료 구독</div>
            </div>

            <div class="row total">
              <div class="key"><strong>최종 결제 금액</strong></div>
              <div class="val"><strong>${{ totalAmount }}</strong> <span>{{discountInfo}}</span></div>
            </div>
          </div>
        </div>

        <div class="payment payment-container">
          <h2 class="label-small-title payment-title">
            <span>할인 정보 입력</span>
            <hr />
          </h2>

          <div class="text-input">
            <label class="label" :class="{required: required}">할인코드</label>

            <div class="discount-element">
              <input type="text" class="form-control" placeholder="MX302011" v-model="couponCode" />
              <button class="button button-box" @click="checkPromotion">적용</button>
            </div>

            <label class="state-message" v-if="acceptCoupon">
              <i class="material-icons done">done</i>
              할인 코드가 적용 되었습니다.
            </label>
          </div>
        </div>

        <div class="payment payment-container">
          <h2 class="label-small-title payment-title">
            <span>결제 정보 입력</span>
            <hr />
          </h2>

          <card-input v-model="paymentInfo.cardInfo" :v="$v.paymentInfo.cardInfo" label="카드번호" placeholder="카드번호를 입력하세요." :required="true" :clientSecret="clientSecret" @card-change="cardNumberChange" @card-error="cardError" @card-valid="setCardValid" />
          <text-input v-model="paymentInfo.name" :v="$v.paymentInfo.name" label="이름" placeholder="카드에 적힌 이름을 입력하세요." :required=true @input="checkValid" />

          <checkbox-input v-model="paymentInfo.paymentTerm" :v="$v.paymentInfo.paymentTerm" :label="termsLabel" val="true" pk="terms" @input="validCheckBox"></checkbox-input>
          <miilk-button :v="$v" name="구독 시작" class="primary" :isLoading="isLoading" loading-text="결제 처리중..." :isDisableStart=true @clicked="subscribe" />

          <div class="message small">
            <p>* 구독 취소 또는 해지를 원하시면 <a class="button-link" href="mailto:support@themiilk.com">support@themiilk.com</a> 으로 연락주십시오.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { required, maxLength } from 'vuelidate/lib/validators';
import { validPromotionCode, cardValidation, cvcValid, requiredName } from '../commons/validators';
import PlanCard from "./plan_card";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

export default {
  props: [
    'plans', 'initClientSecret', 'signup'
  ],
  data() {
    return {
      acceptCoupon: false,
      selectedType: 'yearly',
      selectedPlanId: 2,
      selectedPlan: null,
      isLoading: false,
      couponCode: null,
      paymentInfo: {
        cardInfo: null,
        isValidCard: false,
        isValidCvc: true,
        cardInfoError: null,
        name: null,
        discount: 0,
        paymentTerm: null,
        coupon: null,
      },
      clientSecret: this.prepareSecret(this.initClientSecret),
    }
  },
  created() {
    this.selectedPlan = this.plans.find((plan) => {
      return plan.terms == 'yearly';
    });
    this.selectedPlanId = this.selectedPlan.id;
    fbq('track', 'InitiateCheckout');
  },
  mounted() {
    // notification 추가로 제거
    // if (this.signup) {
    //   this.$notification.open({
    //     message: '회원가입 완료',
    //     description:
    //         '회원가입이 성공적으로 완료되었습니다. 가입하신 이메일 또는 연결된 SNS를 통해 로그인하실 수 있습니다.',
    //     // icon: <a-icon type="check" />,
    //   });
    // }

    const items = this.plans.map(plan => ({
      item_id: plan.id,
      item_name: plan.terms,
      item_category: 'Subscription',
      currency: 'USD',
      price: plan.price,
      quantity: 1
    }))

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: items
      }
    });
  },
  computed: {
    termsLabel: function() {
      return "(필수) 상품, 가격, <a href='/payment' class='button-link' target='_blank'>결제 및 환불 관련 정책</a> 등을 확인하였으며 구매에 동의합니다. ";
    },
    amount: function() {
      return this.selectedPlan.price;
    },
    totalAmount: function() {
      return this.selectedPlan.price;
    },
    discountInfo: function() {
      const coupon = this.paymentInfo.coupon;
      if (coupon == null || coupon.coupon_type.discount_type == 'terms') {
        return '';
      }

      if (coupon.coupon_type.discount_type == 'price') {
        return `(-$${this.selectedPlan.price - coupon.coupon_type.discount_rate})`;
      }

      const price = this.selectedPlan.price * ((100 - coupon.coupon_type.discount_rate) / 100);
      return `(-$${price})`;
    }
  },
  methods: {
    prepareSecret: function(initClientSecret) {
      const secret = initClientSecret;
      return secret;
    },
    checkValid: function() {
      this.$v.$reset();
      this.$v.$touch();
    },
    planSelected: function(type, id) {
      this.selectedType = type;
      this.selectedPlan = this.plans.find((plan) => {
        return plan.id == id;
      });
    },
    checkPromotion: function() {
      if (this.acceptCoupon == true) {
        return;
      }

      const curDom = this;
      axios.get(`/coupons?code=${this.couponCode}`).then(res => {
        curDom.paymentInfo.coupon = res.data;
        curDom.acceptCoupon = true;
        curDom.checkValid();
      }).catch(function(err){
        curDom.paymentInfo.coupon = null;
      });
    },
    subscribe: function() {
      var stripe = MiilkStripeElement.stripe;
      var card = MiilkStripeElement.card;
      const curDom = this;

      this.isLoading = true;
      stripe.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: {
              card: card,
              billing_details: {
                name: this.paymentInfo.name,
              },
            },
          }
      ).then(function(result) {
        if (result.error) {
          curDom.paymentInfo.isValidCard = false;
          curDom.isLoading = false;
          curDom.updateClientSecret();
        } else {
          curDom.payment(result.setupIntent.payment_method);
        }
      });
    },
    payment: function (paymentId) {
      const data = {
        payment_id: paymentId,
        plan_id: this.selectedPlan.id,
        coupon_id: this.paymentInfo.coupon == null ? null : this.paymentInfo.coupon.id,
      };

      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "begin_purchase",
        ecommerce: {
          items: [
            {
              item_id: data.plan_id,
              item_name: (data.plan_id==1) ? 'monthly': 'yearly',
              item_category: 'Subscription',
              currency: "USD",
              price: data.total_amount,
              quantity: 1,
            }
          ]
        }
      });

      const formData = common.objectToFormData(data);
      axios.post("/orders/payment", formData).then(res => {
        try {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ecommerce: null});
          dataLayer.push({
            'event': 'purchase',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': res.data.order.id,
                  'revenue': data.total_amount,
                },
                'products': [{
                  'name': (data.plan_id == 1) ? 'monthly' : 'yearly',
                  'id': data.plan_id,
                  'price': data.total_amount,
                  'category': 'Subscription',
                  'quantity': 1
                }]
              },
            }
          });

          heap.track('Purchase', {
            order_id: res.data.order.id,
            product_id: data.plan_id,
            product_name: (data.plan_id==1) ? 'monthly': 'yearly',
            product_amount:  data.total_amount,
            product_category: 'Subscription',
            order_quntity: 1,
            order_currency: 'USD'
          });          
         
          fbq('track', 'Purchase', {currency: "USD", value: data.total_amount, content_type: data.plan_id});
        } catch(e) {
        }

        location.href = "/orders/complete";
      }).catch(this.paymentError);
    },
    paymentError(error) {
      console.error(error);
      this.isLoading = false;
      this.paymentInfo.isValidCard = false;
      this.updateClientSecret();

      // TODO error valid
    },
    validCheckBox: function(checked) {
      if (checked) {
        this.paymentInfo.paymentTerm = true;
      } else {
        this.paymentInfo.paymentTerm = null;
      }
    },
    cardNumberChange: function(event) {
      if (event != null && event.error != null) {
        this.paymentInfo.isValidCard = false;
      }
    },
    cardError: function(event) {
      this.paymentInfo.isValidCard = false;
      // this.$v.$reset();
      this.$v.$touch();
    },
    setCardValid: function(event) {
      this.paymentInfo.isValidCard = true;
    },
    updateClientSecret: function() {
      axios.get("/users/client_secret").then(res => {
        this.clientSecret = res.data.client_secret;
      });
    },
  },
  validations: {
    paymentInfo: {
      cardInfo: {cardValidation, cvcValid},
      name: {requiredName},
      promotionCode: {validPromotionCode},
      paymentTerm: {required}
    }
  },
  components: {
    PlanCard
  }
}
</script>
