<template>
  <div class="button-wrapper notification-btn" v-bind:class="{ 'active': isActive }" v-on:click="readAll">
    <div class="button-notification remove-line-height">
      <img src="https://assets.themiilk.com/common/icon-notification.svg" class="icon-notification"/>
      <div class="count" v-if="notification_count !== 0">{{notification_count}}</div><!-- 메세지가 없을경우 none class 추가. -->
    </div>

    <div class="notifications-wrapper">
      <div class="notifications-head" :class="{'empty': notification_count + read_notification_count === 0}">
        <div class="count-label">알림 {{notification_count + read_notification_count}}</div>
        <button class="button underline button-remove-messege notification-remove-all" v-if="notification_count !== 0 || read_notification_count !== 0" v-on:click="removeAll">
          모두삭제
        </button>
      </div>

      <ul class="notification-list">
        <!-- 새로운 알림 -->

        <!-- 읽은 알림 -->
        <li v-for="notification in read_notifications" v-bind:key="notification" class="notification">
          <div class="date">{{formatDate(notification.created_at)}}</div>
          <div class="point">{{notification.title}}</div>
          <div class="description">
            <span v-html="notiMessage(notification.message)"></span>
          </div>
          <a v-if="notification.url" :href="notification.url" target="_blank" class="button-custom button-box tiny">보러가기</a>
          <a class="button-remove notification-remove" href="#" v-on:click="remove(notification.id)">
            <img src="https://assets.themiilk.com/common/icon-remove.svg" class="icon-remove"/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {},
    data () {
      return {
        notifications: [],
        read_notifications: [],
        notification_count: 0,
        read_notification_count: 0,
        active: false,
        isActive: false,
        isLoad: false
      }
    },
    mounted() {
      this.getNotifications();
    },
    computed: {},
    methods: {
      getNotifications() {
        axios.get(`/notifications`).then(res => {
          this.notifications = res.data.notifications;
          this.read_notifications = res.data.read_notifications;
          this.notification_count = res.data.notification_count;
          this.isLoad = true;
          this.active = res.data.notification_count == 0;
          this.read_notification_count = res.data.read_notifications.length;
        })
      },
      notiMessage(message) {
        return message.split('\n').join('<br />')
      },
      readAll() {
        if (!this.active) {
          axios.post(`/notifications/read_all`).then(_ => {
            this.getNotifications()
          })
        }
        this.isActive = !this.isActive
      },
      remove(id) {
        axios.get(`/notifications/${id}/remove`).then(_ => {
          this.getNotifications()
        })
      },
      removeAll() {
        axios.get(`/notifications/remove_all`).then(_ => {
          this.getNotifications()
        })
      },
      leftPad(value) {
        if (value >= 10) {
          return value;
        }

        return `0${value}`;
      },
      formatDate(created_at) {
        try {
          let date = new Date(created_at);
          
          var now = new Date();
          let msec = now - date;

          let sec = msec / 1000;
          if (sec < 60) {
            return `${Math.floor(sec)}초 전`
          } 
          let min = msec / 1000 / 60;
          if (min < 60) {
            return `${Math.floor(min)}분 전`
          }
          let hour = msec / 1000 / 60 / 60;
          if (hour < 24) {
            return `${Math.floor(hour)}시간 전`
          }

          const year = date.getFullYear();
          const month = this.leftPad(date.getMonth() + 1);
          const day = this.leftPad(date.getDate());

          return [year, month, day].join(".");
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    }
  }
</script>
