var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail" }, [
    _c(
      "div",
      { staticClass: "table-container" },
      [
        _c("h2", { staticClass: "label-middle-title" }, [
          _vm._v("구독 결제 내역")
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.subscribeOrders, function(order) {
          return _c("a", { key: order.id, staticClass: "row no-deco" }, [
            _c("div", { staticClass: "col date" }, [
              _vm._v("\n        " + _vm._s(order.created_at_str) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col name" }, [
              _c("span", [_vm._v(_vm._s(_vm.termsName(order)))]),
              _vm._v(" "),
              _c("span", { staticClass: "pc" }, [
                _vm._v(_vm._s(_vm.period(order)))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col amount" }, [
              _vm._v("\n        $" + _vm._s(order.price) + "\n      ")
            ])
          ])
        }),
        _vm._v(" "),
        _vm.subscribeOrders.length == 0
          ? _c("div", { staticClass: "row blank" }, [
              _vm._v("\n      결제 내역이 없습니다.\n    ")
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-container" },
      [
        _c("h2", { staticClass: "label-middle-title" }, [_vm._v("쿠폰 내역")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._l(_vm.coupons, function(coupon) {
          return _c("a", { key: coupon.id, staticClass: "row no-deco" }, [
            _c("div", { staticClass: "col date" }, [
              _vm._v("\n        " + _vm._s(coupon.created_at_str) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col name" }, [
              _c("span", [_vm._v(_vm._s(coupon.coupon_type.name))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col expired" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("intToDate")(coupon.expire_date_i)) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col use" }, [
              _vm._v("\n        " + _vm._s(coupon.used_at_str) + "\n      ")
            ])
          ])
        }),
        _vm._v(" "),
        _vm.coupons.length == 0
          ? _c("div", { staticClass: "row blank" }, [
              _vm._v("\n      쿠폰 사용 내역이 없습니다.\n    ")
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row top" }, [
      _c("div", { staticClass: "col date" }, [_vm._v("결제 일시")]),
      _vm._v(" "),
      _c("div", { staticClass: "col name" }, [_vm._v("구독플랜")]),
      _vm._v(" "),
      _c("div", { staticClass: "col amount" }, [_vm._v("구매금액")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row top" }, [
      _c("div", { staticClass: "col date" }, [_vm._v("발급일")]),
      _vm._v(" "),
      _c("div", { staticClass: "col name" }, [_vm._v("쿠폰명")]),
      _vm._v(" "),
      _c("div", { staticClass: "col expired" }, [_vm._v("사용기간")]),
      _vm._v(" "),
      _c("div", { staticClass: "col use" }, [_vm._v("사용일")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-container" }, [
      _c("p", { staticClass: "t" }, [
        _vm._v("\n      쿠폰 이용 방법 및 유의사항\n    ")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "d" }, [
        _c("li", [
          _vm._v("\n        쿠폰 사용 시 결제 정보가 필요합니다.\n      ")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "\n        등록한 결제 정보로 구독기간 종료일에 다음 회차 구독료가 결제됩니다.\n      "
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "\n        월간/연간플랜 구독 중 적용이 불가하며, 다음 정기 결제 시 자동으로 쿠폰이 사용됩니다. (사용기한 임박한 쿠폰부터 사용)\n      "
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "\n        현재 구독 중인 플랜 변경 시 이용 가능합니다. [마이페이지 > 멤버십 > 플랜 변경하기]\n      "
          )
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("\n        쿠폰은 중복 사용이 불가합니다.\n      ")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "\n        쿠폰 사용 후 구독 취소 시 쿠폰은 복구되지 않습니다.\n      "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }