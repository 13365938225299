var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "signup-container padding tiny" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-detail" },
        [
          _c(
            "div",
            { staticClass: "login-form social" },
            [
              _c("social-button", {
                attrs: { "is-signup": true },
                on: { snsClicked: _vm.snsClick }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "signup-form" },
            [
              _c("text-input", {
                attrs: {
                  v: _vm.$v.user.login_user_id,
                  label: "이메일",
                  placeholder: "이메일을 입력하세요.",
                  required: true
                },
                model: {
                  value: _vm.user.login_user_id,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.user,
                      "login_user_id",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "user.login_user_id"
                }
              }),
              _vm._v(" "),
              _c("text-input", {
                attrs: {
                  v: _vm.$v.user.password,
                  label: "비밀번호",
                  placeholder: "비밀번호를 입력하세요.",
                  type: "password",
                  required: true,
                  description: "영문, 숫자 조합 8자리 이상"
                },
                model: {
                  value: _vm.user.password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.user,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "user.password"
                }
              }),
              _vm._v(" "),
              _c("text-input", {
                attrs: {
                  v: _vm.$v.user.password_confirm,
                  label: "비밀번호 확인",
                  placeholder: "비밀번호를 입력하세요.",
                  type: "password",
                  required: true
                },
                on: { input: _vm.checkValid },
                model: {
                  value: _vm.user.password_confirm,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.user,
                      "password_confirm",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "user.password_confirm"
                }
              }),
              _vm._v(" "),
              _c("text-input", {
                attrs: {
                  v: _vm.$v.user.name,
                  label: "이름",
                  placeholder: "이름을 입력하세요.",
                  required: true
                },
                on: { input: _vm.checkValid },
                model: {
                  value: _vm.user.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.user,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "user.name"
                }
              }),
              _vm._v(" "),
              _c("checkbox-input", {
                attrs: {
                  v: _vm.$v.user.terms,
                  label: _vm.termsLabel,
                  val: "true",
                  pk: "terms"
                },
                on: { input: _vm.validCheckBox },
                model: {
                  value: _vm.user.terms,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "terms", $$v)
                  },
                  expression: "user.terms"
                }
              }),
              _vm._v(" "),
              _c("miilk-button", {
                attrs: {
                  v: _vm.$v,
                  name: "이메일로 가입",
                  isDisableStart: true
                },
                on: { clicked: _vm.signup }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm.signupError
            ? _c("layer-popup", {
                attrs: { title: _vm.popTitle, content: _vm.popContent },
                on: { "close-layer-popup": _vm.closePop }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-head" }, [
      _c("p", { staticClass: "label-xsmall bold step" }, [
        _vm._v("1 / 2 단계")
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "label-title" }, [_vm._v("회원가입")]),
      _vm._v(" "),
      _c("div", { staticClass: "message-box" }, [
        _c("div", { staticClass: "message-box-wrapper" }, [
          _c("h3", { staticClass: "box-title" }, [
            _c("span", { staticClass: "label normal" }, [
              _vm._v("\n              회원가입 후 뷰스레터를 "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "point" }, [_vm._v("주 3회 무료")]),
              _vm._v("로 받아보세요.\n            ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "icon-hi remove-line-height" }, [
              _c("img", {
                attrs: {
                  src: "https://assets.themiilk.com/commons/icon-hi.svg",
                  alt: ""
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "box-description" }, [
            _vm._v(
              "\n            가치있는 정보를 신선하게 배달하는 메일링 서비스 뷰스레터. 단순 뉴스 서비스가 아닌 세상과 산업의 종합적인 관점(Viewpoints)을 전달드립니다. 뷰스레터는 실리콘밸리 스타트업 이야기를 묶은 '스타트업 포커스'를 포함하여 주 3회(월, 수, 금) 보내드립니다.\n          "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "splitter" }, [
      _c("hr", { staticClass: "line" }),
      _vm._v(" "),
      _c("span", { staticClass: "label label-small" }, [
        _vm._v("또는 이메일로 회원가입")
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("p", [
        _vm._v("이미 더밀크 계정이 있으신가요? "),
        _c("a", { staticClass: "button-link", attrs: { href: "/login" } }, [
          _vm._v("로그인")
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("기업회원이신가요? "),
        _c("a", { staticClass: "button-link", attrs: { href: "/business" } }, [
          _vm._v("기업회원 상품보러 가기")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }