var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pop-wrapper email" }, [
    _c("div", { staticClass: "dimBg" }),
    _vm._v(" "),
    _c("div", { staticClass: "pop-layer", attrs: { id: "layer" } }, [
      _c("div", { staticClass: "pop-container" }, [
        _c("div", { staticClass: "pop-conts" }, [
          _c("div", { staticClass: "pop-head clearfix" }, [
            _c("div", { staticClass: "title" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "close-btn btn", on: { click: _vm.closeModal } },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pop-content" }, [
      _c("p", [
        _vm._v(
          "\n            원활한 서비스 이용을 위해 다른 브라우저(크롬, 사파리 등)을 이용해주세요.\n            "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }