var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-container" }, [
    _c("div", { staticClass: "dialog-background" }),
    _vm._v(" "),
    _c("div", { staticClass: "dialog-wrapper" }, [
      _c("div", { staticClass: "dialog-box" }, [
        _c("div", { staticClass: "dialog-content" }, [
          _c("div", {
            staticClass: "content-wrapper label-middle-description",
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dialog-button" }, [
          _c(
            "div",
            {
              staticClass: "button button-box confirm-btn",
              on: { click: _vm.closePop }
            },
            [_vm._v("확인")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }