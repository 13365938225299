<template>
  <div class="plan-container padding tiny">
    <div class="content">
      <div class="content-detail">
        <div class="payment payment-container">
          <div class="payment-wrapper">
            <h2 class="label-small-title payment-title">
              <span>구매 정보</span>
              <hr />
            </h2>

            <div class="amount">
              <div class="row">
                <div class="key">구매상품</div>
                <div class="val" v-if="initEvent">{{ initEvent.title }}</div>
                <div class="val" v-else><span v-html="initReport.title" /></div>
              </div>

              <div class="row">
                <div class="key">결제금액</div>
                <div class="val">${{ price }}</div>
              </div>

              <div class="row total">
                <div class="key">최종 결제금액</div>
                <div class="val bold">${{ price }}</div>
              </div>
            </div>
          </div>
          <div class="payment-wrapper">
            <h2 class="label-small-title payment-title">
              <span>결제 정보 입력</span>
              <hr />
            </h2>

            <card-input v-model="paymentInfo.cardInfo" :v="$v.paymentInfo.cardInfo" label="카드번호" placeholder="카드번호를 입력하세요." :required="true" :clientSecret="clientSecret" @card-change="cardNumberChange" @card-error="cardError" @card-valid="setCardValid" />
            <text-input v-model="paymentInfo.name" :v="$v.paymentInfo.name" label="이름" placeholder="카드에 적힌 이름을 입력하세요." :required=true @input="checkValid" />
            <text-input v-if="false" v-model="paymentInfo.promotionCode" :v="$v.paymentInfo.promotionCode" label="프로모션 코드" placeholder="프로모션 코드를 입력하세요." :required=false @input="checkPromotion" />

            <checkbox-input v-model="paymentInfo.paymentTerm" :v="$v.paymentInfo.paymentTerm" :label="termsLabel" val="true" pk="terms" @input="validCheckBox"></checkbox-input>
            <miilk-button :v="$v" name="결제하기" class="primary" :isLoading="isLoading" loading-text="결제 처리중..." :isDisableStart=true @clicked="subscribe" />
          </div>
        </div>
      </div>
    </div>

    <layer-popup v-if="paymentErrorPop" :title="errorTitle" :content="errorContent" @close-layer-popup="errorPopClose"/>
    <toast v-if="completePop" :title="paymentTitle" :content="paymentContent" @canceled="closeCompletePop"/>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { required, maxLength } from 'vuelidate/lib/validators';
  import { validPromotionCode, cardValidation, cvcValid, requiredName } from '../commons/validators';
  import Antd from 'ant-design-vue';
  import 'ant-design-vue/dist/antd.css';
  Vue.use(Antd);

  import Toast from "./toast";

  export default {
    props: {
      initClientSecret: {
        type: String
      },
      price: {
        type: Number
      },
      initEvent: {
        type: Object
      },
      initReport: {
        type: Object
      }
    },
    data() {
      return {
        isLoading: false,
        paymentErrorPop: false,
        errorTitle: '',
        errorContent: '',
        paymentInfo: {
          cardInfo: null,
          isValidCard: false,
          isValidCvc: true,
          cardInfoError: null,
          name: null,
          promotionCode: null,
          discount: 0,
          paymentTerm: null,
          promotionId: null,
          totalAmount: 0
        },
        clientSecret: this.prepareSecret(this.initClientSecret),
        completePop: false,
      }
    },
    created() {
    },
    mounted() {
      const type = this.type;
      const product_name = this.productName;
      const productId = this.initEvent ? this.initEvent.id : this.initReport.id;

      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id: productId,
              item_name: product_name,
              item_category: type ? "report" : "event",
              currency: "USD",
              price: this.price,
              quantity: 1,
            }
          ]
        }
      });
    },
    computed: {
      termsLabel: function() {
        return "(필수) 상품, 가격, <a href='/payment' class='link-a' target='_blank'>결제 및 환불 관련 정책</a> 등을 확인하였으며 구매에 동의합니다. ";
      },
      paymentTitle: function() {
        if (this.initEvent) {
          return '결제 완료'
        } else {
          return '리포트 결제 완료'
        }
      },
      paymentContent: function() {
        if (this.initEvent) {
          return `결제가 완료되었습니다.<br/>신청해주셔서 감사합니다.`
        } else {
          return `결제가 완료되었습니다.<br/>지금 바로 리포트를 다운로드 받아보세요!`
        }
      },
      type: function() {
        if (this.initReport) {
          return 'report';
        } else if (this.initEvent) {
          return 'event';
        }

        return null;
      },
      productName: function() {
        let productId = this.initEvent ? this.initEvent.id : this.initReport.id;
        productId = String(productId).padStart(4, '0');

        return this.type ? `r${productId}` : `e${productId}`;
      }
    },
    methods: {
      prepareSecret: function(initClientSecret) {
        const secret = initClientSecret;
        return secret;
      },
      checkValid: function() {
        this.$v.$reset();
        this.$v.$touch();
      },
      subscribe: function() {
        var stripe = MiilkStripeElement.stripe;
        var card = MiilkStripeElement.card;
        const curDom = this;

        this.isLoading = true;
        stripe.confirmCardSetup(
            this.clientSecret,
            {
              payment_method: {
                card: card,
                billing_details: {
                  name: this.paymentInfo.name,
                },
              },
            }
        ).then(function(result) {
          if (result.error) {
            // TODO :: card element를 refresh 해야함.
            // var displayError = document.getElementById('card-errors');
            // displayError.textContent = result.error.message;
            curDom.paymentInfo.isValidCard = false;
            curDom.isLoading = false;
            curDom.updateClientSecret();
          } else {
            curDom.payment(result.setupIntent.payment_method);
          }
        });
      },
      payment: function (paymentId) {
        const type = this.type;
        const product_name = this.productName;
        const productId = this.initEvent ? this.initEvent.id : this.initReport.id;

        const data = {
          id: productId,
          name: product_name,
          category: type ? 'Report' : 'Event',
          price: this.price,
          payment_id: paymentId,
          type: type
        };

        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
          event: "begin_purchase",
          ecommerce: {
            items: [
              {
                item_id: data.id,
                item_name: data.name,
                item_category: data.category,
                currency: "USD",
                price: data.price,
                quantity: 1,
              }
            ]
          }
        });

        const formData = common.objectToFormData(data);
        axios.post("/porders", formData).then(res => {
          if (window.fbq) {
            fbq("track", "Purchase", {
              currency: "USD",
              value: data.price,
              content_type: data.name,
            });
          }
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
            event: "purchase",
            ecommerce: {
              purchase: {
                actionField: {
                  id: res.data.id,
                  revenue: data.price,
                },
                products: [
                  {
                    name: data.name,
                    id: data.id,
                    price: data.price,
                    category: data.category,
                    quantity: 1,
                  },
                ],
              },
            },
          }); 

          heap.track('Purchase', {
            order_id: res.data.id,
            product_id: data.plan_id,
            product_name: data.name,
            product_amount:  data.price,
            product_category: data.category,
            order_quntity: 1,
            order_currency: 'USD'
          });          

          this.completePop = true;
        }).catch(this.paymentError);
      },
      paymentError(error) {
        if (error.response.data.errorCode == 20005) {
          this.errorTitle = '';
          this.errorContent = error.response.data.message;
          this.paymentErrorPop = true;
          return;
        }
        this.isLoading = false;
        this.paymentInfo.isValidCard = false;
        this.updateClientSecret();

        // TODO error valid
      },
      validCheckBox: function(checked) {
        if (checked) {
          this.paymentInfo.paymentTerm = true;
        } else {
          this.paymentInfo.paymentTerm = null;
        }
      },
      cardNumberChange: function(event) {
        if (event != null && event.error != null) {
          this.paymentInfo.isValidCard = false;
        }
      },
      cardError: function(event) {
        this.paymentInfo.isValidCard = false;
        // this.$v.$reset();
        this.$v.$touch();
      },
      setCardValid: function(event) {
        this.paymentInfo.isValidCard = true;
      },
      updateClientSecret: function() {
        axios.get("/users/client_secret").then(res => {
          this.clientSecret = res.data.client_secret;
        });
      },
      errorPopClose: () => {
        this.paymentErrorPop = false;
        location.href = `/events/${this.initEvent.id}`;
      },
      closeCompletePop () {
        this.completePop = false;
        const productId = this.initEvent ? this.initEvent.id : this.initReport.id;
        if (this.initEvent) {
          location.href = `/events/${productId}`;
        } else {
          location.href = `/reports/${productId}`;
        }
      }
    },
    validations: {
      paymentInfo: {
        cardInfo: {cardValidation, cvcValid},
        name: {requiredName},
        paymentTerm: {required}
      }
    },
    components: {
      Toast
    }
  }
</script>
