<template>
  <li class="row">
    <div class="col remove-line-height checkbox">
      <input type="checkbox" class="form-control" v-model="checkedList" :value="member.id" v-on:click="itemSelected"/>
    </div>
    <div class="col name">
      {{member.user.name}}
    </div>
    <div class="col email">
      {{member.user.email}}
    </div>
    <div class="col department">
      {{member.department}}
    </div>
    <div class="col management remove">
      <button class="button button-bordered tiny" v-on:click="delMember">삭제</button>
    </div>
  </li>
</template>
<script>
  export default {
    props: {
      member: {
        type: Object,
        default: {}
      },
      checkedList: {
        type: Array,
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    components: {
    },
    methods: {
      itemSelected: function() {
        this.$emit('selected', this.member.id);
      },
      delMember: function() {
        this.$emit('deleted', this.member.id);
      }
    },

  }
</script>