var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-detail" },
    [
      _c(
        "div",
        { staticClass: "table-container bookmark" },
        [
          _c("h2", { staticClass: "label-middle-title" }, [
            _vm._v("북마크 한 기사 "),
            _c(
              "span",
              {
                staticClass: "number",
                class: { point: _vm.bookmarks.length > 0 }
              },
              [_vm._v(_vm._s(_vm.bookmarks.length))]
            ),
            _vm._v("개")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row top" }, [
            _c("div", { staticClass: "col select" }, [
              _c("label", { attrs: { for: "all" } }, [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.all,
                        expression: "all"
                      }
                    ],
                    staticClass: "select-all",
                    attrs: { id: "all", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.all)
                        ? _vm._i(_vm.all, null) > -1
                        : _vm.all
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.all,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.all = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.all = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.all = $$c
                          }
                        },
                        _vm.allChecked
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [_vm._v("전체선택")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col option" }, [
              _c(
                "button",
                {
                  staticClass: "button button-box tiny button-remove-selected",
                  class: { disabled: _vm.checkedList.length == 0 },
                  on: { click: _vm.destroyAll }
                },
                [_vm._v("선택 삭제하기")]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.bookmarks, function(bookmark) {
            return _c(
              "label",
              { key: bookmark.id, staticClass: "row no-deco" },
              [
                _c("div", { staticClass: "col select" }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkedList,
                          expression: "checkedList"
                        }
                      ],
                      staticClass: "select",
                      attrs: { name: "checked-bookmark", type: "checkbox" },
                      domProps: {
                        value: bookmark.id,
                        checked: Array.isArray(_vm.checkedList)
                          ? _vm._i(_vm.checkedList, bookmark.id) > -1
                          : _vm.checkedList
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.checkedList,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = bookmark.id,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.checkedList = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.checkedList = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.checkedList = $$c
                            }
                          },
                          _vm.changeUnit
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "box" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col infor" }, [
                  _c(
                    "a",
                    {
                      staticClass: "title no-deco",
                      attrs: { href: _vm.linkUrl(bookmark) }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(bookmark.article.title) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "author" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(bookmark.article.author))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "date" }, [
                      _vm._v(_vm._s(bookmark.article.str_published_at))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col option" })
              ]
            )
          }),
          _vm._v(" "),
          _vm.bookmarks.length == 0
            ? _c("div", { staticClass: "row blank" }, [
                _vm._v("\n        등록된 북마크가 없습니다.\n      ")
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.showConfirm
        ? _c("layer-confirm-popup", {
            attrs: { content: _vm.popContent },
            on: { "close-layer-popup": _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }