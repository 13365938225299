<template>
  <div class="content-detail">
    <div class="table-container">
      <h2 class="label-middle-title">구매 내역</h2>

      <div class="row top">
        <div class="col date">구매일시</div>
        <div class="col type">유형</div>
        <div class="col name">상품명</div>
        <div class="col amount">구매금액</div>
      </div>

      <a class="row no-deco" v-for="order in orders" :key="order.id" :href="linkUrl(order)">
        <div class="col date">
          {{ order.payment_date }}
        </div>
        <div class="col type">
          {{ orderType(order.orderable_type) }}
        </div>
        <div class="col name">
          <span v-html="order.title" />
        </div>
        <div class="col amount">
          ${{ order.amount }}
        </div>
      </a>

      <div class="row blank" v-if="orders.length == 0">
        결제 내역이 없습니다.
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: {
      orders: {
        type: Array,
        default: [],
      }
    },
    data() {
      return {};
    },
    computed: {
    },
    methods: {
      orderType: function(typeString) {
        if (typeString === 'Event') return '이벤트';
        else if (typeString === 'Report') return '리포트';
      },
      linkUrl: function(order) {
        const typeString = order.orderable_type;
        if (typeString === 'Event') return  `/events/${order.orderable_id};`;
        else if (typeString === 'Report') return  `/reports/${order.orderable_id};`;
      }
    }
  }
</script>