<template>
  <div class="list-wrapper">
    <ul class="list news" v-if="articles.length > 0">
      <li class="item" v-for="article in articles">
        <article class="item-wrapper investing-item">
          <div class="image-wrapper remove-line-height">
            <a :href="articleUrl(article)" @click="clickArticle">
              <div class="image-box" :style="heroImageUrl(article)">
                <img class='blank-image' src="https://assets.themiilk.com/common/blank-6-4.png" alt='' />
              </div>
            </a>
          </div>

          <div class="text-wrapper">
            <ul class="tags" v-if="article.tags.length > 0">
              <li class="tag" v-for="tag in article.tags">
                <a :href="tagUrl(tag)">
                  {{ tag.name }}
                </a>
              </li>
            </ul>

            <a :href="articleUrl(article)" @click="clickArticle">
              <h2 class="title label-middle-title bold ellipsis one">
                {{ article.title }}
              </h2>
            </a>

            <a class="no-deco" :href="articleUrl(article)" v-if="article.first_text" @click="clickArticle">
              <p class="description label-small-description ellipsis three">
                {{ stripTag(article.first_text) }}
              </p>
            </a>

            <p class="author label-xsmall">
              <!-- <span class="bold">{{ article.author_names }}</span> -->
              <author-name :article="article" />
              <span class="date">
                {{ article.str_published_at }}
              </span>
            </p>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
  import AuthorName from "../commons/components/author_name.vue";
  export default {
    props: ['articles'],
    data() {
      return {}
    },
    mounted() {},
    methods: {
      heroImageUrl: function(article) {
        const imageUrl = "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/";
        return `background-size: cover; background-image: url('${imageUrl}${article.hero_image_url}')`;
      },
      articleUrl: function(article) {
        return `/articles/${article.nickname}`;
      },
      clickArticle: function(article) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_article',
          'article_id': article.id,
          'article_title': article.title,
          'article_author': article.author,
          'article_published_at': article.published_at
        })
      },
      isFree: function(article) {
        if (article.free_start_at == null || article.free_end_at == null) {
          return false;
        }

        var date = new Date();
        var startAt = new Date(article.free_start_at);
        var endAt = new Date(article.free_end_at);

        return ((startAt <= date) && (endAt > date));
      },
      stripTag: function(str) {
        var div = document.createElement("div");
        div.innerHTML = str;
        var text = div.textContent || div.innerText || "";
        return text;
      },
      tagUrl: function(tag) {
        return `/tags/${tag.id}`;
      }
    },
    components: {
      AuthorName
    }
  }
</script>