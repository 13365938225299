var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password-container" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("p", { staticClass: "title" }, [_vm._v("비밀번호 찾기")]),
          _vm._v(" "),
          _c("p", { staticClass: "sub-title" }, [
            _vm._v("입력하신 이메일로 비밀번호 재설정 링크를 보내드립니다.")
          ]),
          _vm._v(" "),
          _c("text-input", {
            attrs: {
              v: _vm.$v.email,
              label: "이메일",
              placeholder: "이메일을 입력하세요.",
              required: true
            },
            on: { enter: _vm.requestSend },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          _vm._v(" "),
          _c("miilk-button", {
            staticClass: "primary",
            attrs: {
              v: _vm.$v,
              name: "비밀번호 재설정 링크 보내기",
              isDisableStart: true
            },
            on: { clicked: _vm.requestSend }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.serverError
        ? _c("layer-popup", {
            attrs: { title: _vm.popTitle, content: _vm.popContent },
            on: { "close-layer-popup": _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }