<template>
  <section class="event-detail">
    <div class="content single">
      <article class="event-detail-item">
        <div class="event-detail-item-head">
          <div class="image-wrapper remove-line-height hero-image">
            <div class="image-box" :style="backgroundImageUrl">
              <img src='https://assets.themiilk.com/common/blank.png' class="blank-image" />
            </div>
          </div>
        </div>

        <div class="event-detail-item-content">
          <div class="content-head">
            <h1 class="label-sub-title bold">
              {{event.title}}
            </h1>
          </div>

          <div class="sticky-content-container" v-if="event.event_type == 'general'">
            <div class="sticky-content-wrapper">
              <p class="label-middle-description event-content">
                {{ event.description }}
              </p>

              <p class="label-description" v-if="isRegistered && showParticipatoryLink && (event.participatory_link != null)">
                참여링크: <a :href="event.participatory_link" class="link-a">{{event.participatory_link}}</a>
              </p>
            </div>

            <div class="sticky-content-side">
              <div class="info-container">
                <miilk-button v-if="isFinished" :name="finishedBtnName" :isDisableStart=true></miilk-button>
                <miilk-button v-if="!isFinished && isRegistered" name="신청완료" :isDisableStart=true></miilk-button>

                <div v-if="user == null">
                  <miilk-button v-if="!isFinished && !isRegistered" name="참가신청" :isDisableStart=true></miilk-button>
                  <p class="signup" v-if="!isFinished">해당 이벤트 참가신청을 위해서 더밀크에 <a href="/signup" class="link-a">가입하러 가기</a></p>
                </div>
<!--                <div v-else-if="user != null && !isSubscribe && (currentUserPrice == 0)">-->
<!--                  <miilk-button v-if="!isFinished && !isRegistered" name="참가신청" :isDisableStart=true></miilk-button>-->
<!--                  <p class="signup" v-if="!isFinished">해당 이벤트 참가신청을 위해 <a href="/orders/plans" class="link-a">구독하러 가기</a></p>-->
<!--                </div>-->
                <div v-else>
                  <miilk-button :v="$v" v-if="!isFinished && !isRegistered" name="참가신청" @clicked="register" :isLoading="isLoading"></miilk-button>
                </div>

                <div class="info-wrapper">
                  <div class="info" v-if="pdtTime || kstTime">
                    <p class="label-middle-description bold key">일시</p>
                    <p class="label-small-description val" v-if="pdtTime">미국 : {{ pdtTime }}</p>
                    <p class="label-small-description val" v-if="kstTime">한국 : {{ kstTime }}</p>
                  </div>

                  <div class="info" v-if="event.location">
                    <p class="label-middle-description bold key">장소</p>
                    <p class="label-small-description val">{{ event.location }}</p>
                  </div>

                  <div class="info-box" v-if="event.prices.normal || event.prices.monthly || event.prices.yearly">
                    <p class="label-middle-description bold key">가격</p>
                    <p class="label-small-description val" v-if="event.prices.normal">일반 사용자: ${{event.prices.normal}}</p>
                    <p class="label-small-description val" v-if="event.prices.monthly">개인 플랜 구독자: ${{event.prices.monthly}}</p>
                    <p class="label-small-description val" v-if="event.prices.yearly">기업 플랜 구독자: ${{event.prices.yearly}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sticky-content-container" v-if="event.event_type == 'attachment'">
            <div class="sticky-content-wrapper">
              <p class="label-description event-content">
                {{ event.description }}
              </p>
            </div>

            <div class="sticky-content-side">
              <div class="info-container">
                <div class="button-wrapper" v-if="isRegistered">
                  <a :href="event.participatory_link" class="button button-box fix" target="_blank">
                    리포트 다운로드
                  </a>
                </div>

                <div class="button-wrapper" v-if="user == null">
                  <p class="signup" v-if="!isFinished">더밀크 <a :href="signupLink()" class="link-a">회원가입</a> 또는 <a :href="loginLink()" class="link-a">로그인</a></p>
                  <miilk-button v-if="!isFinished && !isRegistered" name="리포트 다운로드" class="primary" :isDisableStart=true></miilk-button>
                </div>
                <div class="button-wrapper" v-else>
                  <miilk-button :v="$v" v-if="!isFinished && !isRegistered" name="리포트 다운로드" class="primary" @clicked="register"></miilk-button>
                </div>

                <div class="info-container">
                  <div class="info-wrapper" v-if="event.prices.normal > 0">
                    <p class="label-small-description bold key">가격</p>
                    <p class="label-small-description val">일반 사용자: ${{event.prices.normal}}</p>
                    <p class="label-small-description val">월 결제 유료 구독자: ${{event.prices.monthly}}</p>
                    <p class="label-small-description val">연 결제 유료 구독자: ${{event.prices.yearly}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="event-replay" v-if="relatedVideos.length > 0">
            <h3 class="label-small bold section-title">
              이벤트 다시보기
            </h3>

            <div class="rvideo-wrapper" v-for="relatedVideo in relatedVideos">
              <div class="image-wrapper remove-line-height">
                <a :href="videoLink(relatedVideo)">
                  <img :src="relatedVideo.thumbnail_url" />
                </a>
              </div>

              <div class="text-wrapper">
                <a :href="videoLink(relatedVideo)">
                  <h2 class="labsl-sub-title title">
                    {{relatedVideo.title}}
                  </h2>
                </a>

                <div class="label-small-description" v-if="relatedVideo.description">
                  {{relatedVideo.description}}
                </div>

                <div class="statistics label-small">
                  <span>{{relatedVideo.str_published_at}}</span>
                </div>
              </div>
            </div>
          </div>

          <payment-popup v-if="paymentPop" :title="popTitle" :content="paymentMessage" @confirmed="payment" @canceled="cancelOrder"></payment-popup>
          <layer-popup v-if="paymentErrorPop" :title="errorTitle" :content="errorContent" @close-layer-popup="errorPopClose"/>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import {cardValidation, cvcValid, requiredName} from '../commons/validators';
import PaymentPopup from "./payment_popup";

export default {
  props: [
    'event', 'isRegistered', 'isFinished', 'canRegister', 'pdtTime', 'kstTime', 'user', 'isSubscribe', 'subscribe', 'hasCard', 'relatedVideos', 'showParticipatoryLink', 'currentPlan'
  ],
  data() {
    return {
      isLoading: false,
      paymentPop: false,
      paymentErrorPop: false,
      errorTitle: '',
      errorContent: '',
    }
  },
  computed: {
    finishedBtnName: function() {
      if (!this.canRegister) {
        return '신청 종료';
      }
      return '지난 이벤트';
    },
    backgroundImageUrl: function() {
      return `background-size: cover; background-image: url('${this.event.hero_image_url}')`;
    },
    popTitle: function() {
      if (this.event.event_type == 'general') {
        return '참가신청';
      } else if (this.event.event_type == 'attachment'){
        return '리포트 다운로드';
      } else {
        return '';
      }
    },
    paymentMessage: function() {
      let message;
      const price = this.currentPrice();
      if (price != 0) {
        if (this.hasCard) {
          message = `$${price} 금액을 결제하시겠습니까?<br/>(더밀크닷컴 서비스 구독 때 등록하셨던 카드로 바로 결제됩니다.)<br/><p style='font-size:12px;'>* 다른 카드로 결제를 원하시면 마이 페이지에서 결제 카드를 변경하신 후 진행해 주세요.</p>`;
        } else {
          message = `$${price} 금액을 결제하시겠습니까?<br/>(확인 버튼을 누르시면 카드 등록 및 결제 페이지로 이동합니다.)`;
        }
      } else if (this.event.event_type == 'attachment') {
        message = `파일을 다운로드 하시겠습니까?`;
      } else {
        message = `이벤트에 신청하시겠습니까?`;
      }
      return message;
    },
    currentUserPrice: function() {
      const userType = this.userType();
      return this.event.prices[userType];
    }
  },
  methods: {
    videoLink: function(video) {
      return `/videos/${video.id}`;
    },
    signupLink: function(){
      return  `/signup?url=${location.pathname}`
    },
    loginLink: function(){
      return  `/login?url=${location.pathname}`
    },
    register: function() {
      this.paymentPop = true;
      this.isLoading = true;
    },
    userType: function() {
      if (this.currentPlan != null) {
        if (this.currentPlan.plan_type == "general") {
          return "monthly";
        } else {
          return "yearly";
        }
      } else {
        return "normal";
      }
    },
    currentPrice: function() {
      const userType = this.userType();
      return this.event.prices[userType];
    },
    cancelOrder: function() {
      this.paymentPop = false;
      this.isLoading = false;
    },
    payment: function() {
      this.paymentPop = false;
      const userType = this.userType();
      const curDom = this;
      const price = this.currentPrice();

      if (userType != "normal") {
        if (price != 0) {
          if (this.hasCard == true) {
            const data = {
              id: this.event.id,
              price: price,
              type: 'event'
            };
            const formData = common.objectToFormData(data);
            axios.post("/porders", formData).then(res => {
              location.reload();
            }).catch(e => {
              if (e.response.data.errorCode == 20005) {
                curDom.errorTitle = '';
                curDom.errorContent = e.response.data.message;
                curDom.paymentErrorPop = true;
              } else {
                curDom.errorContent = `잠시후 이용해주세요(ErrorCode: ${e.response.data.errorCode})`;
                curDom.paymentErrorPop = true;
              }
            });
          } else {
            location.href = "/porders/" + this.event.id;
          }
        } else {
          const data = {
            event_id: this.event.id
          };
          const formData = common.objectToFormData(data);
          axios.post("/events/register", formData).then( res => {
            location.reload();
          }).catch();
        }
      } else {
        if (price > 0) {
          if (this.hasCard == true) {
            const data = {
              id: this.event.id,
              price: price,
              type: 'event'
            };
            const formData = common.objectToFormData(data);
            axios.post("/porders", formData).then(res => {
              location.reload();
            }).catch(e => {
              if (e.response.data.errorCode == 20005) {
                curDom.errorTitle = '';
                curDom.errorContent = e.response.data.message;
                curDom.paymentErrorPop = true;
              } else {
                curDom.errorContent = `잠시후 이용해주세요(ErrorCode: ${e.response.data.errorCode})`;
                curDom.paymentErrorPop = true;
              }
            });
          } else {
            location.href = "/porders/" + this.event.id;
          }
        } else if (this.event.event_type == 'attachment') {
          const data = {
            event_id: this.event.id
          };
          const formData = common.objectToFormData(data);
          axios.post("/events/register", formData).then( res => {
          }).catch();
          window.open(this.event.participatory_link);
        } else {
          const data = {
            event_id: this.event.id
          };
          const formData = common.objectToFormData(data);
          axios.post("/events/register", formData).then( res => {
            location.reload();
          }).catch();
        }
      }
    },
    errorPopClose: () => {
      this.isLoading = false;
      this.paymentErrorPop = false;
    },
  },
  components: {
    PaymentPopup
  },
  validations: {
  }
}
</script>