var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "event-detail" }, [
    _c("div", { staticClass: "content single" }, [
      _c("article", { staticClass: "event-detail-item" }, [
        _c("div", { staticClass: "event-detail-item-head" }, [
          _c(
            "div",
            { staticClass: "image-wrapper remove-line-height hero-image" },
            [
              _c(
                "div",
                { staticClass: "image-box", style: _vm.backgroundImageUrl },
                [
                  _c("img", {
                    staticClass: "blank-image",
                    attrs: {
                      src: "https://assets.themiilk.com/common/blank.png"
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-detail-item-content" },
          [
            _c("div", { staticClass: "content-head" }, [
              _c("h1", { staticClass: "label-sub-title bold" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.event.title) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _vm.event.event_type == "general"
              ? _c("div", { staticClass: "sticky-content-container" }, [
                  _c("div", { staticClass: "sticky-content-wrapper" }, [
                    _c(
                      "p",
                      { staticClass: "label-middle-description event-content" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.event.description) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isRegistered &&
                    _vm.showParticipatoryLink &&
                    _vm.event.participatory_link != null
                      ? _c("p", { staticClass: "label-description" }, [
                          _vm._v("\n                참여링크: "),
                          _c(
                            "a",
                            {
                              staticClass: "link-a",
                              attrs: { href: _vm.event.participatory_link }
                            },
                            [_vm._v(_vm._s(_vm.event.participatory_link))]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sticky-content-side" }, [
                    _c(
                      "div",
                      { staticClass: "info-container" },
                      [
                        _vm.isFinished
                          ? _c("miilk-button", {
                              attrs: {
                                name: _vm.finishedBtnName,
                                isDisableStart: true
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isFinished && _vm.isRegistered
                          ? _c("miilk-button", {
                              attrs: { name: "신청완료", isDisableStart: true }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user == null
                          ? _c(
                              "div",
                              [
                                !_vm.isFinished && !_vm.isRegistered
                                  ? _c("miilk-button", {
                                      attrs: {
                                        name: "참가신청",
                                        isDisableStart: true
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isFinished
                                  ? _c("p", { staticClass: "signup" }, [
                                      _vm._v(
                                        "해당 이벤트 참가신청을 위해서 더밀크에 "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link-a",
                                          attrs: { href: "/signup" }
                                        },
                                        [_vm._v("가입하러 가기")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                !_vm.isFinished && !_vm.isRegistered
                                  ? _c("miilk-button", {
                                      attrs: {
                                        v: _vm.$v,
                                        name: "참가신청",
                                        isLoading: _vm.isLoading
                                      },
                                      on: { clicked: _vm.register }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "info-wrapper" }, [
                          _vm.pdtTime || _vm.kstTime
                            ? _c("div", { staticClass: "info" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label-middle-description bold key"
                                  },
                                  [_vm._v("일시")]
                                ),
                                _vm._v(" "),
                                _vm.pdtTime
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "label-small-description val"
                                      },
                                      [_vm._v("미국 : " + _vm._s(_vm.pdtTime))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.kstTime
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "label-small-description val"
                                      },
                                      [_vm._v("한국 : " + _vm._s(_vm.kstTime))]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.event.location
                            ? _c("div", { staticClass: "info" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label-middle-description bold key"
                                  },
                                  [_vm._v("장소")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "label-small-description val"
                                  },
                                  [_vm._v(_vm._s(_vm.event.location))]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.event.prices.normal ||
                          _vm.event.prices.monthly ||
                          _vm.event.prices.yearly
                            ? _c("div", { staticClass: "info-box" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label-middle-description bold key"
                                  },
                                  [_vm._v("가격")]
                                ),
                                _vm._v(" "),
                                _vm.event.prices.normal
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "label-small-description val"
                                      },
                                      [
                                        _vm._v(
                                          "일반 사용자: $" +
                                            _vm._s(_vm.event.prices.normal)
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.event.prices.monthly
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "label-small-description val"
                                      },
                                      [
                                        _vm._v(
                                          "개인 플랜 구독자: $" +
                                            _vm._s(_vm.event.prices.monthly)
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.event.prices.yearly
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "label-small-description val"
                                      },
                                      [
                                        _vm._v(
                                          "기업 플랜 구독자: $" +
                                            _vm._s(_vm.event.prices.yearly)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.event.event_type == "attachment"
              ? _c("div", { staticClass: "sticky-content-container" }, [
                  _c("div", { staticClass: "sticky-content-wrapper" }, [
                    _c(
                      "p",
                      { staticClass: "label-description event-content" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.event.description) +
                            "\n              "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sticky-content-side" }, [
                    _c("div", { staticClass: "info-container" }, [
                      _vm.isRegistered
                        ? _c("div", { staticClass: "button-wrapper" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button button-box fix",
                                attrs: {
                                  href: _vm.event.participatory_link,
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    리포트 다운로드\n                  "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user == null
                        ? _c(
                            "div",
                            { staticClass: "button-wrapper" },
                            [
                              !_vm.isFinished
                                ? _c("p", { staticClass: "signup" }, [
                                    _vm._v("더밀크 "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "link-a",
                                        attrs: { href: _vm.signupLink() }
                                      },
                                      [_vm._v("회원가입")]
                                    ),
                                    _vm._v(" 또는 "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "link-a",
                                        attrs: { href: _vm.loginLink() }
                                      },
                                      [_vm._v("로그인")]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isFinished && !_vm.isRegistered
                                ? _c("miilk-button", {
                                    staticClass: "primary",
                                    attrs: {
                                      name: "리포트 다운로드",
                                      isDisableStart: true
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "button-wrapper" },
                            [
                              !_vm.isFinished && !_vm.isRegistered
                                ? _c("miilk-button", {
                                    staticClass: "primary",
                                    attrs: {
                                      v: _vm.$v,
                                      name: "리포트 다운로드"
                                    },
                                    on: { clicked: _vm.register }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c("div", { staticClass: "info-container" }, [
                        _vm.event.prices.normal > 0
                          ? _c("div", { staticClass: "info-wrapper" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "label-small-description bold key"
                                },
                                [_vm._v("가격")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "label-small-description val" },
                                [
                                  _vm._v(
                                    "일반 사용자: $" +
                                      _vm._s(_vm.event.prices.normal)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "label-small-description val" },
                                [
                                  _vm._v(
                                    "월 결제 유료 구독자: $" +
                                      _vm._s(_vm.event.prices.monthly)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "label-small-description val" },
                                [
                                  _vm._v(
                                    "연 결제 유료 구독자: $" +
                                      _vm._s(_vm.event.prices.yearly)
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.relatedVideos.length > 0
              ? _c(
                  "div",
                  { staticClass: "event-replay" },
                  [
                    _c(
                      "h3",
                      { staticClass: "label-small bold section-title" },
                      [_vm._v("\n              이벤트 다시보기\n            ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.relatedVideos, function(relatedVideo) {
                      return _c("div", { staticClass: "rvideo-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "image-wrapper remove-line-height" },
                          [
                            _c(
                              "a",
                              { attrs: { href: _vm.videoLink(relatedVideo) } },
                              [
                                _c("img", {
                                  attrs: { src: relatedVideo.thumbnail_url }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          _c(
                            "a",
                            { attrs: { href: _vm.videoLink(relatedVideo) } },
                            [
                              _c(
                                "h2",
                                { staticClass: "labsl-sub-title title" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(relatedVideo.title) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          relatedVideo.description
                            ? _c(
                                "div",
                                { staticClass: "label-small-description" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(relatedVideo.description) +
                                      "\n                "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "statistics label-small" }, [
                            _c("span", [
                              _vm._v(_vm._s(relatedVideo.str_published_at))
                            ])
                          ])
                        ])
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.paymentPop
              ? _c("payment-popup", {
                  attrs: { title: _vm.popTitle, content: _vm.paymentMessage },
                  on: { confirmed: _vm.payment, canceled: _vm.cancelOrder }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.paymentErrorPop
              ? _c("layer-popup", {
                  attrs: { title: _vm.errorTitle, content: _vm.errorContent },
                  on: { "close-layer-popup": _vm.errorPopClose }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }