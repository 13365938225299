<template>
<div class="dialog-container from-lower">
  <div class="dialog-background"></div>
  <div class="dialog-wrapper">
    <div class="button button-circle close-btn" v-on:click="closePop">
      <img class="icon-close" src="https://assets.themiilk.com/common/icon-close-white.svg" alt="Close" />
    </div>
    <div class="dialog-ticker">
      <div class="dialog-content">
        <div class="dialog-head">
          <div class="info-wrapper">
            <div class="section-title label-middle-title bold">{{this.content.symbol}}</div>
            <div class="section-description label-small-description">{{this.content.name}}</div>
          </div>

          <div class="price-wrapper">
            <div class="current-price label-middle-title bold">
              <sup class="thin">$</sup>{{this.closeData.adjClose}}
            </div>

            <div class="market-wrapper">
              <div class="market">
                <div class="move label-small-description medium" v-bind:class="updown(this.closeData.change)">{{new Intl.NumberFormat('en-US').format(this.closeData.change)}} ({{this.closeData.changePercent.toFixed(2)}}%)</div>
                <div class="status label-xsmall">{{this.closeData.date.split('-').slice(-2).join('.')}} 종가</div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-chart">
          <div class="chart-loading" v-if="isLoading">
            <div class="chart-loading-wrapper">
              <div class="icon remove-line-height">
                <img src="https://assets.themiilk.com/common/icon-loading.svg" alt="" />
              </div>
              <span class="msg">Chart Loading ...</span>
            </div>
          </div>

          <GChart
            type="LineChart"
            :data="chartData"
            :settings="{ packages: ['corechart'] }"
            :options="chartOptions"
            :events="chartEvents"
            ref="gChart"
            @ready="onChartReady"
          />
        </div>

        <!--div class="dialog-foot">
          <div class="dialog-related-wrapper">
            <div class="related-title">
              <span class="bold">관련글</span>
              <div class="bar"></div>
            </div>

            <ul class="article-list">
              <li class="item">
                <article class="article-item">
                  <div class="image-wrapper remove-line-height">
                    <a href="/articles/a52a8799f">
                      <div class="image-box" style="background-size: cover; background-image: url('https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/production/article/61659281ce/27b249d502_1639120404')">
                        <img alt="" class="blank-image" src="/assets/common/blank-6-4-794552ee12971cb17c770ff709b8cbdec81d6d43f3014027a917e7523e607d34.png">
                      </div>
                    </a>
                  </div>

                  <div class="text-wrapper">
                    <ul class="tags">
                      <li class="collection">
                        <a href="/collections/70" class="collection-linkage no-deco ellipsis one">
                          정책인사이트
                        </a>
                      </li>

                      <li class="tag">
                        <a class="tag-linkage" href="/tags/627">INSTAGRAM</a>
                      </li>
                      <li class="tag">
                        <a class="tag-linkage" href="/tags/2159">SOCIAL MEDIA</a>
                      </li>
                    </ul>

                    <a href="/articles/a52a8799f">
                      <h2 class="title label-description bold ellipsis two">
                        소셜미디어 자율 규제 끝...‘알고리즘 아웃’
                      </h2>
                    </a>

                    <p class="author label-xsmall">
                      <span class="bold">Hajin Han</span>
                      <span class="date">
                        2021.12.09 22:36 PDT
                      </span>
                    </p>
                  </div>
                </article>
              </li>
              <li class="item">
                <article class="article-item">
                  <div class="image-wrapper remove-line-height">
                    <a href="/articles/a52a8799f">
                      <div class="image-box" style="background-size: cover; background-image: url('https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/production/article/61659281ce/27b249d502_1639120404')">
                        <img alt="" class="blank-image" src="/assets/common/blank-6-4-794552ee12971cb17c770ff709b8cbdec81d6d43f3014027a917e7523e607d34.png">
                      </div>
                    </a>
                  </div>

                  <div class="text-wrapper">
                    <ul class="tags">
                      <li class="collection">
                        <a href="/collections/70" class="collection-linkage no-deco ellipsis one">
                          정책인사이트
                        </a>
                      </li>

                      <li class="tag">
                        <a class="tag-linkage" href="/tags/627">INSTAGRAM</a>
                      </li>
                      <li class="tag">
                        <a class="tag-linkage" href="/tags/2159">SOCIAL MEDIA</a>
                      </li>
                    </ul>

                    <a href="/articles/a52a8799f">
                      <h2 class="title label-description bold ellipsis two">
                        소셜미디어 자율 규제 끝...‘알고리즘 아웃’
                      </h2>
                    </a>

                    <p class="author label-xsmall">
                      <span class="bold">Hajin Han</span>
                      <span class="date">
                        2021.12.09 22:36 PDT
                      </span>
                    </p>
                  </div>
                </article>
              </li>
              <li class="item">
                <article class="article-item">
                  <div class="image-wrapper remove-line-height">
                    <a href="/articles/a52a8799f">
                      <div class="image-box" style="background-size: cover; background-image: url('https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/production/article/61659281ce/27b249d502_1639120404')">
                        <img alt="" class="blank-image" src="/assets/common/blank-6-4-794552ee12971cb17c770ff709b8cbdec81d6d43f3014027a917e7523e607d34.png">
                      </div>
                    </a>
                  </div>

                  <div class="text-wrapper">
                    <ul class="tags">
                      <li class="collection">
                        <a href="/collections/70" class="collection-linkage no-deco ellipsis one">
                          정책인사이트
                        </a>
                      </li>

                      <li class="tag">
                        <a class="tag-linkage" href="/tags/627">INSTAGRAM</a>
                      </li>
                      <li class="tag">
                        <a class="tag-linkage" href="/tags/2159">SOCIAL MEDIA</a>
                      </li>
                    </ul>

                    <a href="/articles/a52a8799f">
                      <h2 class="title label-description bold ellipsis two">
                        소셜미디어 자율 규제 끝...‘알고리즘 아웃’
                      </h2>
                    </a>

                    <p class="author label-xsmall">
                      <span class="bold">Hajin Han</span>
                      <span class="date">
                        2021.12.09 22:36 PDT
                      </span>
                    </p>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div-->
      </div>
    </div>
  </div>
</div>

</template>

<script>
  import { GChart } from 'vue-google-charts';
  export default {
    props: {
        content: {
            default: {},
            type: Object
        },
        recommended_at: {
          default: '',
          type: String
        }
    },
    data() {
      let data = [...this.content.historical];
      let currentData = data[0];
      let recomendedDate = this.recommended_at;
      let recommendedData = 0;
      const reversed = data.reverse();

      const recommendDay = new Date(recomendedDate);
      const closestDay = data.reduce((a, b)=>{
        const daydiff = new Date(a.date) - recommendDay;
        return daydiff > 0 && daydiff < new Date(b.date) - recommendDay ? a : b;
      });
      if (closestDay.date !== recomendedDate){
        recomendedDate = closestDay.date;
      }
      let historical = reversed.map((item, i)=>{
        
        if(item.date===recomendedDate){
          
          recommendedData = i;
    
          return [item.date, item.close, '종목선정', 'point { size: 5; }']
        }
        else {
          return [item.date, item.close, null, null];
        }
        
      });

      //historical.unshift(["Date", "Price", {type: 'string', role: 'annotation'}])
      historical.unshift(["Date", "Price", {type: 'string', role: 'annotation'}, {type: 'string', role: 'style'}])
      return {
        isLoading:false,
        showConfirm: false,
        setPoint: recommendedData,
        closeData: currentData,
        chartsLib: null,
        chartData: historical,
        chartOptions: {
          chart: {
            title: "Company Performance",
            subtitle: "Sales, Expenses, and Profit: 2014-2017",
          },
          chartArea: {left: 0, top: 0, width: "100%", height: "90%"},
          legend: { position: "bottom" },          
          hAxis: {
            format: 'yy/MM',
            gridlines: {
              count: 0,
            },
          },
          vAxis: {
            grindlines: {
              count:4,
            },
            gridlineColor: '#e4e4e4',
            minorGridlines: {
              count: 4,
              color: '#f7f7f7',
            }
          }, 
          async: true
        },
        chartEvents: {
          select: () => {          
            const table = this.$refs.gChart.chartObject;
            const selection = table.getSelection();          
            const onSelectionMeaasge = selection.length !== 0 ? 'row was selected' : 'row was diselected'

          }
        }               
      }
    },    
    computed: {
    },
    components: {
      GChart
    },   
    mounted() {
      this.isLoading = true;
    },
    methods: {
      updown: function (price){
        if(price>0) return 'up';
        else if (price < 0) return 'down';
        else return ''
      },
      closePop: function(e) {
        this.$emit('close-layer-popup', e);
      },
      styleBackground: function(color) {
        return `--background-color: ${color}`;
      },
      onChartReady: function(chart, google) {     
        this.isLoading = false;
        this.chartsLib = google;
        let setRow = this.setPoint;
        google.visualization.events.addListener(chart, 'ready', function () {
          chart.setSelection([{row:setRow, column:1}]);
        });
      }
    }
  }
</script>
