var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-detail account" }, [
    _c("h2", { staticClass: "label-middle-title" }, [_vm._v("계정 정보")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-wrapper" },
      [
        _c("text-input", {
          attrs: {
            v: _vm.$v.user.name,
            label: "이름",
            placeholder: "이름을 입력하세요.",
            required: true
          },
          model: {
            value: _vm.user.name,
            callback: function($$v) {
              _vm.$set(_vm.user, "name", $$v)
            },
            expression: "user.name"
          }
        }),
        _vm._v(" "),
        _c("text-input", {
          attrs: {
            v: _vm.$v.user.email,
            label: "이메일",
            placeholder: "이메일을 입력하세요.",
            required: true,
            readonly: true
          },
          model: {
            value: _vm.user.email,
            callback: function($$v) {
              _vm.$set(_vm.user, "email", $$v)
            },
            expression: "user.email"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "toggle-wrapper" }, [
          _c("label", { staticClass: "label" }, [_vm._v("뷰스레터 관리")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "toggle-container" },
            [
              _c("toggle-button", {
                attrs: { sync: true, width: 44, height: 22, color: "#070d92" },
                on: { change: _vm.newsletterChanged },
                model: {
                  value: _vm.user.newsletter,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "newsletter", $$v)
                  },
                  expression: "user.newsletter"
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "label", on: { click: _vm.newsletterChanged } },
                [_vm._v("뷰스레터 수신")]
              )
            ],
            1
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-wrapper button-wrapper" },
      [
        _c("miilk-button", {
          staticClass: "primary",
          attrs: {
            v: _vm.$v,
            name: "계정 정보 변경하기",
            isDisableStart: true
          },
          on: { clicked: _vm.modify }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }