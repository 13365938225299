<template>
  <div class="content-detail">
    <div class="table-container bookmark">
      <h2 class="label-middle-title">북마크 한 기사 <span class="number" :class="{ point: bookmarks.length > 0 }">{{ bookmarks.length }}</span>개</h2>

      <div class="row top">
        <div class="col select">
          <label for="all">
            <div class="checkbox">
              <input id="all" type="checkbox" v-model="all" class="select-all" @change="allChecked" />
              <div class="box"></div>
            </div>
            <span class="text">전체선택</span>
          </label>
        </div>
        <div class="col option">
          <!-- 버튼내에 있는 disabled 클래스는 전체 선택을 했을때 삭제 되어야 함 -->
          <button class="button button-box tiny button-remove-selected" :class="{disabled: checkedList.length == 0}" @click="destroyAll">선택 삭제하기</button>
        </div>
      </div>

      <label class="row no-deco" v-for="bookmark in bookmarks" :key="bookmark.id">
        <div class="col select">
          <div class="checkbox">
            <input name="checked-bookmark" v-model="checkedList" :value="bookmark.id" type="checkbox" class="select" @change="changeUnit" />
            <div class="box"></div>
          </div>
        </div>
        <div class="col infor">
          <a class="title no-deco" :href="linkUrl(bookmark)">
            {{ bookmark.article.title }}
          </a>
          <span class="author">
            <span class="name">{{ bookmark.article.author }}</span>
            <span class="date">{{ bookmark.article.str_published_at }}</span>
          </span>
        </div>
        <div class="col option">
<!--          <button class="button button-box tiny button-remove" @click="destroyBookmark(bookmark.id)">삭제하기</button>-->
        </div>
      </label>

      <div class="row blank" v-if="bookmarks.length == 0">
        등록된 북마크가 없습니다.
      </div>
    </div>

    <layer-confirm-popup v-if="showConfirm" :content="popContent" @close-layer-popup="closePop"/>
  </div>
</template>

<script>
import LayerConfirmPopup from "../../commons/components/layer_confirm_popup";

  export default{
    props: {
      initBookmarks: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        all: false,
        bookmarks: [...this.initBookmarks],
        checkedList: [],
        popContent: '',
        showConfirm: false,
        removeType: null,
        bookmarkId: null,
      };
    },
    computed: {
    },
    components: {
      LayerConfirmPopup
    },
    methods: {
      allChecked() {
        this.checkedList = [];
        if (this.all) {
          this.bookmarks.forEach(x => this.checkedList.push(x.id));
        }
      },
      changeUnit() {
        this.all = this.checkedList.length == this.bookmarks.length;
      },
      linkUrl: function(bookmark) {
        return `/articles/${bookmark.article.id}`
      },
      destroyAll() {
        if (this.checkedList.length == 0) {
          return;
        }

        this.popContent = `선택한 ${this.checkedList.length}개의 항목을 모두 삭제하시겠습니까?`;
        this.showConfirm = true;
        this.removeType = 'destroyAll';
      },
      destroyBookmark(bookmarkId) {
        this.popContent = `삭제하시겠습니까?`;
        this.showConfirm = true;
        this.removeType = 'destroy';
        this.bookmarkId = bookmarkId;
      },
      closePop(confirmed) {
        if (confirmed) {
          if (this.removeType == 'destroyAll') {
            this.removeAll();
          } else if (this.removeType == 'destroy') {
            this.removeUnit();
          }
        }

        this.showConfirm = false;
        this.popContent = '';
        this.removeType = null;
      },
      removeAll() {
        const data = {
          ids: this.checkedList
        }

        // delete는 param을 넘길 수 없음으로
        axios.post('/api/bookmarks/destroy_all', data).then(res => {
          const remains = this.bookmarks.filter(x => !this.checkedList.includes(x.id));
          this.bookmarks = remains;
        });
      },
      removeUnit() {
        axios.delete(`/api/bookmarks/${this.bookmarkId}`).then(res => {
          const remains = this.bookmarks.filter(x => x.id != this.bookmarkId);
          this.bookmarks = remains;
          this.bookmarkId = null;
        });
      },
    }
  }
</script>