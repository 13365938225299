<template>
  <div class="chart-box treemap">
    <!-- ab 테스트를 위해 show-button 여부로 분기별 checkbox 와 지난분기 button을 표시를 결정한다 -->
    <div class="chart-box-header" :class="{ 'show-button': showButton }">
      <div class="title label-sub-title bold">
        Top 20 Holdings
        <div class="switch-button-container" v-if="!showButton">
          {{ current.toString().substring(0, 4) }}
          <!--div class="switch-button-wrapper" :class="{ next: currentQuarter }">
            <button
              class="switch-button bold"
              :class="{ active: !currentQuarter }"
              v-on:click="showQuarter(false)"
            >
              {{ current.toString().slice(-1) - 1 }}Q
            </button>
            <button
              class="switch-button bold"
              :class="{ active: currentQuarter }"
              v-on:click="showQuarter(true)"
            >
              {{ current.toString().slice(-1) }}Q
            </button>
          </div-->
        </div>
      </div>
      <p class="description label-small-title">
        평가금액 기준 보유 상위 20개 종목을 확인해보세요! ({{
          current.toString().substring(0, 4)
        }}년 {{ current.toString().slice(-1) }}분기 기준)
      </p>

      <div class="button-wrapper" v-if="showButton">
        <button class="button button-bordered round middle bold">
          지난분기
        </button>
      </div>
    </div>

    <div v-if="isLoading">Data is Loading</div>
    <div id="treemap" v-else></div>

    <div class="options">
      <div class="option-items-wrapper">
        <p class="option-title label-small-description bold">
          전분기 대비 보유 주식수 변화
        </p>

        <ul class="option-items option-color-range">
          <li
            v-for="(color, index) in colorRage"
            class="option-item"
            :key="index"
          >
            <div class="color" :style="{ background: color }"></div>
          </li>
        </ul>
      </div>

      <div class="option-items-wrapper">
        <p class="option-title label-small-description bold center">
          신규
        </p>

        <ul class="option-items option-color-new">
          <li class="option-item">
            <div class="color" :style="{ background: colorNew }"></div>
          </li>
        </ul>
      </div>
    </div>

    <PopupTreemap
      v-if="showConfirm"
      :content="popContent"
      :color="popColor"
      @close-layer-popup="closePop"
    />
  </div>
</template>

<script>
import PopupTreemap from "../commons/components/popup_treemap";
import * as d3 from "d3";
export default {
  props: {
    orders: {
      type: Array,
      default: [],
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  data() {
    let sectors = this.orders.map((data) => data.sector);
    sectors = [...new Set(sectors)];

    let finalData = sectors.map((sector) => {
      let returnObj = { name: sector, children: [] };

      this.orders.filter((d) => {
        if (sector == d.sector) {
          returnObj.children.push({
            ticker: d.symbol,
            name: d.name,
            english_name: d.english_name,
            sector: d.sector,
            value: d.value,
            change: d.change,
            tag: d.tag_id,
            ratio: d.ratio,
          });
        }
      });

      return returnObj;
    });
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      /*
        https://developers.google.com/chart/interactive/docs/gallery/treemap?hl=en
        column 0 [String] : An ID for this node.
        column 1 [String] : The ID of the parent node.
        column 2 [Number] : The size of node.
        column 3 [optional, Number] : 

        */
      isLoading: false,
      showButton: false,
      currentQuarter: true,
      data: { name: "holdings", children: finalData },
      colorRage: [
        "#B2D9EE",
        "#B2D9EE",
        "#D9ECF7",
        "#ECF6FB",
        "#fff",
        "#EDFCE4",
        "#DBF8C9",
        "#CAF4AF",
        "#B7F093",
      ],
      colorNew: "#FEF2B1",
      showConfirm: false,
      popContent: {},
      popColor: "",
    };
  },
  mounted() {
    if (this.data.children.length > 0) {
      this.isLoading = true;
      this.showTreemap();
      this.isLoading = false;
    }
  },
  components: {
    PopupTreemap,
  },
  computed: {
    styleBackground: function(color) {
      return `background-color: ` + color;
    },
  },
  methods: {
    openPop(data, color) {
      this.popColor = color;
      this.popContent = data;
      this.showConfirm = true;
      document.body.style.overflow = "hidden";
    },
    closePop(confirmed) {
      this.showConfirm = false;
      this.popContent = "";
      this.removeType = null;
      document.body.style.overflow = "auto";
    },
    cascade(root, offset) {
      const x = new Map();
      const y = new Map();
      return root
        .eachAfter((d) => {
          if (d.children) {
            x.set(
              d,
              1 +
                d3.max(d.children, (c) =>
                  c.x1 === d.x1 - offset ? x.get(c) : NaN
                )
            );
            y.set(
              d,
              1 +
                d3.max(d.children, (c) =>
                  c.y1 === d.y1 - offset ? y.get(c) : NaN
                )
            );
          } else {
            x.set(d, 0);
            y.set(d, 0);
          }
        })
        .eachBefore((d) => {
          d.x1 -= 2 * offset * x.get(d);
          d.y1 -= 2 * offset * y.get(d);
        });
    },
    showTreemap() {
      const openPop = this.openPop;
      const width = 800;
      const height = 600;
      const legendHeight = 40;
      const format = d3.format(",d");

      const color = d3.scaleSequential([8, 0], d3.interpolateMagma);
      const ratioFormat = (input) => {
        if (input == null) return "New";
        else return d3.format(",.2%")(input);
      };
      const ratioLegendFormat = d3.format(".0%");

      // 데이터 트리맵 용으로 가공
      const treemap = (data) =>
        d3
          .treemap()
          .size([width, height])
          .paddingOuter(3)
          .paddingTop(19)
          //.paddingInner(1) cell box 사이의 padding
          .round(true)(
          d3
            .hierarchy(data)
            .sum((d) => d.value)
            .sort((a, b) => b.value - a.value)
        );

      // 그래프 영역 설정
      // 폭 width
      // 높이 height + legendHeight
      // 폰트 설정
      const svg = d3
        .select("#treemap")
        .append("svg")
        // .attr("viewBox", [0, 0, width, height+legendHeight])
        .attr("viewBox", [0, 0, width, height])
        .style("font", "10px sans-serif");

      const root = treemap(this.data);
      /*
        const changeColor = d3.scaleSequential()
                                  //.domain([-100, 100])
                                  .domain(root.leaves().map(d=>d.data.change).sort())
                                  .interpolator(d3.interpolateRainbow);

        */

      // 트리맵 사용 컬러 설정
      // 데이터와 컬러 영역 매핑
      let linearScale = d3
        .scaleLinear()
        //.domain([-1, -0.5,-0.3,-0.1, 0, 0.1, 0.3, 0.5, 1])
        .domain([-1, -0.5, -0.25, -0.05, 0, 0.05, 0.25, 0.5, 1])
        .range(this.colorRage)
        .unknown(this.colorNew);

      // 주석 문자
      // 사용 영역 메핑
      let linearLegendScale = d3
        .scaleLinear()
        .domain([-1, -0.5, -0.25, 0, 0.25, 0.5, 1])
        .range([0, 220]);

      // 트리맵 박스
      // 박스가 그려지는 좌표는
      // treemap 함수에서 자동으로 좌표를 생성함
      // d.data.change 변화율 데이터에 따라
      // 사용할 컬러를 자동 리턴함
      /*let cell = svg.selectAll("g")
                    .data(root.leaves())
                    .enter().append("g")
                    .attr("transform", function(d) { return "translate(" + d.x0 + "," + d.y0 + ")"; });

        cell.append("rect")
              .attr('width', function (d) { return d.x1 - d.x0; })
              .attr('height', function (d) { return d.y1 - d.y0; })
              .style("stroke", "black")
              .style("fill", function(d){ return linearScale(d.data.change)} )
        */
      var rects = svg
        .selectAll("rect")
        .data(root.leaves())
        .enter()
        .append("rect")
        .attr("x", function(d) {
          return d.x0;
        })
        .attr("y", function(d) {
          return d.y0;
        })
        .attr("width", function(d) {
          return d.x1 - d.x0;
        })
        .attr("height", function(d) {
          return d.y1 - d.y0;
        })
        .style("stroke", "#ddd")
        .style("fill", function(d) {
          return linearScale(d.data.change);
        })
        .style("opacity", function(d) {
          if (Math.abs(d.data.change) > 1) return 0.5;
          else return 1;
        });

      rects
        .on("click", function(event, d) {
          openPop(d.data, linearScale(d.data.change));
        })
        .on("mouseover", function(event, d) {
          d3.select(this).style("cursor", "pointer");
          if (d3.select(this).style("opacity") != 0) {
            d3.select(this).attr("opacity", 0.7);
          }
        })
        .on("mouseout", function(event, d) {
          d3.select(this).style("cursor", "default");
          if (d3.select(this).style("opacity") != 0) {
            d3.select(this).attr("opacity", 1);
          }
        });
      // 박스에 표시되는 글
      // 위치 설정
      var texts = svg
        .selectAll("text")
        .data(root.leaves())
        .enter()
        .append("text")
        .attr("x", function(d) {
          console.log(d.data.ticker, d.x1 - d.x0)
          if (d.x1 - d.x0 <= 45) {
            if (d.x1 - d.x0 <= 25) return  d.x0 + 1;
            else return d.x0 + 3;
          }
          else return d.x0 + 7;
        }) // +10 to adjust position (more right)
        .attr("y", function(d) {
          if (d.y1 - d.y0 <= 24) return d.y0 + 13;
          else return d.y0 + 17;
        }) // +20 to adjust position (lower)
        .text((d)=>{
          if (d.x1 - d.x0 <= 25)return '';
          else return d.data.ticker;
        })
        .attr("font-size", "12px")
        .attr("font-weight", "700")
        .attr("fill", "black");

      texts
        .on("click", function(event, d) {
          openPop(d.data, linearScale(d.data.change));
        })
        .on("mouseover", function(event, d) {
          d3.select(this).style("cursor", "pointer");
          if (d3.select(this).style("opacity") != 0) {
            d3.select(this).attr("opacity", 0.7);
          }
        })
        .on("mouseout", function(event, d) {
          d3.select(this).style("cursor", "default");
          if (d3.select(this).style("opacity") != 0) {
            d3.select(this).attr("opacity", 1);
          }
        });

      // and to add the text labels
      svg
        .selectAll("vals")
        .data(root.leaves())
        .enter()
        .append("text")
        .attr("x", function(d) {
          if (d.y1 - d.y0 <= 24) return d.x0 + 38;
          else {
            if (d.x1 - d.x0 <= 45) return d.x0 + 3;
            else return d.x0 + 7;
          }
        }) // +10 to adjust position (more right)
        .attr("y", function(d) {
          if (d.y1 - d.y0 <= 24) return d.y0 + 14;
          else return d.y0 + 28;
        }) // +20 to adjust position (lower)
        .text(function(d) {
          if (d.x1 - d.x0 <= 25) return ''          
          else return ratioFormat(d.data.change);
        })
        .attr("font-size", "9px")
        .attr("fill", "black");

      // Add title for the 3 groups
      svg
        .selectAll("titles")
        .data(
          root.descendants().filter(function(d) {
            return d.depth == 1;
          })
        )
        .enter()
        .append("text")
        .attr("x", function(d) {
          return d.x0;
        })
        .attr("y", function(d) {
          return d.y0 + 13;
        })
        .text(function(d) {
          return d.data.name;
        })
        .attr("font-size", function(d) {
          if (this.getComputedTextLength() > d.x1 - d.x0) {
            return "8px";
          } else return "10px";
        })
        //.attr("class", 'category-point')
        .attr("fill", function(d) {
          return color(d.data.name);
        });

      /*const legW = 30;

      var legend = svg.select(".x.axis").append("g").classed("legend", true);

      legend.append("text")
        .attr("x", 10 + 30)
        .attr("y", 9.5)
        .attr("dy", "0.32em")
        .text(function(d) {
            let value = Math.round(d*10)/10;
            return  value;
        });

      legend.append("rect")
        .attr("x", function(d,i){
          return (i + 28) * legW;
          })
        .attr("width", 19)
        .attr("height", 19)
          .attr('fill', function(d){
            console.log(d)
          return d;
          
          });
      */
      // 주석 표시할 영역 설정
      /*
      svg.append("g")
        .attr("class", "legendLinear")
        .attr("transform", "translate(6, 410)");
      
      const legW = 20;
      var axis = d3.axisBottom(linearLegendScale).ticks(20, "%");
      */
      /*  const axis = g => g
                    .attr("transform", `translate(4, 40)`)
                    .call(d3.axisBottom(linearLegendScale).ticks(200 / 10, "%"))
                    .call(g => g.selectAll(".domain").remove());
      */

      var legend = svg
        .select(".legendLinear")
        .append("g")
        .classed("legend", true);

      // 주석 표시 사각형
      /*
      legend.selectAll("rect")
        .data(linearScale.range())
        .enter()
        .append('rect')
        .attr('stroke', '#ddd')
        .attr('width', legW)
        .attr("x", function(d,i){
          //console.log(i, d);
          return i * legW;
          })
        .attr("y", 0)
        .attr("height", 10)
          .attr('fill', function(d){
          return d;
          
          });
    
      // 주석 표시 텍스트
      // 중간 값 0은 박스 중앙에 표시하고
      // 나머지 영역은 박스 경계선에 텍스트 표시하기 위해
      // index 4를 기준으로 x 좌표와 width를 조정
      legend.selectAll("text")
      .data([-1, -0.5,-0.25, 0.25, 0.5, 1])
        .enter()
        .append('text')
        .attr('width', function(d,i){ return legW;})
        .attr("x", function(d,i){ return (i + 1) * legW; })
        .attr("y", 27)
        .text(function(d){ return ratioLegendFormat(d) })
        .attr("font-size", "6px")
        .attr("fill", "black")
        .attr("text-anchor", "middle")*/
    },
    showQuarter(boolean) {
      this.currentQuarter = boolean;
    },
  },
};
</script>
