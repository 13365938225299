var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "option-button related-ticker" },
    [
      _vm.tickers.length > 1
        ? _c("div", { staticClass: "option-button-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _c(
            "div",
            {
              staticClass: "option-button-body",
              on: {
                click: function($event) {
                  return _vm.openPop(_vm.tickers[0])
                }
              }
            },
            [_vm._m(2), _vm._v(" "), _vm._m(3)]
          ),
      _vm._v(" "),
      _vm.tickers.length > 1
        ? _c(
            "ul",
            { staticClass: "option-button-list" },
            _vm._l(_vm.tickers, function(ticker) {
              return _c("li", { key: ticker.symbol, staticClass: "item" }, [
                _c(
                  "button",
                  {
                    staticClass: "ticker-item",
                    on: {
                      click: function($event) {
                        return _vm.openPop(ticker)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "icon-wrapper" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/investors/logos/" +
                            ticker.symbol +
                            ".jpg",
                          alt: ticker.symbol
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-wrapper" }, [
                      _c("div", { staticClass: "ticker" }, [
                        _vm._v(_vm._s(ticker.symbol))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "percent",
                          class: _vm.updown(ticker.historical[0].change)
                        },
                        [
                          _vm._v(
                            _vm._s(
                              ticker.historical[0].changePercent.toFixed(2)
                            ) + "%"
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirm
        ? _c("PopupTickers", {
            attrs: {
              content: _vm.popContent,
              recommended_at: _vm.published_at
            },
            on: { "close-layer-popup": _vm.closePop }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label active" }, [
      _vm._v("관련종목"),
      _c("span", { staticClass: "mobile-hide" }, [_vm._v(" 보기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mark" }, [
      _c("img", {
        staticClass: "icon default",
        attrs: {
          src:
            "https://assets.themiilk.com/common/option-icon-related-ticker.svg"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon active",
        attrs: {
          src:
            "https://assets.themiilk.com/common/option-icon-related-ticker-active.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label active fold-hide force" }, [
      _vm._v("종목차트"),
      _c("span", { staticClass: "mobile-hide" }, [_vm._v(" 보기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mark" }, [
      _c("img", {
        staticClass: "icon default",
        attrs: {
          src:
            "https://assets.themiilk.com/common/option-icon-related-ticker.svg"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon active",
        attrs: {
          src:
            "https://assets.themiilk.com/common/option-icon-related-ticker-active.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }